import React, { useState, useEffect } from 'react'
import { Container, Col,Form,Table,Row,Badge,Jumbotron,Card,Spinner,Alert } from 'react-bootstrap';
import CategValueOption from './WebThrdColCategVals'
import { v4 as uuidv4 } from 'uuid';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';

function WebThrdColShopRow(props){
    

    

    function deleteRow(event){

        props.delRowFunc(props.rowData)
    }

    const [selShopCateg,setCateg]=useState('Walmart')
    function onChngeSel(event){
        const val=event.target.value
        setCateg(val)
    }

    useEffect(()=>{
        if(!props.rowData.shopapprowid){
            props.upDateCategValue({changeId:props.rowData.tmpid,selectedval:selShopCateg})
        }
        
    },[selShopCateg])

    const fallBackui=<Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert>

    try{
        return <tr style={props.rowData.shopapprowid?{backgroundColor:'#b7efcd'}:null}>
                <td className='web3rdcolshopcol1'>{props.rowData.itemdesc}</td>
                {/* <td>{props.rowData.category}</td> */}
                <td>{props.rowData.shopapprowid?props.rowData.category:<Form.Control as="select" id="seltype" value={selShopCateg} onChange={onChngeSel} disabled={props.categDisabled} >
              {/* <option >Walmart</option>
              <option >Amazon</option>
              <option >Nina's</option>
              <option >Costco</option>
              <option >99 Cents</option>
              <option >Gelsons</option> */}
              {props.categRows.map((rwval)=><CategValueOption key={uuidv4()} rowValue={rwval} />)}
            </Form.Control>}</td>
              <td>
                  {props.delLoadingFlag?<Spinner animation="border" variant="primary" /> : <DeleteForeverTwoToneIcon color='secondary' fontSize='medium' onClick={deleteRow} /> }
              </td>
                {/* <td><a href='#'>{props.delLoadingFlag?<Spinner animation="border" variant="primary" />:<i className="material-icons webthrdcoladdshp1" id='additem1' onClick={deleteRow} >
                delete_forever
                </i>}</a></td> */}
            </tr>
    }catch(e){
        return fallBackui
    }
    
}

export default WebThrdColShopRow