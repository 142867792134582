import React,{useState} from 'react'
import { Container, Col,Form,Table,Row,Badge,Jumbotron,Card,Alert } from 'react-bootstrap';
import DeleteSweepTwoToneIcon from '@material-ui/icons/DeleteSweepTwoTone';

function MobEditPageIngrows(props){

    function delIngRow(){
        if(props.rowData.source==='new'){
            props.deleteIng(props.idVal)
        }else{
            //#############delete on server
            const apiurl=`${process.env.REACT_APP_API_HOST}/delingredient/me/${props.idVal}`
            fetch(apiurl,{
                credentials: 'include',
                method:'DELETE',
                headers:{
                    'Authorization':`Bearer ${props.authTknValu}`
                }
            }).then((out)=>{
                if(out.status===404){
                    throw Error('Ingredient not found on server. Please refresh the page and try again')
                }else if(out.status!==200){
                    throw Error('Server error on deleting the ingredient. Please refresh the page and try again')
                }
                return out.json()
            }).then((outjson)=>{
                console.log('deleted ingredient from edit view')
                props.deleteIng(props.idVal)
                // props.triggerQryOndel(true)
            }).catch((e)=>{
                alert(e)
            }) 
        }
    }

    const [itmNme,setItmName]=useState(props.rowData.itemname)
    function chngeItmName(event){
        event.preventDefault()
        const val=event.target.value
        setItmName(val)
        const opObj={
                    id: props.idVal?props.idVal:props.rowData,
                    itemname: val,
                    quantity: itmQty
                }
        props.rowUpdates(opObj)
    }

    const [itmQty,setItmQty]=useState(props.rowData.quantity)
    function chngeItmQty(event){
        event.preventDefault()
        const val=event.target.value
        setItmQty(val)
        const opObj={
            id: props.idVal?props.idVal:props.rowData,
            itemname: itmNme,
            quantity: val
        }
        props.rowUpdates(opObj)
    }

    const fallBackui=<Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert>

    try{
        return <tr>
                <th>{props.rowIndx+1}</th>
                {/* <td className='mobeditingrwcol1nme' ><Form.Control type="text"  placeholder="Enter Item Name" id="mobeditpreptime" value={itmNme} onChange={chngeItmName}  readOnly={props.rowData.source==='new'?false:true} /></td> */}
                {props.rowData.source==='new'?<td className='mobeditingrwcol1nme' ><Form.Control type="text"  placeholder="Enter Item Name" id="mobeditpreptime" value={itmNme} onChange={chngeItmName} /></td> : <td className='mobeditingrwcol1nme' >{itmNme}</td>}
                {/* <td></td> */}
                
                {/* <td className='mobeditingrwcol2qty' ><Form.Control type="text" placeholder="Enter Quantity" id="mobeditqty" value={itmQty} onChange={chngeItmQty} readOnly={props.rowData.source==='new'?false:true} /></td> */}
                {props.rowData.source==='new'?<td className='mobeditingrwcol2qty' ><Form.Control type="text" placeholder="Enter Quantity" id="mobeditqty" value={itmQty} onChange={chngeItmQty} /></td> : <td className='mobeditingrwcol2qty' >{itmQty}</td>}
                {/* <td></td> */}
                <td><DeleteSweepTwoToneIcon fontSize='large' className='mobedtwebdelingicorow' onClick={delIngRow}/></td>
                {/* <td><a href="#"><i className="material-icons mobedtwebdelingrow" id='mobedtdelingrow' onClick={delIngRow} >delete_sweep</i></a></td> */}
            </tr>
    }catch(e){
        return fallBackui
    }
    
}

export default MobEditPageIngrows