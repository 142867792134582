import React,{useState} from 'react'
import { Container,Row,Col,Card,Spinner,Image,Alert } from 'react-bootstrap';
import sampleback from '../../images/recipe1_2.png'
import sampleback2 from '../../images/recipe1_3.png'
import sampleback3 from '../../images/recipe2.png'
import cardback from '../../images/cardback1.png'
import Zoom from '@material-ui/core/Zoom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
      },
    },
  }));

function MobHomePageRows(props){
    const classes = useStyles();
    // function clickRowAction(){

    //     props.clickNav(props.rowData)
    // }
    const [clickedFlg,setClkFlg]=useState(false)
    function clickRowAction(){
        setClkFlg(true)
        // setClkFlg(false)
        setTimeout(()=>setClkFlg(false),1000)
        //query data
        const apiurl=`${process.env.REACT_APP_API_HOST}/getrecipeid/me/${props.rowData.id}`
        fetch(apiurl,{
            credentials: 'include',
            headers:{
            'Authorization':`Bearer ${props.authValue}`
            }
        }).then((out)=>{
            if(out.status===404){
                throw Error('Recipe not found on server. Please refresh the page and try again')
            }else if(out.status!==200){
                throw Error('Server error on getting details. Please refresh the page and try again')
            }
            return out.json()
        }).then((datajsonout)=>{
            setClkFlg(false)
            props.clickNav(datajsonout[0])
        }).catch((e)=>{
            alert(e)
        })
        
    }
    const [loadingIco,setLoading]=useState(false)

    function delItemRow(){
        setDelconfFlg(false);

        //###############delete
        // const apiurl=`${process.env.REACT_APP_API_HOST}/deleterec/me/${props.rowData.id}`
        const apiurl=`${process.env.REACT_APP_API_HOST}/completerec/me/${props.rowData.id}`
        setLoading(true)
        fetch(apiurl,{
            // method:'DELETE',
            credentials: 'include',
            headers:{
                // 'Content-Type':'application/json',
                'Authorization':`Bearer ${props.authValue}`
                }
        }).then((out)=>{
            if(out.status===404){
                throw Error('no records found to delete')
            }else if (out.status!==200){
                throw Error('error from server on deleting the recipe')
            }
            return out.json()
        }).then((outjson)=>{
            console.log(outjson)
            console.log('deleted')
            setLoading(false)
            //delete elastic index
            const esurl=`${process.env.REACT_APP_API_HOST}/deldocument`
            const esdata={
                indexname:"recipes",
                doc_id:props.rowData.id
                }
            fetch(esurl,{
              credentials: 'include',
              method:'POST',
              headers:{
                'Authorization':`Bearer ${props.authValue}`,
                'Content-Type':'application/json'
              },
              body:JSON.stringify(esdata)
            }).then(()=>{
                console.log('ok')
            })
            //end delete elastic index
            props.delRowMethod(props.rowData.id)
        }).catch((e)=>{
            setLoading(false)
            alert(e)
        })
        //###############end delete
        
    }

    //###################################delete confirm dialog#######################

    const [delconfFlg, setDelconfFlg] = useState(false);
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const hndleDelDiaOpen = () => {
        setDelconfFlg(true)
    };

    const hndleDelConClose = () => {
        setDelconfFlg(false);
    }
  //################################### end delete confirm dialog#######################

    const imgArray={1:sampleback,2:sampleback2,3:sampleback3}

    const fallbackUi=<Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert> 

    try{
        return <Row className='mobhomerw1'>
    <Col xs={2} className='mobhomerocol1'>
        {/* <div className='mobhomerocol1img'>
        <Image src={`${sampleback}`} fluid rounded/>
        </div> */}
    {/* <Row>
    <Col xs={6} md={4}>
    <Image src={`${sampleback}`} rounded fluid/>
    </Col>
    </Row> */}
        
        {/* <Row>
            <Col style={{backgroundImage:`url('${sampleback}')`}}>
                        ddsdf
            </Col>
        </Row> */}
        {/* <div style={{backgroundImage:`url('${sampleback}')`,position:'relative'}}>
            <img src={`url('${sampleback}')`}></img>
        </div> */}
        <Card className='mobhomerocol1img'>
        <Image className='mobhomecol1imgtag' src={`${imgArray[Math.floor(Math.random()*3)+1]}`} fluid rounded/>
        {/* <Card.Body> */}
                {/* <div className= 'mobhomecol1imgtag' style={{backgroundImage:`url('${sampleback}')`}}> */}
                {/* <img  src={`${sampleback}`} alt="Girl in a jacket"></img> */}
                {/* <Image src={`${sampleback}`} fluid rounded/> */}
                {/* </div> */}
                
        {/* </Card.Body> */}
                {/* <div className= 'mobhomecol1imgtag'>
                
                </div> */}
                
        </Card>
    </Col>
    <Col xs={9}>
    {/* <Zoom in={true} ><Card className='mobhomerocol2' onClick={clickRowAction} >
            <Card.Body>{props.rowData.recipename}</Card.Body>
        </Card></Zoom> */}
        {/* style={{backgroundColor:'#00b9d1' 318fb5 93b5e1 2bb2bb  3797a4 40bad5 00bcd4*/}
    {!clickedFlg ? <Paper elevation={20} className='' style={{backgroundColor:'#00bcd4',backgroundImage:`url('${cardback}')`,position:'relative',top:'0px'}} onClick={clickRowAction} >
    <Zoom in={true} ><Card.Body style={{fontFamily:'"Exo 2", sans-serif',fontSize:'17px',fontWeight:'bolder',color:'#363636'}} >{props.rowData.recipename}</Card.Body></Zoom>
        </Paper> :<Paper elevation={2} className='' style={{backgroundColor:'#bbbbbb',backgroundImage:`url('${cardback}')`,position:'relative',top:'5px'}} onClick={clickRowAction} >
        <Zoom in={true}><Card.Body style={{fontFamily:'"Exo 2", sans-serif',fontSize:'17px',fontWeight:'bolder',color:'#363636'}} >{props.rowData.recipename}</Card.Body></Zoom>
        </Paper>}
        
    </Col>
    <Col xs={1}>
        {loadingIco?<Spinner animation="border" variant="primary" className='mobhomerocol3'  /> : <DeleteForeverTwoToneIcon color='secondary' className='mobhomerocol3' onClick={hndleDelDiaOpen} /> }
        {/* <a  href="#" className='mobhomerocol3'>{loadingIco?<Spinner animation="border" variant="primary" />:<i className="material-icons mobhomerocol3ico" id='mobdelitem' onClick={hndleDelDiaOpen} >
          delete_forever
              </i>}</a> */} {/*this one was active*/}

    {/* {loadingIco?<Spinner animation="border" variant="primary" />:<a  href="#" className='mobhomerocol3'><i className="material-icons mobhomerocol3ico" id='mobdelitem' onClick={delItemRow} >
          delete_forever
              </i></a>} */}
    </Col>

    {/*************************delete dialog************************/}
            <Dialog
                fullScreen={fullScreen}
                open={delconfFlg}
                onClose={hndleDelConClose}
                aria-labelledby="responsive-dialog-title" >
                <DialogTitle id="responsive-dialog-title">{"Sure to Delete?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={hndleDelConClose} color="primary">
                    No
                    </Button>
                    <Button onClick={delItemRow} color="primary" autoFocus>
                    Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* *************************delete dialog************************* */}
</Row>
    }catch(e){
        return fallbackUi
    }
    
}

export default MobHomePageRows