import React, { useState,useEffect } from 'react'
import { Container, Col,Form,Table,Row,Badge,Jumbotron,Card,Modal,Button,Alert,Image } from 'react-bootstrap';
// import '../'
import hdrico from '../../images/pizza1.png'
import recpfileico from '../../images/recipe_file_icon.svg'
import recipelnkico from '../../images/url_1.svg'
import MobDetailIngRows from './MobDetailPageIngrows'
import { v4 as uuidv4 } from 'uuid';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import { green } from '@material-ui/core/colors';
import PlaylistAddTwoToneIcon from '@material-ui/icons/PlaylistAddTwoTone';
import CreateTwoToneIcon from '@material-ui/icons/CreateTwoTone';
import CircularProgress from '@material-ui/core/CircularProgress';

function MobDetailPage(props){

    console.log(props.inputData)
    const [showAddConf,setShowConf]=useState(false)
    const [showRecpSelct,setShowSelectico]=useState(false)

    const [ingRow,addIngRow]=useState(props.inputData.ingredients?props.inputData.ingredients:[])
    
    useEffect(()=>{
        if(props.inputData.ingredients){
            // addIngRow((oldval)=>[...oldval,...props.inputData.ingredients])
            addIngRow((oldval)=>[...props.inputData.ingredients])
        }
        
    },[props.inputData.ingredients])

    //shopping list data
    const [shopItms,chngeShpItmList]=useState([])
    //props.changeShopData(val)
    function addAllShpRows(){
        // console.log(ingRow)
        if(ingRow.length>0){
            setShowConf(true)
            setTimeout(()=>setShowConf(false),2000)
        }
        let tmpRw={}
        tmpRw.itms=[]
        ingRow.forEach((rw)=>{
            const inptRw={
                id:uuidv4(),
                itemdesc:`${rw.itemname}:${rw.quantity}`,
                source:'new',
                category:"Walmart"
            }
            // props.shopDataChnge(inptRw)
            tmpRw.itms.push(inptRw)
        })
        tmpRw.mode='all'
        props.shopDataChnge(tmpRw)
    }

    //###########################get recipe file for link################################

    function getRecpFile(){
        const fleApiUrl=`${process.env.REACT_APP_API_HOST}/getrecipefile/${props.inputData.recipefileid}`
        setShowSelectico(true)
        fetch(fleApiUrl,{
            credentials: 'include',
            method:'GET',
            headers:{
                'Authorization':`Bearer ${props.authTokenValue}`,
                // 'Content-Type':'application/json'
            }
            }).then((out)=>{
                setShowSelectico(false)
                // console.log(out)
                // window.open(out);
                // return out
                out.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = props.inputData.recipefile.split('-')[2];
					a.click();
				});
            }).catch((e)=>{
                setShowSelectico(false)
                alert('Error in loading the file. Please refresh and try again!!!')
            })
        const a=''
    }

    //###########################end get recipe file for link################################

    //###########################control header image##########################

    const [headerImage,setHeaderImage]=useState(`${process.env.REACT_APP_HEADER_IMG_CDN}/mainimg.png`)
    const [hdrImageName,setImgeName]=useState(props.inputData.mainimage)
    const [hdrImageId,setImgeId]=useState(props.inputData.mainimageid)
    console.log(props.inputData.mainimage)
    useEffect(()=>{
        if(props.inputData.id){
            setHeaderImage(props.inputData.mainimage==='mainimg.png'?`${process.env.REACT_APP_HEADER_IMG_CDN}/mainimg.png`:`${process.env.REACT_APP_PUBLIC_CDN}/${props.inputData.mainimage}`)
        }  
        setImgeName(props.inputData.mainimage)      
        setImgeId(props.inputData.mainimageid)
    },[props.inputData.id])
    const [hdrImgFile,setHdrImgfile]=useState(undefined)
    function handleHdrFilechange(event){
        const fleCntnt=event.target.files[0];
        setHdrImgfile(fleCntnt)
    }

    const [showHdrPop,showHdrEditImg]=useState(false)

    function runOnClose(){
        showHdrEditImg(false)
    }

    
    function saveHdrImagetosrv(){
        console.log(hdrImgFile)
        // if(props.inputData.mainimage!=='mainimg.png'){
        if(hdrImageName!=='mainimg.png'){
            deleteHdrForUpdate(hdrImageId)
        }
        const fleSvcUrl=`${process.env.REACT_APP_API_HOST}/postfile`
        const filedata = new FormData()
        filedata.append('image',hdrImgFile)
        fetch(fleSvcUrl,{
            credentials: 'include',
            method:'POST',
            headers:{
                'Authorization':`Bearer ${props.authTokenValue}`,
                'mode':'public'
                // 'Content-Type':'application/json'
            },
            body: filedata
        }).then((fleOut)=>{
            if(fleOut.status===500){
                throw new Error('error in updating image')
            }
            return fleOut.json()
        }).then((flejson)=>{
            setHeaderImage(flejson.imageurl)
            setImgeName(flejson.imagefile)
            setImgeId(flejson.imageid)
            //update header image to DB
            updateHeaderImagetoRecipe(flejson).then((out)=>{
                // props.triggerQry(true)
            }).catch((e)=>{
                alert(e)
            })

            runOnClose()
        }).catch((e)=>{
            alert(e)
        })

    }

    async function deleteHdrForUpdate(fname){
        const delSrvc=`${process.env.REACT_APP_API_HOST}/deletefilebackend/${fname}`
        fetch(delSrvc,{
            credentials: 'include',
            method:'DELETE',
            headers:{
              'Authorization':`Bearer ${props.authTokenValue}`,
              // 'usrid':props.editData.user_id,
              'mode':'public'
              // 'Content-Type':'application/json'
            }
          }).then((out)=>{
            if(out.status!==200){
                throw Error('Server error on deletion of file. Please refresh the page and try again')
              }
                return out.json()
          }).then((outjson)=>{
            Promise.resolve('completed')
          }).catch((e)=>{
              Promise.reject('Server error on deletion of file. Please refresh the page and try again')
          })

    }

    async function updateHeaderImagetoRecipe(filejson){
        const saveJson={mainimage:filejson.imagefile,mainimageid:filejson.imageid}
        const apiurl=`${process.env.REACT_APP_API_HOST}/updaterec/me/${props.inputData.id}`    
        fetch(apiurl,{
            credentials: 'include',
            method:'PATCH',
            headers:{
                'Authorization':`Bearer ${props.authTokenValue}`,
                'Content-Type':'application/json'
            },
            body:JSON.stringify(saveJson)
        }).then((out)=>{
            if(out.status===404){
                throw Error('recipe not found')
              }else if(out.status!==200){
                throw Error('server error in updating')
              }
              return out.json()
        }).then((outjson)=>{
            Promise.resolve('completed')
        }).catch((e)=>{
            Promise.reject('error in updating recipe')
            // throw Error('error in updating recipe')
        })
        
    }


    //###########################end control header image########################

    const [addShopLoadingbtn,setAddloadingBtnFlg]=useState({})

    const fallBackui=<Col className='mobdetl'><Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert></Col>

    try{
        return <Col className='mobdetl'>
        {/* <!--####### row div for image #######--> */}
        <Row style={{backgroundImage:`url(${headerImage})`,backgroundSize:'cover'}} className='mobdetlrw1'>
            <Col>
                <Row>
                    {props.inputData.id && <CreateTwoToneIcon fontSize='large' className='mobecndcolhdredit' onClick={()=>showHdrEditImg(true)} />}
                    {/* {props.inputData.id && <a  href="#" ><i className="material-icons mobecndcolhdredit" id='addtoshp1' onClick={()=>showHdrEditImg(true)} >create</i></a>} */}
                </Row>
                <Row>
                    <div className='mobedtrw1hdr'>
                        <span className='mobedtrw1hdrspan'>{props.inputData.recipename}</span>
                    </div>
                </Row>
            </Col>
            
        </Row>

        {/* <!--####### row div for recipe/rl/link #######--> */}
        {props.inputData.recipetype !=='text' && <Row className='mobdetlrw2'>
            <Col sm>
                <Form className='mobdetlrw2frm'>
                    <Row style={{paddingLeft:20}}>
                        {/* <Col style={{paddingBottom:5}}> */}
                            {/* <Form.Label>URL/Link: </Form.Label> */}
                        {/* </Col> */}
                        {/* <Col style={{paddingBottom:10,paddingTop:0}}> */}
                        <Col >
                            {/* <Badge variant="info" className='mobdetlrw2bdge'><a style={{color: 'darkblue'}} href='#'>Recipe</a></Badge> */}
                            {showRecpSelct && <CircularProgress className='mobdetlrw2bdge' />}
                            {!showRecpSelct && props.inputData.recipetype==='file/image' && <Image className='mobdetlrw2bdge' src={`${recpfileico}`} fluid onClick={getRecpFile} /> }
                            {/* {props.inputData.recipetype==='file/image' && <Badge variant="info" className='mobdetlrw2bdge' ><a style={{color: 'darkblue'}} href="#" onClick={getRecpFile}>Recipe</a></Badge>} */}
                            {props.inputData.recipetype==='link' && <a href={props.inputData.recipeurl} target='_blank' rel="noopener noreferrer" > <Image href={props.inputData.recipeurl} target='_blank' className='mobdetlrw2bdge' src={`${recipelnkico}`} fluid /></a> }
                            {/* {props.inputData.recipetype==='link' && <Badge variant="info" className='mobdetlrw2bdge' ><a style={{color: 'darkblue'}} href={props.inputData.recipeurl} target='_blank' rel="noopener noreferrer">Recipe</a></Badge>} */}
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>}
        

        {/* <!--####### row div for prep time #######--> */}
        {props.inputData.recipetype ==='text' && <Row className='mobdetlrw3'>
            <Col sm>
                <Form className='mobdetlrw3frm'>
                    <Row className='mobdetlrw3prp1'>
                        <Col>
                            <Badge pill variant="secondary">Prep Time</Badge>
                        </Col>
                        <Col>
                            <Form.Control plaintext readOnly type="text" className='mobdetlrw3tme1' id="detmobtm1" style={{fontWeight:'bolder'}}  value={props.inputData.preptime|| ''} />
                        </Col>
                    </Row>
                </Form>
            </Col>
            <Col sm>
                <Form className='mobdetlrw3frm'>
                    <Row className='mobdetlrw3prp1'>
                        <Col>
                            <Badge pill variant="secondary">Servings</Badge>
                        </Col>
                        <Col>
                            <Form.Control plaintext readOnly type="text" className='mobdetlrw3tme1' id="detmobsrvns" style={{fontWeight:'bolder'}}  value={props.inputData.servings|| ''} />
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>}
        

        {/* <!--####### row div for ingredients table #######--> */}
        {true && <Row className='mobdetlrw4'>
            <Col sm style={{paddingTop:10}}>
                <Row>
                    <Col xs={8}>
                        <h2><Badge variant="secondary" className='mobdetlinghdr'>Ingredients</Badge></h2>
                    </Col>
                    <Col xs={4}>
                        {!showAddConf && <PlaylistAddTwoToneIcon  fontSize='large' color='primary' className='mobdetladalshpico1' onClick={addAllShpRows}/>}
                        {/* {!showAddConf && <a  href="#" ><i className="material-icons mobdetladalshp1" id='mobaddallshp' onClick={addAllShpRows} >playlist_addr</i></a>} */}
                        {showAddConf && <CheckCircleTwoToneIcon className="mobdetladalshp1" fontSize="large" style={{ color: green[500] }}></CheckCircleTwoToneIcon>}
                    </Col>
                </Row>

                <Table striped >
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Item</th>
                            <th></th>
                            
                            <th>Quantity</th>
                            {/* <th></th> */}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {ingRow.map((rw,indx)=><MobDetailIngRows key={uuidv4()} rowData={rw} updteShopLst={props.shopDataChnge} rowSnum={indx} loadingBtnFlg={addShopLoadingbtn} updateLoadingBtnflg={setAddloadingBtnFlg} />)}

                        {/* <tr>
                            <th>1</th>                    
                            <td >Mark</td>
                            <td></td>
                            <td></td>
                            <td >Otto</td>
                            <td><a  href="#"><i className="material-icons mobdetladrwshp1" id='mobdetladds1'>add_circle_outline</i></a></td>
                        </tr>
                        <tr>
                            <th>1</th>                    
                            <td >Mark</td>
                            <td></td>
                            <td></td>
                            <td >Otto</td>
                            <td><a  href="#"><i className="material-icons mobdetladrwshp1" id='mobdetladds1'>add_circle_outline</i></a></td>
                        </tr>
                        <tr>
                            <th>1</th>                    
                            <td >Mark</td>
                            <td></td>
                            <td></td>
                            <td >Otto</td>
                            <td><a  href="#"><i className="material-icons mobdetladrwshp1" id='mobdetladds1'>add_circle_outline</i></a></td>
                        </tr> */}
                    </tbody>
                </Table>
            </Col>
        </Row>}
        

        {/* <!--####### row div for steps for recipe #######--> */}
        <Row className='mobdetlrw5'>
            <Col style={{paddingTop:10}}>
                {props.inputData.recipetype ==='text'?<h2><Badge variant="secondary" style={{marginLeft:'36%'}} className='mobdetrecphdr'>How To?</Badge></h2>:<h2><Badge variant="secondary" className='mobdetrecphdr'>Notes</Badge></h2>}
                
                <Jumbotron fluid>
                    <Container>
                        <p><pre>{props.inputData.steps}</pre></p>
                        {/* <p>Bootstrap is the most popular HTML, CSS...,Bootstrap is the most popular HTML, CSS..,Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS
                            Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..
                            Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..
                            Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..
                            Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS..Bootstrap is the most popular HTML, CSS....Bootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTMLBootstrap is the most popular HTML This is ac</p> */}
                    </Container>
                </Jumbotron>
            </Col>
        </Row>

        <Modal show={showHdrPop} onHide={runOnClose} className='hdreditfilemodal' >
            {/* <Modal.Header className='webcreatemodalhdr' closeButton>
                <Modal.Title>Edit Header Image</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <Form.Label>Select File:</Form.Label>
                <Form.Control type="file" id="recipefile" onChange={handleHdrFilechange} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={runOnClose}>
                    Close
                </Button>
                <Button variant="primary" style={{backgroundColor: '#363062'}} onClick={saveHdrImagetosrv} >
                    Save Changes
                </Button>      
            </Modal.Footer>
        </Modal>


    </Col>
    }catch(e){
        return fallBackui
    }
    
}


export default MobDetailPage