import React,{useState,useEffect} from 'react'
import { Button,Form,Row,Col,Table,Modal,Nav,Spinner,Alert } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import CategValueOption from './ShopListModalCateg'
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';

function ShopListModalRow(props){

    


    function deleteRow(event){

        props.delRowFunc(props.rowData)
    }

    const [selShopCateg,setCateg]=useState('Walmart')
    function onChngeSel(event){
        const val=event.target.value
        setCateg(val)
    }

    useEffect(()=>{
        if(!props.rowData.shopapprowid){
            props.upDateCategValue({changeId:props.rowData.id,selectedval:selShopCateg})
        }
        
    },[selShopCateg])


    const fallbackui=<Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert>

    try{
        return <tr className='mobshoprowclass1' >
                <td className='mobshopmodalTablecol1' >{props.rowData.itemdesc}</td>
                <td>{props.rowData.shopapprowid?props.rowData.category:<Form.Control as="select" id="seltype" value={selShopCateg} onChange={onChngeSel} disabled={props.categDisabled} >
              {/* <option >Walmart</option>
              <option >Amazon</option>
              <option >Nina's</option>
              <option >Costco</option>
              <option >99 Cents</option>
              <option >Gelsons</option> */}
              {props.categRows.map((rwval)=><CategValueOption key={uuidv4()} rowValue={rwval} />)}
            </Form.Control>}</td>
                <td>
                    {props.loadingIcoCtrl?<Spinner animation="border" variant="primary" /> : <DeleteForeverTwoToneIcon color='secondary' fontSize='medium' onClick={deleteRow} />}
                {/* {props.loadingIcoCtrl?<Spinner animation="border" variant="primary" />:<a  href="#"><i className="material-icons mobshopmodalico" id='mobremshplst' onClick={deleteRow} >
                delete_forever
                </i></a>} */}
                
                </td>
            </tr>
    }catch(e){
        return fallbackui
    }
    
}

export default ShopListModalRow