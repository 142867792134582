import React,{useState, useEffect} from 'react'
import { Container, Col,Form,Table,Row,Badge,Jumbotron,Modal,Button,Image,Alert } from 'react-bootstrap';
import sampleback from '../../images/pizza1.png'
import WebSecndColIngRow from './WebSecndColIngRow'
import recpfileico from '../../images/recipe_file_icon.svg'
import recipelnkico from '../../images/url_1.svg'
import { v4 as uuidv4 } from 'uuid';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import { green } from '@material-ui/core/colors';
import PlaylistAddTwoToneIcon from '@material-ui/icons/PlaylistAddTwoTone';
import Fab from '@material-ui/core/Fab';
import CreateTwoToneIcon from '@material-ui/icons/CreateTwoTone';
import CircularProgress from '@material-ui/core/CircularProgress';

function WebSecndCol(props){

    // console.log(props.inputData.ingredients)
    // const [ingRow,addIngRow]=useState([{
    //     ingItem:'Item 1',
    //     itmQty:5
    // },{
    //     ingItem:'Item 2',
    //     itmQty:10
    // }])

    // let ingRow=[]
    // if (props.inputData.ingredients){
    //     ingRow=props.inputData.ingredients
    // }
    const [showRecpSelct,setShowSelectico]=useState(false)

    const [hdrRecpName,setHdrName]=useState('')
    useEffect(()=>{
        setHdrName(props.inputData.recipename)
    },[props.inputData.recipename])

    //###################control header image#################

    // const [headerImage,setHeaderImage]=useState(`${process.env.REACT_APP_CDN}/mainimg.png`)
    const [headerImage,setHeaderImage]=useState(`${process.env.REACT_APP_HEADER_IMG_CDN}/mainimg.png`)
    const [hdrImageName,setImgeName]=useState(props.inputData.mainimage)
    const [hdrImageId,setImgeId]=useState(props.inputData.mainimageid)
    console.log(props.inputData.mainimage)
    useEffect(()=>{
        if(props.inputData.id){
            setHeaderImage(props.inputData.mainimage==='mainimg.png'?`${process.env.REACT_APP_HEADER_IMG_CDN}/mainimg.png`:`${process.env.REACT_APP_PUBLIC_CDN}/${props.inputData.mainimage}`)
        }        
        setImgeName(props.inputData.mainimage)      
        setImgeId(props.inputData.mainimageid)
    },[props.inputData.id])
    const [hdrImgFile,setHdrImgfile]=useState(undefined)
    function handleHdrFilechange(event){
        const fleCntnt=event.target.files[0];
        setHdrImgfile(fleCntnt)
    }

    const [showHdrPop,showHdrEditImg]=useState(false)

    function runOnClose(){
        showHdrEditImg(false)
    }

    
    function saveHdrImagetosrv(){
        console.log(hdrImgFile)
        if(hdrImageName!=='mainimg.png'){
            deleteHdrForUpdate(hdrImageId)
        }
        const fleSvcUrl=`${process.env.REACT_APP_API_HOST}/postfile`
        const filedata = new FormData()
        filedata.append('image',hdrImgFile)
        fetch(fleSvcUrl,{
            credentials: 'include',
            method:'POST',
            headers:{
                'Authorization':`Bearer ${props.authTokenValue}`,
                'mode':'public'
                // 'Content-Type':'application/json'
            },
            body: filedata
        }).then((fleOut)=>{
            if(fleOut.status===500){
                throw new Error('error in updating image')
            }
            return fleOut.json()
        }).then((flejson)=>{
            setHeaderImage(flejson.imageurl)
            setImgeName(flejson.imagefile)
            setImgeId(flejson.imageid)
            //update header image to DB
            updateHeaderImagetoRecipe(flejson).then((out)=>{
                props.triggerQry(true)
            }).catch((e)=>{
                alert(e)
            })

            runOnClose()
        }).catch((e)=>{
            alert(e)
        })

    }

    async function deleteHdrForUpdate(fname){
        const delSrvc=`${process.env.REACT_APP_API_HOST}/deletefilebackend/${fname}`
        fetch(delSrvc,{
            credentials: 'include',
            method:'DELETE',
            headers:{
              'Authorization':`Bearer ${props.authTokenValue}`,
              // 'usrid':props.editData.user_id,
              'mode':'public'
              // 'Content-Type':'application/json'
            }
          }).then((out)=>{
            if(out.status!==200){
                throw Error('Server error on deletion of file. Please refresh the page and try again')
              }
                return out.json()
          }).then((outjson)=>{
            Promise.resolve('completed')
          }).catch((e)=>{
              Promise.reject('Server error on deletion of file. Please refresh the page and try again')
          })

    }

    async function updateHeaderImagetoRecipe(filejson){
        const saveJson={mainimage:filejson.imagefile,mainimageid:filejson.imageid}
        const apiurl=`${process.env.REACT_APP_API_HOST}/updaterec/me/${props.inputData.id}`    
        fetch(apiurl,{
            credentials: 'include',
            method:'PATCH',
            headers:{
                'Authorization':`Bearer ${props.authTokenValue}`,
                'Content-Type':'application/json'
            },
            body:JSON.stringify(saveJson)
        }).then((out)=>{
            if(out.status===404){
                throw Error('recipe not found')
              }else if(out.status!==200){
                throw Error('server error in updating')
              }
              return out.json()
        }).then((outjson)=>{
            Promise.resolve('completed')
        }).catch((e)=>{
            Promise.reject('error in updating recipe')
            // throw Error('error in updating recipe')
        })
        
    }


    //###################end control header image#################


    //########################get the ingredients row############################################
    const [ingRow,addIngRow]=useState([])
    function updIngRow(val){


        addIngRow(val)
    }
    useEffect(()=>{
        if (props.inputData.ingredients){
            if(props.inputData.ingredients.length>0){
                addIngRow(props.inputData.ingredients)
            }else{
                addIngRow([])
            }
            
        }else{
            addIngRow([])
        }
        
    },[props.inputData.ingredients])

    //##########################end get the ingredients row ############################################

    //##########################shopping list row#########################################

    const [shopList,updShopList]=useState([])
    function addShopList(addVals){
        updShopList(addVals)
        props.chngeshopList(addVals)
        // if (shopList.length===0){
        //     updShopList(addVals)
        //     props.chngeshopList(shopList)
        // }else{
        //     // updShopList((oldVal)=>[...oldVal,addVals])
        //     updShopList(addVals)
        //     props.chngeshopList(shopList)
        // }
    }

    function addAlltoShop(){
        if(ingRow.length>0){
            setShowConf(true)
            setTimeout(()=>setShowConf(false),2000)
        }
        
        let tmpOut=[]
        ingRow.forEach((rw)=>{
            let tmprw={}
            tmprw.itemdesc=`${rw['itemname']}:${rw['quantity']}`
            tmprw.category='Walmart'
            tmprw.recipe_id=rw['recipe_id']
            tmprw.tmpid=uuidv4()
            tmpOut.push(tmprw)
        })
        props.chngeshopList(tmpOut)
    }


    //##########################end shopping list row#########################################

    //###########################get recipe file for link################################

    function getRecpFile(){
        const fleApiUrl=`${process.env.REACT_APP_API_HOST}/getrecipefile/${props.inputData.recipefileid}`
        setShowSelectico(true)
        fetch(fleApiUrl,{
            credentials: 'include',
            method:'GET',
            headers:{
                'Authorization':`Bearer ${props.authTokenValue}`,
                // 'Content-Type':'application/json'
            }
            }).then((out)=>{
                setShowSelectico(false)
                // console.log(out)
                // window.open(out);
                // return out
                out.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = props.inputData.recipefile.split('-')[2];
					a.click();
				});
            }).catch((e)=>{
                setShowSelectico(false)
                alert('Error in loading the file. Please refresh and try again!!!')
            })
        const a=''
    }

    //###########################end get recipe file for link################################

    const [showAddConf,setShowConf]=useState(false)



    // const [ingRow,addIngRow]=useState(props.inputData.ingredients?props.inputData.ingredients:[])

    // const updIng=()=>addIngRow(props.inputData.ingredients?props.inputData.ingredients:[])

    

    //props.inputData.ingredients

    //props.inputData
    
    const [addShopLoadingbtn,setAddloadingBtnFlg]=useState({})
    const fallbackUi=<Col className='col2'  xs={7}><Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert></Col>
    
    try{
        return <Col className='col2'  xs={7}>

        {/* <!--####### row div for image #######--> */}
        {/* <Row className='websecndcolrw1' style={{backgroundImage:`url(${sampleback})`}} > */}
        <Row className='websecndcolrw1' style={{backgroundImage:`url(${headerImage})`}} >
        {/* <Row className='websecndcolrw1'  > */}
        {/* <Image className='webhomecol1imgtag' src={`${headerImage}`} fluid rounded/> */}
            <Col>
                <Row>
                    {props.inputData.id && <CreateTwoToneIcon color='primary' fontSize='small' className='websecndcolhdredit' onClick={()=>showHdrEditImg(true)} /> }
                    {/* {props.inputData.id && <a  href="#" ><i className="material-icons websecndcolhdredit" id='addtoshp1' onClick={()=>showHdrEditImg(true)} >create</i></a>} */}
                </Row>
                <Row>
                    <div className='websecndcolhdr'>
                    {/* <div className='websecndcolhdrspan'>{hdrRecpName}</div> */}
                        <span className='websecndcolhdrspan' style={{width:'30%'}}>{hdrRecpName}</span>
                    </div>
                </Row>
                
            </Col>   
        </Row>
        

        {/* <!--####### row div for recipe/rl/link #######--> */}
        <Row className='websecndcolrw2'>
            <Form className='websecondcollnk'>
                {/* <Row>
                    <Col xs={2} style={{paddingTop:5}}>
                        <Form.Label>Recipe: </Form.Label>
                    </Col>
                    <Col xs={10} style={{paddingTop:5}}>
                        <Form.Control type="file" id="recipefile" />
                    </Col>
                </Row> */}
                {props.inputData.recipetype !=='text' && <Row>
                    {/* <Col xs={2} style={{paddingTop:5}}>
                        <Form.Label>URL/Link: </Form.Label>
                    </Col> */}
                    <Col xs={10} style={{paddingTop:0}}>
                        {/* <Badge variant="info" className='websecondcolbdge1'>{props.inputData.recipeurl!==''?<a style={{color: 'darkblue'}} href={props.inputData.recipeurl}>Recipe</a>:<a style={{color: 'darkblue'}} href={props.inputData.recipefile} download>Recipe</a>}</Badge> */}
                        {showRecpSelct && <CircularProgress className='websecondcolbdge1' />}
                        {!showRecpSelct && props.inputData.recipetype==='file/image' && <Image className='websecondcolbdge1' src={`${recpfileico}`} fluid onClick={getRecpFile} />}
                        {/* {props.inputData.recipetype==='file/image' && <Badge variant="info" className='websecondcolbdge1' ><a style={{color: 'darkblue'}} href="#" onClick={getRecpFile}>Recipe</a></Badge>} */}
                        {props.inputData.recipetype==='link' && <a href={props.inputData.recipeurl} target='_blank' rel="noopener noreferrer" > <Image href={props.inputData.recipeurl} target='_blank' className='websecondcolbdge1' src={`${recipelnkico}`} fluid /></a> }
                        {/* {props.inputData.recipetype==='link' && <Badge variant="info" className='websecondcolbdge1' ><a style={{color: 'darkblue'}} href={props.inputData.recipeurl}  target='_blank' rel="noopener noreferrer" >Recipe</a></Badge>} */}
                    </Col>
                </Row>}
            </Form>
        </Row>

        {/* <!--####### row div for prep time #######--> */}
        {(props.inputData.recipetype ==='text' || !props.inputData.recipetype) && <Row className='websecndcolrw3'>
            <Col>
                <Form className='websecndcolprep1'>
                    <Row className='websecndcolprep2'>
                        <Col>
                            <Badge pill variant="secondary" >Prep Time</Badge>
                        </Col>
                        <Col>
                            <Form.Control type="text" style={{fontWeight:'bolder'}} className='websecndcolprep3' plaintext readOnly  value={props.inputData.preptime|| ''} id="tmefield" />
                        </Col>
                    </Row>
                </Form>
            </Col>
            <Col>
                <Form className='websecndcolprep1'>
                    <Row className='websecndcolprep2'>
                        <Col>
                            <Badge pill variant="secondary" >Servings</Badge>
                        </Col>
                        <Col>
                            <Form.Control type="text" style={{fontWeight:'bolder'}} className='websecndcolprep3' plaintext readOnly  value={props.inputData.servings|| ''} id="srvfield" />
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>}
        

        {/* <!--####### row div for ingredients table #######--> */}
        {(true || !props.inputData.recipetype)  && <Row className='websecndcolrw4'>
            <Col>
                <Row>
                    <Col xs={10}>
                        <h2><Badge variant="secondary" className='websecndcoling1'>Ingredients</Badge></h2>
                    </Col>
                    <Col>
                        {!showAddConf && <PlaylistAddTwoToneIcon  fontSize='large' color='primary' className='websecndcoladshpico1' onClick={addAlltoShop} / > }
                        {/* {!showAddConf && <Fab  color='primary' size="small" style={{top: '2px'}} className="websecndcoladshp1" ><PlaylistAddTwoToneIcon  fontSize='large' className='' onClick={addAlltoShop} / ></Fab> } */}
                        {/* {!showAddConf && <a  href="#"><i className="material-icons websecndcoladshp1" id='addtoshp' onClick={addAlltoShop} >playlist_addr</i></a>} */}
                        {showAddConf && <CheckCircleTwoToneIcon className="websecndcoladshp1" fontSize="large" style={{ color: green[500] }}></CheckCircleTwoToneIcon>}
                    </Col>
                </Row>
            </Col>

            <Table striped >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Item</th>
                        <th></th>
                        <th></th>
                        <th>Quantity</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {ingRow.map((rw,indx)=><WebSecndColIngRow roowidval={uuidv4()} key={uuidv4()} rowData={rw} addShopFunc={addShopList} rownum={indx} loadingBtnFlg={addShopLoadingbtn} updateLoadingBtnflg={setAddloadingBtnFlg} />)}
                    {/* <tr>
                        <th scope="row">1</th>                    
                        <td >Mark</td>
                        <td></td>
                        <td></td>
                        <td >Otto</td>
                        <td><a  href="#"><i className="material-icons websecndcoladshp2" id='addtoshp1' >add_circle_outline</i></a></td>
                    </tr>
                    <tr>
                        <th scope="row">1</th>                    
                        <td >Mark</td>
                        <td></td>
                        <td></td>
                        <td >Otto</td>
                        <td><a  href="#"><i className="material-icons websecndcoladshp2" id='addtoshp1' >add_circle_outline</i></a></td>
                    </tr>
                    <tr>
                        <th scope="row">1</th>                    
                        <td >Mark</td>
                        <td></td>
                        <td></td>
                        <td >Otto</td>
                        <td><a  href="#"><i className="material-icons websecndcoladshp2" id='addtoshp1' >add_circle_outline</i></a></td>
                    </tr>
                    <tr>
                        <th scope="row">1</th>                    
                        <td >Mark</td>
                        <td></td>
                        <td></td>
                        <td >Otto</td>
                        <td><a  href="#"><i className="material-icons websecndcoladshp2" id='addtoshp1' >add_circle_outline</i></a></td>
                    </tr> */}
                </tbody>

            </Table>
        </Row>}
        

        {/* <!--####### row div for steps for recipe #######--> */}
        
        <Row className='websecndcolrw5'>
            <Col>
                {(props.inputData.recipetype ==='text' || !props.inputData.recipetype) ? <h2><Badge variant="secondary" style={{left:'44%'}} className='websecndcolrecpbdge'>How To?</Badge></h2>: <h2><Badge variant="secondary" className='websecndcolrecpbdge'>Notes</Badge></h2>}
                <Jumbotron fluid>
                    <Container>
                        {/* <Form.Control as="textarea" rows="15" id="comment" value={props.inputData.steps} readOnly/> */}
                        <p><pre>{props.inputData.steps}</pre></p>
                    </Container>
                </Jumbotron>
            </Col>
        </Row>

         <Modal show={showHdrPop} onHide={runOnClose}  className='hdreditfilemodal' >
            {/* <Modal.Header className='webcreatemodalhdr' closeButton>
                <Modal.Title>Edit Header Image</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <Form.Label>Select File:</Form.Label>
                <Form.Control type="file" id="recipefile" onChange={handleHdrFilechange} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={runOnClose}>
                    Close
                </Button>
                <Button variant="primary" style={{backgroundColor: '#363062'}} onClick={saveHdrImagetosrv} >
                    Save Changes
                </Button>      
            </Modal.Footer>
        </Modal>

    </Col>
    }catch(e){
        return fallbackUi
    }
    


    
}

export default WebSecndCol