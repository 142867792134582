import React,{useState} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import NavbarHdr from './NavbarHdr'
// import WebCreateModal from './components/WebCreateModal'

import * as serviceWorker from './serviceWorker';



ReactDOM.render(
  <React.StrictMode>
    {/* <NavbarHdr />
    <WebCreateModal /> */}    
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();