import React, { useState,useEffect } from 'react'
import { Button,Form,Row,Col,Table,Modal,Nav,Spinner,Alert } from 'react-bootstrap';
import ShopListModalRow from './ShopListModalRow'
import { v4 as uuidv4 } from 'uuid';
import AddShoppingCartTwoToneIcon from '@material-ui/icons/AddShoppingCartTwoTone';
import Fab from '@material-ui/core/Fab';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

function ShopListPop(props){

    //#######################get shop category values##############################
    const [categValues,setCategvalues]=useState([])
    useEffect(()=>{
        if(categValues.length===0){
            const apiurl=`${process.env.REACT_APP_API_HOST}/getcategories`
          
          fetch(apiurl,{
            credentials: 'include',
            method:'GET',
            headers:{
              // 'Authorization':`Bearer ${process.env.REACT_APP_AUTH_TKN}`,
              'Authorization':`Bearer ${props.passAuthTkn}`
            }
          }).then((out)=>{
            if(out.status===500){
              throw Error('Error Getting the categories')
            }else if(out.status!==200){
              throw Error('Error Getting the categories')
            }
            return out.json()
          }).then((outjson)=>{
            console.log(outjson)
            setCategvalues((oldval)=>[...outjson])
          }).catch((e)=>{
            // chngeItems([])
            
            // alert(e)
          })
        }
        
    },[])

    //#######################end get shop category values##############################


    console.log(props.inputShpData)
    console.log(props.recpData)
    const [showLoading,setLoading]=useState(false)
    const [loadingRowDisbled,setDisabled]=useState(false)

    // const [shopListRw,chngShopRow]=useState([{
    //     itemname:'A new item'
    // }])
    const [shopListRw,chngShopRow]=useState([])
    useEffect(()=>{
        chngShopRow([])
        //query shoplist data
        if(props.recpData){
            if(Object.keys(props.recpData).length>0){
                const apiurl=`${process.env.REACT_APP_API_HOST}/getlistitem/me/${props.recpData.id}`
                fetch(apiurl,{
                    credentials: 'include',
                    headers:{
                        'Authorization':`Bearer ${props.passAuthTkn}`
                    }
                }).then((out)=>{
                    console.log(out.status)
                    let dataOut=out.json()
                    if(out.status===404){
                        // console.log('*********************************************************inside inside error')
                        dataOut=[]
                    }else if(out.status!==200){
                        dataOut=[]
                    }
                    return dataOut
                }).then((datajsonout)=>{
                    console.log('in the loop')
                    console.log(datajsonout)
                    let rwval=datajsonout?datajsonout:[]
                    // setIngRows(ingval)
                    chngShopRow(rwval)
                    // consol
                    // datarw=[...datajsonout]
                }).catch((err)=>{
                    console.log('in error loop')
                    chngShopRow([])
                })
            }else{
                chngShopRow([])
            }
        }
        
        // chngShopRow([])
    },[props.recpData])
    useEffect(()=>{

        if(Object.keys(props.inputShpData).length!==0){
            if(props.inputShpData.mode){
                chngShopRow((oldval)=>[...oldval,...props.inputShpData.itms])
            }else{
                chngShopRow((oldval)=>[...oldval,props.inputShpData])
            }
            
        }
        
    },[props.inputShpData])

    //############modify shop row category############
    function upDateCategonChange(inpt){
        
        shopListRw.map((rws)=>{
            if(!rws.shopapprowid){
                if(rws.id===inpt.changeId){
                    rws.category=inpt.selectedval
                }
            }           
            return rws
        })
        console.log(shopListRw)
    }

    //############end modify shop row category############

    //deleting shopping items
    function delShopItem(itm){
        
        if(itm.source!=='new'){
            setLoading(true)
            const apiurl=`${process.env.REACT_APP_API_HOST}/completelistitem/me/${itm.id}`
        fetch(apiurl,{
            credentials: 'include',
            headers:{
                'Authorization':`Bearer ${props.passAuthTkn}`
            }
        }).then((out)=>{
            if(out.status===404){
                throw Error('Shopping item not found on server. Please refresh and try again!!!')
            }else if(out.status===500){
                throw Error('Server error in deleting shopping list item. Please refresh and try again!!!')
            }
            return out.json()
        }).then((outjson)=>{
            console.log('deleted item')
            setLoading(false)
            chngShopRow((oldVal)=>{
                return oldVal.filter((rwVal)=>rwVal.id!==itm.id)
            })
        }).catch((e)=>{
            setLoading(false)
            alert(e)
        })
        }else{
            chngShopRow((oldVal)=>{
                return oldVal.filter((rwVal)=>rwVal.id!==itm.id)
            })
        }        
    }

    //add all shopping items to shop app
    function addAlltoShopApp(){
        
        console.log(shopListRw)
        let shopApprows=shopListRw.filter((vals)=>vals.shopapprowid)
        let insrtData=[]
        shopListRw.forEach((rw)=>{
            if(rw.source==='new'){
                delete rw.id
                delete rw.source
                insrtData.push(rw)
            }
        })
        const apiurl=`${process.env.REACT_APP_API_HOST}/addtolist/me/${props.recpData.id}`

        insrtData.forEach((shprw)=>{
            // setLoadng(true)
            setDisabled(true)
            setLoading(true)
            fetch(apiurl,{
                method:'POST',
                credentials: 'include',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Bearer ${props.passAuthTkn}`
                    },
                body:JSON.stringify(shprw)
            }).then((out)=>{
                console.log(out.status)
                if(out.status===400){
                    throw Error('Error in the request to add to shopping list. Please refresh and try again!!!')
                }else if(out.status===500){
                    throw Error('Server error in adding to shopping list. Please refresh and try again!!!')
                }
                return out.json()
            }).then((outjson)=>{
                setDisabled(false)
                shopApprows.push(outjson)
                console.log(shopApprows)
                chngShopRow([])
                chngShopRow(shopApprows)
                // setLoadng(false)
                setLoading(false)
            }).catch((e)=>{
                setDisabled(false)
                console.log(e)
                // setLoadng(false)
                setLoading(false)
                alert(e)
            })
        })
    }

    function runOnClose(){

        // chngShopRow([{
        //     itemname:'A new item'
        // }])
        props.handleHide()
    }

    const fallbackui=<Modal show={props.handleDisp} onHide={runOnClose}  ><Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert></Modal>

    try{
        return <Modal show={props.handleDisp} onHide={runOnClose}  >
        <Modal.Header className='mobcreatemodalhdr' closeButton>
            <Modal.Title>Shopping List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row style={{paddingTop:40}}>
                <Col sm>
                    <Table hover>
                        <tbody>
                            {shopListRw.map((rw)=><ShopListModalRow key={uuidv4()} rowData={rw} delRowFunc={delShopItem} upDateCategValue={upDateCategonChange} loadingIcoCtrl={showLoading} chngeLoadingFlg={setLoading} categRows={categValues} categDisabled={loadingRowDisbled} />)}
                            {/* <tr>
                                <td>Mark akdahsj akh alskdasldj alksd </td>
                                <td>
                                    <a  href="#"><i className="material-icons mobshopmodalico" id='mobremshplst'>
                        delete_forever
                            </i></a>
                                </td>
                            </tr>
                            <tr>
                                <td>Mark akdahsj akh alskdasldj alksd </td>
                                <td>
                                    <a  href="#"><i className="material-icons mobshopmodalico" id='mobremshplst'>
                        delete_forever
                            </i></a>
                                </td>
                            </tr>
                            <tr>
                                <td>Mark akdahsj akh alskdasldj alksd </td>
                                <td>
                                    <a  href="#"><i className="material-icons mobshopmodalico" id='mobremshplst'>
                        delete_forever
                            </i></a>
                                </td>
                            </tr>
                            <tr>
                                <td>Mark akdahsj akh alskdasldj alksd </td>
                                <td>
                                    <a  href="#"><i className="material-icons mobshopmodalico" id='mobremshplst'>
                        delete_forever
                            </i></a>
                                </td>
                            </tr> */}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Modal.Body>

        <Modal.Footer>
            <Nav.Link>
            <Fab color='primary' size="small" aria-label="add" >
                <CloseTwoToneIcon fontSize='medium'  onClick={()=>props.handleHide()} />
              </Fab>
                {/* <i className="material-icons mobshopmodalcncel" id='mobcncelshpadd' onClick={()=>props.handleHide()}>
              cancel
                  </i> */}
            </Nav.Link>
            <Nav.Link>

            {/* {showLoading?<Spinner animation="border" variant="primary" />: <i className="material-icons mobshopmodalcncel" id='mobshpaddbtn' onClick={addAlltoShopApp} >
            add_shopping_cart
                  </i>} */}
                  {showLoading?<Spinner animation="border" variant="primary" /> : <AddShoppingCartTwoToneIcon color='primary' fontSize='large' onClick={addAlltoShopApp}/>} 
           
            </Nav.Link>
        </Modal.Footer>
    </Modal>
    }catch(e){
        return fallbackui
    }
    
}

export default ShopListPop