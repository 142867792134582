import React, { useState, useEffect } from 'react'
import { Container, Col,Form,Table,Row,Spinner,Image,Card,Alert } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import sampleback from '../../images/recipe1.png'
import sampleback2 from '../../images/recipe2.png'
import sampleback3 from '../../images/recipe3.png'
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';

function WebFrstColRow(props){
    const [rwstyl,setrwStyl1]=useState(props.indx!==props.selected?{}:{backgroundColor:'#d9d9d9'})
    const [editIcoFlg,setEditIco]=useState(props.indx!==props.selected?false:true)
    // function readColRow(event){
    //     // console.log(event.target)
    //     // event.preventDefault();
    //     // setrwStyl1({backgroundColor:'red'})
    //     // console.log(rwstyl)
    //     props.selfunc(props.indx)
    //     event.preventDefault();
    //     setrwStyl1({backgroundColor:'#d9d9d9'}) 
    //     props.readcolData(props.rowData)
    // }
    const [selctdRowData,setSelctddata]=useState({})

    function readColRow(event){
        event.preventDefault();
        props.selfunc(props.indx)
        setrwStyl1({backgroundColor:'#d9d9d9'}) 
        setEditIco(true)
        //query data and send data
        const apiurl=`${process.env.REACT_APP_API_HOST}/getrecipeid/me/${props.rowData.id}`
        fetch(apiurl,{
            credentials: 'include',
            headers:{
            // 'Authorization':`Bearer ${process.env.REACT_APP_AUTH_TKN}`
            'Authorization':`Bearer ${props.apiauth}`
            }
        }).then((out)=>{
            if(out.status===404){
                throw Error('Recipe not found on server. Please refresh the page and try again')
            }else if(out.status!==200){
                throw Error('Server error on getting details. Please refresh the page and try again')
            }
            return out.json()
        }).then((datajsonout)=>{
            // console.log(datajsonout)
            // chngeItems(datajsonout)
            // setLoading(false)
            // props.resetFlgEdit(false)
            //------------------------props.selfunc(props.indx)
            // event.preventDefault();
            //------------------setrwStyl1({backgroundColor:'#d9d9d9'}) 
            // setSelctddata(datajsonout[0])
            props.getTmpdata(datajsonout[0])
            props.readcolData(datajsonout[0])
          // props.resetHdrFlag(false)
          // consol
          // datarw=[...datajsonout]
        }).catch((e)=>{
            props.getTmpdata({})
            props.readcolData({})
            alert(e)
        })
    }

    const [delLoading,setDelLoading]=useState(false)

    //----------------------------when a new row is selected, refresh the data
    useEffect(()=>{
        if(props.rowRfrsh){
            // props.selfunc(props.selected)
            // setrwStyl1({backgroundColor:'#d9d9d9'}) 
            if(props.indx===props.selected){
                const apiurl=`${process.env.REACT_APP_API_HOST}/getrecipeid/me/${props.rowData.id}`
                fetch(apiurl,{
                    credentials: 'include',
                    headers:{
                    'Authorization':`Bearer ${props.apiauth}`
                    }
                }).then((out)=>{
                    if(out.status===404){
                        throw Error('Recipe not found on server. Please refresh the page and try again')
                    }else if(out.status!==200){
                        throw Error('Server error on getting details. Please refresh the page and try again')
                    }
                    return out.json()
                }).then((datajsonout)=>{
                    // console.log(datajsonout)
                    // chngeItems(datajsonout)
                    // setLoading(false)
                    // props.resetFlgEdit(false)
                    //------------------------props.selfunc(props.indx)
                    // event.preventDefault();
                    //------------------setrwStyl1({backgroundColor:'#d9d9d9'}) 
                    props.getTmpdata(datajsonout[0])
                    props.readcolData(datajsonout[0])
                    
                    // props.resetHdrFlag(false)
                    // consol
                    // datarw=[...datajsonout]
                }).catch((e)=>{
                    props.getTmpdata({})
                    props.readcolData({})
                    alert(e)
                })
                // props.readcolData(props.rowData)---old code
            }            
        }        
    },[props.rowRfrsh])

    //---------------------------------------to get the selected row data back to higher parent components
    function togglEditPop(event){
        event.preventDefault();
        if(Object.keys(props.tmpData).length===0){
            console.log(props.tmpData)
            const apiurl=`${process.env.REACT_APP_API_HOST}/getrecipeid/me/${props.rowData.id}`
                fetch(apiurl,{
                    credentials: 'include',
                    headers:{
                    'Authorization':`Bearer ${props.apiauth}`
                    }
                }).then((out)=>{
                    if(out.status===404){
                        throw Error('Recipe not found on server. Please refresh the page and try again')
                    }else if(out.status!==200){
                        throw Error('Server error on getting details. Please refresh the page and try again')
                    }
                    return out.json()
                }).then((datajsonout)=>{
                    // console.log(datajsonout)
                    // chngeItems(datajsonout)
                    // setLoading(false)
                    // props.resetFlgEdit(false)
                    //------------------------props.selfunc(props.indx)
                    // event.preventDefault();
                    //------------------setrwStyl1({backgroundColor:'#d9d9d9'}) 
                    // props.getTmpdata(datajsonout[0])
                    // props.readcolData(datajsonout[0])
                    props.showEditpop(datajsonout[0])
                    
                    // props.resetHdrFlag(false)
                    // consol
                    // datarw=[...datajsonout]
                }).catch((e)=>{                    
                    alert(e)
                })
        }else{
            // props.showEditpop(props.rowData)
            props.showEditpop(props.tmpData)
        }
        
    }

    function delItemRow(event){
        setDelconfFlg(false);
        event.preventDefault();
        // hndleDelDiaOpen()
        console.log(props.rowData)
        // props.selfunc(props.indx+1)
        //###############delete
        // const apiurl=`${process.env.REACT_APP_API_HOST}/deleterec/me/${props.rowData.id}`
        const apiurl=`${process.env.REACT_APP_API_HOST}/completerec/me/${props.rowData.id}`
        setDelLoading(true)
        fetch(apiurl,{
            // method:'DELETE',
            credentials: 'include',
            headers:{
                // 'Content-Type':'application/json',
                'Authorization':`Bearer ${props.apiauth}`
                }
        }).then((out)=>{
            if(out.status===404){
                throw Error('No recipe found to delete on server. Please refresh and try again!!!')
            }else if (out.status!==200){
                throw Error('Error from server on deleting the recipe. Please refresh and try again!!!')
            }
            return out.json()
        }).then((outjson)=>{
            console.log(outjson)
            setDelLoading(false)
            props.readcolData([])
            props.selfunc(-1)
            // setrwStyl1({})
            props.delRowMethod(props.rowData.id)
            console.log('deleted')
            //delete elastic index
            const esurl=`${process.env.REACT_APP_API_HOST}/deldocument`
            const esdata={
                indexname:"recipes",
                doc_id:props.rowData.id
                }
            fetch(esurl,{
              credentials: 'include',
              method:'POST',
              headers:{
                'Authorization':`Bearer ${props.apiauth}`,
                'Content-Type':'application/json'
              },
              body:JSON.stringify(esdata)
            }).then(()=>{
                console.log('deleted index')
            })
            //end delete elastic index
            
        }).catch((e)=>{
            setDelLoading(false)
            alert(e)
        })
        //###############end delete
        // props.readcolData([])
        // props.selfunc(-1)
        // setrwStyl1({})
        // props.delRowMethod(props.rowData.id)
    }

    //###################################delete confirm dialog#######################

    const [delconfFlg, setDelconfFlg] = useState(false);
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const hndleDelDiaOpen = () => {
        setDelconfFlg(true)
    };

    const hndleDelConClose = () => {
        setDelconfFlg(false);
    }
  //################################### end delete confirm dialog#######################



    const imgArray={1:sampleback,2:sampleback2,3:sampleback3}
    // const rowImg=imgArray[Math.floor(Math.random()*3)+1]
    const [rowImg,setRwImg]=useState('')
    useEffect(()=>{
        setRwImg(imgArray[Math.floor(Math.random()*3)+1])
    },[])
    

    const fallBackui=<Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert> 

    try{
        return <tr className='webfrstcolrw'  style={rwstyl} >
            <td className='align-middle webfrscol1' onClick={readColRow}>
                <Card className='webhomerocol1img'>
                    <Image className='webhomecol1imgtag' src={`${rowImg}`} fluid rounded/>
                </Card></td>
            <td colSpan="1" onClick={readColRow} style={{wordBreak:'break-all'}}>
                <div><b>{props.rowData.recipename}</b></div>
                {/* <div>Content</div> */}
            </td>
            <td className='align-middle webfrscol2'>
            { editIcoFlg && <EditTwoToneIcon color='primary' fontSize='medium' onClick={togglEditPop} />}
            {/* {editIcoFlg && <a  href="#"><i className="material-icons webeditfrstrw" id='additem' onClick={togglEditPop}>
                edit
                </i></a>} */}
            </td>
            <td className='align-middle webfrscol3'>
                {delLoading?<Spinner animation="border" variant="primary" /> : <DeleteForeverTwoToneIcon color='secondary' fontSize='medium' onClick={hndleDelDiaOpen} ></DeleteForeverTwoToneIcon>}
                {/* <a  href="#">{delLoading?<Spinner animation="border" variant="primary" />:<i className="material-icons webdelfrstrw" id='delitem' onClick={hndleDelDiaOpen}>
                delete_forever
                </i>}</a> */}
            </td>

            {/*************************delete dialog************************/}
            <Dialog
                fullScreen={fullScreen}
                open={delconfFlg}
                onClose={hndleDelConClose}
                aria-labelledby="responsive-dialog-title" >
                <DialogTitle id="responsive-dialog-title">{"Sure to Delete?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={hndleDelConClose} color="primary">
                    No
                    </Button>
                    <Button onClick={delItemRow} color="primary" autoFocus>
                    Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* *************************delete dialog************************* */}
        </tr>
    }catch(e){
        return fallBackui
    }
    
}

export default WebFrstColRow


    
    // console.log(`Here is the index ${props.indx}`)
    
    // selfunc={stfunc} selected={selrow} 
    // console.log(`The selected row is: ${props.selected}`)
    // if (props.indx!==props.selected){
    //     console.log('a mismatch')
    //     const [rwstyl,setrwStyl1]=useState({backgroundColor:'blue'})
    // }else{
    //     const [rwstyl,setrwStyl1]=useState({backgroundColor:'#d9d9d9'})
    // }
    
    
    // const [sel,setsel]=useState('')
    // let rw11={}
    // setrwStyl1({})
    // console.log(`this is fired for ${props.rowData.recipename} and style is ${rwstyl.backgroundColor}`)
    // console.log('###############################################################################')
    // console.log('###############################################################################')
    // console.log('###############################################################################')


    
    // function test(event){
    //     if(props.indx!==props.selected){

    //     }
        
    //     // console.log(`selected row is ${props.indx}`)
    //     // setsel(props.indx)
    //     // props.setseln(sel)
    //     props.selfunc(props.indx)
    //     event.preventDefault();
    //     setrwStyl1({backgroundColor:'#d9d9d9'}) 
    //     // console.log(rwstyl)
    // }

    // function test1(event){
    //     event.preventDefault();
    //     console.log('this is from loading')
    //     // setrwStyl1({}) 
    //     // console.log(rwstyl)
    // }

//     useEffect(()=>{setrwStyl1({a:'b'})
//     console.log(rwstyl)
// },[rwstyl])

    

    