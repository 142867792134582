import React,{useState} from 'react'
import { Button,Form,Row,Col,Navbar,Nav,Alert } from 'react-bootstrap';
import mainlogo from '../../images/webmainheader.svg'
import LibraryAddTwoToneIcon from '@material-ui/icons/LibraryAddTwoTone';

function MobHdrLanding(props){
    const [createStyle,setCreateStyle]=useState({color:'rgba(255,255,255,.75)'})
  const changeCreateStyle=()=>{
    // alert('akjhdjh')
    setCreateStyle({})
    setCreateStyle({color:'rgba(255,255,255,.75)'})
  }
  const changeFocusStyle=()=>{
    // alert('Focus')\
    setCreateStyle({})
    setCreateStyle({color:'#FFF'})
  }

  function logOutMethod(){
    props.setlogoutFlag(false)
    props.logoutRemoveTkn('unset')
    localStorage.removeItem('appky')
    const apiurl=`${process.env.REACT_APP_API_HOST}/logout`
    fetch(apiurl,{
      credentials: 'include',
      headers:{
        'Authorization':`Bearer ${props.tokenValue}`
      }
    }).then((out)=>{
      if(out.status!==200){
        throw Error('Error in Logging out!!')
      }
      return out.json()
    }).then((outjson)=>{
      console.log(outjson)
      // props.setlogoutFlag(false)
      // props.logoutRemoveTkn('unset')
      // localStorage.removeItem('appky')
      // console.log(outjson)
    }).catch((e)=>{
      alert('Error in logging Out!!')
    })
  }

    const fallbackui=<Navbar bg="dark" variant="dark" expand="sm" className="mobilenavbar"><Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert></Navbar>
    
    try{
        return <div>
        <Navbar bg="dark" variant="dark" expand="sm" className="mobilenavbar">
            <Navbar.Brand onClick={()=>window.location.reload(false)} className='mobilenavbarhdrbrand' ><img
        src={mainlogo}
        width="50"
        height="40"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
        style={{margin:'0'}}></img></Navbar.Brand>

            {/* <Form className='mobnavbarsearchform'>
                <Row>
                    <Col>
                        <Form.Control className='mobsearchfield' placeholder="Search for a Recipe" aria-label="Search for a Recipe" aria-describedby="basic-addon2" />
                    </Col>
                </Row>
            </Form> */}
        <Navbar.Toggle className='ml-auto' aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav.Link className='nav-item' href="#home">
          <LibraryAddTwoToneIcon style={createStyle} fontSize='medium'onClick={()=>props.handleMobShow()} />
        {/* <i style={createStyle} className="material-icons" id='additem' onClick={()=>props.handleMobShow()}>
                library_add
                    </i> */}
                    </Nav.Link>
        <Nav.Link className='ml-auto nav-item moblogoutlink' onClick={logOutMethod} style={createStyle}><strong>Logout</strong></Nav.Link>
        </Navbar.Collapse>    
    </Navbar>
    </div>
    }catch(e){
        return fallbackui
    }
    
}

export default MobHdrLanding