import React, { useState } from 'react'
import { Container, Col,Form,Table,Row,Badge,Jumbotron,Card,Alert } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import { green } from '@material-ui/core/colors';
import AddShoppingCartTwoToneIcon from '@material-ui/icons/AddShoppingCartTwoTone';

function MobDetailPageingRows(props){

    const [showAddConf,setShowConf]=useState(props.loadingBtnFlg[props.rowSnum]?true:false)
    if(showAddConf){
        setTimeout(()=>{
            props.updateLoadingBtnflg((oldval)=>{
                oldval[props.rowSnum]=false
                return oldval
            })
            setShowConf(false)
        },2000)
    }

    function onClickShpChnge(){
        const inpt={
                id:uuidv4(),
                itemdesc:`${props.rowData.itemname}:${props.rowData.quantity}`,
                source:'new',
                category:"Walmart"
            }
        props.updteShopLst(inpt)
        props.updateLoadingBtnflg((oldval)=>{
            oldval[props.rowSnum]=true
            return oldval
        })
    }

    const fallBackui=<Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert>

    try{
        return <tr>
                <th>{props.rowSnum+1}</th>                    
                <td className='mobsecndcol1' >{props.rowData.itemname}</td>
                
                <td></td>
                <td className='mobsecndcol2' >{props.rowData.quantity}</td>
                {/* <td></td> */}
                {!showAddConf && <td><AddShoppingCartTwoToneIcon color='primary' fontSize='medium' className='mobdetladrwshpico2' onClick={onClickShpChnge} /></td>}
                {/* {!showAddConf && <td><a  href="#"><i className="material-icons mobdetladrwshp1" id='mobdetladds1' onClick={onClickShpChnge} >add_circle_outline</i></a></td>} */}
                {showAddConf && <td><CheckCircleTwoToneIcon className="mobdetladrwshp1" fontSize="medium" style={{ color: green[500] }}></CheckCircleTwoToneIcon></td>}
            </tr>
    }catch(e){
        return fallBackui
    }
    
}

export default MobDetailPageingRows