import React, { useState } from 'react'
import { Container, Col,Form,Table,Row,Badge,Jumbotron,Card,InputGroup,Button,Modal,Alert } from 'react-bootstrap';
import srchbck from '../../images/search-field.png'

function WebLandingpage(props){

    function navSrchReslts(event){

        props.navLndnpage()
        props.setHdrToggl('default')
    }

    console.log(props.authTokenValue)
    const [srchValue,setSrchval]=useState('')
    function changeSrchWord(event){
        const val=event.target.value
        setSrchval(val)
        props.getSrchWord(val)
    }

    const [showCrdts,setShwCrdts]=useState(false)

    const fallBackui=<Col className='weblndngmaincol'><Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert></Col>

    try{
        return <Col className='weblndngmaincol'>
    <div className='weblndnsearchbar'>
        <InputGroup className="mb-3">
            {/* <Form.Control type="text" onChange={navSrchReslts} style={{backgroundImage:`url(${srchbck})`,backgroundPosition:'right',backgroundRepeat:'no-repeat'}} className='webmainsrchfrm' placeholder="Search for a Recipe" id="webmainsrchfld"  aria-label="Search for a Recipe" aria-describedby="basic-addon2" /> */}
            <Form.Control type="text" className='webmainsrchfrm' placeholder="Search for a Recipe" id="webmainsrchfld"  aria-label="Search for a Recipe" aria-describedby="basic-addon2" onChange={changeSrchWord} />
            {/* <Button className='webhomesrchbtn' style={{width:'50px',height:'30px',position:'absolute',right:'0px',top:'20px',backgroundColor:'transparent'}}></Button> */}
            {/* <Button className='webhomesrchbtn'></Button> */}
            <div className='webhomesrchbtn' onClick={navSrchReslts}  ></div> 
        </InputGroup>
    </div>
    <div className='webcredits'>
            <a href='#' style={{color:'#333333'}} onClick={()=>setShwCrdts(true)} >Credits</a>
    </div>

    <Modal show={showCrdts} onHide={()=>setShwCrdts(false)} >
            {/* <Modal.Header className='webcreatemodalhdr' closeButton>
                <Modal.Title>Edit Header Image</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <Badge pill variant="secondary" style={{color:'black'}}>
                Icons made by <a href="https://www.flaticon.com/authors/freepik" target="_blank" title="Freepik" style={{color:'blue'}}>Freepik</a> from <a href="https://www.flaticon.com/" target="_blank" title="Flaticon" style={{color:'blue'}}>www.flaticon.com</a>
                
                </Badge>{' '}
                <Badge pill variant="secondary">
                    <a target="_blank" href="https://icons8.com/icons/set/cooking-book" style={{color:'blue'}}>Cooking Book icon</a> icon by <a target="_blank" href="https://icons8.com" style={{color:'blue'}}>Icons8</a>
                </Badge>
            </Modal.Body>            
        </Modal>
</Col>
    }catch(e){
        return fallBackui
    }
    
}

export default WebLandingpage