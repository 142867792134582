import React,{useRef,useContext} from 'react'
import { Modal,Button,Container,Row,Col,Form,Badge,Table,Jumbotron,Spinner,Tooltip,Overlay,Alert } from 'react-bootstrap';
import MobCreateModalingRow from './MobCreateModalIngRow'
import { useState,useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import IconButton from '@material-ui/core/IconButton';
import NoteIcon from '@material-ui/icons/Note';
import CachedIcon from '@material-ui/icons/Cached';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { set, get } from 'idb-keyval'
import draftContext from '../../contexts/draftCreate'

const accent = lightBlue['900'];

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function WebCreateModal(props){
    const classes = useStyles();

    const [ingrows,ChngeIngRw]=useState([1])
    const [loadDrftClked,setLoadDrftClck]=useState(false)
    const [loadingIco,setLoading]=useState(false)
    const [invalidFlgs,setinValidFlg]=useState([false,false,false,false])
    const [draftRecipe,setDraftRecipe]=useState({})
    const [drftContext, setdraftContxt] = useContext(draftContext);

    function addNewingrows(){
      setLoadDrftClck(false)
      ChngeIngRw((oldval)=>[...oldval,uuidv4()])
    }

    function addNewDrftingrows(val){
      ChngeIngRw((oldval)=>[...oldval,val])
    }

    function delallDraftIngRows(val){
      // ChngeIngRw((oldVal)=>ingrows.filter((rw)=>rw!==val))
      // ChngeIngRw([])
      ChngeIngRw((oldval)=>{
        let newval=oldval.slice(1,oldval.length)
        return newval
      })
    }

    function runOnClose(){
      const tmpflgs=[false,false,false,false]
      setinValidFlg((oldval)=>[...tmpflgs])
      setInvCrtFlg(false)
      setInvCrtsrvFlg(false)
      setInvCrtprpFlg(false)
      //blank out all form fields
      setCrtName('')
      setCrtType('Link')
      setCrtUrl('')
      setCrtPrep('')
      setCrtSrvng('')
      setCrtStps('')
      setingRowVals([])
      ChngeIngRw([1])
      props.handleClose()
    }

    useEffect(()=>{
      console.log('this is setting the valuf of the draft value')
      get("draftRecipe").then(value =>
        
        // setDraftRecipe(((oldval)=>{return {...value}}))
        setdraftContxt(((oldval)=>{return {...value}}))
      ).catch((e)=>{
        // setDraftRecipe({})
        setdraftContxt({})
      })
    },[])

    //######################################capture all ing row changes##############################
    // set('hello', 'world')
    const [ingRowVals,setingRowVals]=useState([])
    function changeIngVals(valObj){
      if(ingRowVals.length===0){
        return setingRowVals([...ingRowVals,valObj])
      }
  
      let updFlg=false
      let updIngs=ingRowVals.map((v)=>{
        if (v.id===valObj.id){
          v.itemname=valObj.itemname
          v.quantity=valObj.quantity
          updFlg=true
          
        }
        return v
      })
      if(!updFlg){
        updIngs=[...updIngs,valObj]
      }
      setingRowVals(updIngs)
      console.log('this is running####################################################')
      console.log(ingRowVals)
  
    }
  
    const [ingRowCopy,setingRowCopy]=useState(ingRowVals)
    useEffect(()=>{
      let opRows=[]
      ingRowVals.forEach((val)=>{
        let tmparray={}
        Object.keys(val).forEach((ObjRow)=>{
          if(ObjRow==='id'){
  
          }else{
            tmparray[ObjRow]=val[ObjRow]
          }
        })
        opRows.push(tmparray)
      })
      setingRowCopy(opRows)
    },[ingRowVals])
  
    function removeIdvals(inptId){
      console.log(inptId)
      let op=[...inptId]
      op=op.map((v)=>{
        delete v['id']
        return v
      })
      console.log('##########################################in remove')
      console.log(op)
      return op
    }
    //######################################end capture all ing row changes##############################

    //######################################state values for all form fields######################
    const [crtName,setCrtName]=useState('')
    const [crtNmeInvFlg,setInvCrtFlg]=useState(false)
    function changeCrtName(event){
      event.preventDefault()
      const val=event.target.value
      if(val.length>60){
        // alert('keep the name short and crisp!!')
        setInvCrtFlg(true)
        return
      }
      setInvCrtFlg(false)
      setCrtName(val)
    }

    const [crtType,setCrtType]=useState('Link')
      function changeCrtType(event){
        event.preventDefault()
        const val=event.target.value
        setCrtType(val)
      }

    const [crtTUrl,setCrtUrl]=useState('')
      function changeCrtUrl(event){
        event.preventDefault()
        const val=event.target.value
        setCrtUrl(val)
      }

    const [crtPrep,setCrtPrep]=useState('')
    const [crtprpInvFlg,setInvCrtprpFlg]=useState(false)
      function changeCrtPrep(event){
        event.preventDefault()
        const val=event.target.value
        if(val.length>20){
          // alert('keep the name short and crisp!!')
          setInvCrtprpFlg(true)
          return
        }
        setInvCrtprpFlg(false)
        setCrtPrep(val)
      }

    const [crtSrvng,setCrtSrvng]=useState('')
    const [crtsrvInvFlg,setInvCrtsrvFlg]=useState(false)
      function changeCrtSrvng(event){
        event.preventDefault()
        const val=event.target.value
        if(val.length>20){
          // alert('keep the name short and crisp!!')
          setInvCrtsrvFlg(true)
          return
        }
        setInvCrtsrvFlg(false)
        setCrtSrvng(val)
      }

    const [crtStps,setCrtStps]=useState('')
      function changeCrtStps(event){
        event.preventDefault()
        const val=event.target.value
        setCrtStps(val)
      }

       //----------------file field-------
    const [selctdFile,changeSelFile]=useState(undefined)
      function handleFileLoad(event){
        const fleCntnt=event.target.files[0];
        changeSelFile(fleCntnt)
        // const fleData=new FormData()
        // console.log(fleCntnt)
      }
  //---------------file field----------

  //#########################load the draft function#######################
  const [draftIngrRows,setDrftIngrrows]=useState([])
  async function loadDraft(){
    let loadedDraft=await get("draftRecipe")
    setdraftContxt(((oldval)=>{return {...loadedDraft}}))
    setCrtName(drftContext.recipename)
    setCrtType(drftContext.recipetype)
    setCrtUrl(drftContext.recipeurl)
    setCrtPrep(drftContext.preptime)
    setCrtSrvng(drftContext.servings)
    setCrtStps(drftContext.steps)
    setDrftIngrrows((oldval)=>[...oldval,...draftIngrRows])
    setLoadDrftClck(true)
    let tmpDraftRows=[...drftContext.ingredients]
    tmpDraftRows[0].id=uuidv4()
    //-----load the ingr rows--------
    drftContext.ingredients.forEach((v)=>{
      // delallDraftIngRows(1)
      // if (v.id===1){
      //   delallDraftIngRows(1)
      //   addNewDrftingrows(v.id)
      // }else{
        console.log('This is the stored row value---------------')
        console.log(draftIngrRows)
        addNewDrftingrows(v.id)
        // addNewingrows()
        // delallDraftIngRows()
      // }
      
    })
    setingRowVals(tmpDraftRows)
    
    
    //-----load the ingr rows------
    // let draftOut=await get("draftRecipe")
    // console.log(draftOut)
    // alert(draftOut)
    // get("draftRecipe").then(value =>
    //   setDraftRecipe(((oldval)=>{return {...value}}))
    // ).catch((e)=>{
    //   setDraftRecipe({})
    // })
  }

  async function validateDraft(){
    let drftflg=await get("draftRecipe")
    let out=drftflg?true:false
    return out
  }
  //#########################load the draft function#######################


  //###########################save the draft data##############
  async function draftSave(){
    let draftdta={
      recipename:crtName,
      recipetype:crtType,
      preptime:crtPrep,
      servings:crtSrvng,
      steps:crtStps,
      mainimage:"mainimg.png",
      recipeurl:crtTUrl,
      recipefile:"na",
      recipefileid:"na",
      // ingredients:ingRowVals.length===0?[]:ingRowCopy
      ingredients:ingRowVals.length===0?[]:ingRowVals
    }
    setLoading(true)
    try{
      await set("draftRecipe", {})
      await set("draftRecipe", draftdta)
    }catch(e){
      console.log(e)
      alert('error in saving draft')
    }
    
    setLoading(false)
  }

  //#########################save the draft data#######################

    //######################################end state values for all form fields######################

    //function to save file data
    async function saveFile(selfile){

      const fleSvcUrl=`${process.env.REACT_APP_API_HOST}/postfile`
      const filedata = new FormData()
      filedata.append('image',selfile)
      fetch(fleSvcUrl,{
        credentials: 'include',
        method:'POST',
        headers:{
          'Authorization':`Bearer ${props.passAuthTkn}`,
        // 'Content-Type':'application/json'
        },
        body: filedata
      }).then((fleOut)=>{
        if(fleOut.status===500){
          throw Error('error in loading file')
        }
        return fleOut.json()
      }).then((flejson)=>{
        Promise.resolve(flejson)
      }).catch((e)=>{
        Promise.reject('error in saving file')
      })
    }

    //validate fields
  
  function validateFields(inpt){
    console.log(inpt)
    let errflg=true
    let tmparry=[false,false,false,false]
    if(inpt.recipename===''){
      tmparry[0]=true
      errflg=false
    }
    if(inpt.recipetype==='text'){
      if(inpt.preptime===''){
        tmparry[2]=true
        errflg=false
      }
      if(inpt.servings===''){
        tmparry[3]=true
        errflg=false
      }

      }

    if(inpt.recipetype==='link'){
      if(inpt.recipeurl===''){
        tmparry[1]=true
        errflg=false
        }    
      }
      if(!errflg){
        setinValidFlg((oldval)=>[...tmparry])
      }
   return errflg
    }

    //save data
    function saveRecipe(){
      
      // get('hello').then((val)=>{
      //   console.log(`This is the indexdb value: ${val}`)
      // })
      let inptdta={
        recipename:crtName,
        recipetype:crtType.toLowerCase(),
        preptime:crtPrep,
        servings:crtSrvng,
        steps:crtStps,
        mainimage:"mainimg.png",
        recipeurl:crtTUrl,
        recipefile:"na",
        recipefileid:"na",
        ingredients:ingRowVals.length===0?[]:ingRowCopy
      }
      setinValidFlg([false,false,false,false])
      if(!validateFields(inptdta)){
        return
      }
      setLoading(true)
      // set("draftRecipe", inptdta).then(()=>{
      const apiurl=`${process.env.REACT_APP_API_HOST}/addrecipe`
      // *********************get file url****************
      if(inptdta.recipetype==='file/image'){
        if(!selctdFile){
          alert('select a file')
          setLoading(false)
          return
        }
        const fleSvcUrl=`${process.env.REACT_APP_API_HOST}/postfile`
        const filedata = new FormData()
        filedata.append('image',selctdFile)
        fetch(fleSvcUrl,{
          credentials: 'include',
          method:'POST',
          headers:{
          'Authorization':`Bearer ${props.passAuthTkn}`,
          // 'Content-Type':'application/json'
          },
        body: filedata
      }).then((fleOut)=>{
        if(fleOut.status===500){
          throw Error('error in loading file')
        }
        return fleOut.json()
      }).then((flejson)=>{
        console.log(flejson)
        //save the file type recipe
        inptdta.recipefile=flejson.imagefile
        inptdta.recipefileid=flejson.imageid
        fetch(apiurl,{
          credentials: 'include',
          method:'POST',
          headers:{
              'Authorization':`Bearer ${props.passAuthTkn}`,
              'Content-Type':'application/json'
            },
          body:JSON.stringify(inptdta)
        }).then((saveDta)=>{
          return saveDta.json()
        
        }).then((jsonData)=>{
          if(jsonData.error){
            throw Error(jsonData.error)
          }
          // props.getCreatedRec(jsonData)
          props.setCreateQry(true)
          setLoading(false)
          //clear local draft
          set("draftRecipe", {})
          // ChngeIngRw([1])
          // setdraftContxt({})
          //add to elastic search
          const esurl=`${process.env.REACT_APP_API_HOST}/adddocument`
          const esdata={
            indexname:"recipes",
            doc_id:jsonData.id,
            data:{
                recipename:jsonData.recipename
            }
          }
          fetch(esurl,{
            credentials: 'include',
            method:'POST',
            headers:{
                'Authorization':`Bearer ${props.passAuthTkn}`,
                'Content-Type':'application/json'
              },
            body:JSON.stringify(esdata)
          })
          //end add to elastic search
          runOnClose()
          // setdraftContxt({})
        }).catch((e)=>{
          console.log('in err form')
          console.log(e)
          setLoading(false)
          alert(`Error in adding recipe. Try again`)
        })
      // return
      }).catch((e)=>{
        setLoading(false)
        alert('Error in adding recipe because file was not loaded. Please try again')
      })
    return
  }

  //*********************end get file url**************** */

      
      
      fetch(apiurl,{
        credentials: 'include',
        method:'POST',
        headers:{
              'Authorization':`Bearer ${props.passAuthTkn}`,
              'Content-Type':'application/json'
            },
        body:JSON.stringify(inptdta)
      }).then((saveDta)=>{
        return saveDta.json()
        
      }).then((jsonData)=>{
        if(jsonData.error){
          throw Error(jsonData.error)
        }
        // props.getCreatedRec(jsonData)
        // props.setCreateQry(true)
        setLoading(false)
        //clear local draft
        set("draftRecipe", {})
        // ChngeIngRw([1])
        // setdraftContxt({})

        //add to elastic search
        const esurl=`${process.env.REACT_APP_API_HOST}/adddocument`
        const esdata={
          indexname:"recipes",
          doc_id:jsonData.id,
          data:{
              recipename:jsonData.recipename
            }
          }
        fetch(esurl,{
          credentials: 'include',
          method:'POST',
          headers:{
              'Authorization':`Bearer ${props.passAuthTkn}`,
              'Content-Type':'application/json'
            },
          body:JSON.stringify(esdata)
          }).then((out)=>{
            props.setCreateQry(true)
          })
        //end add to elastic search
        runOnClose()
        // setdraftContxt({})
      }).catch((e)=>{
        console.log('in err form')
        console.log(e)
        setLoading(false)
        alert(`Error in adding recipe. Try again`)
      })
      // }).catch((e)=>{

      // })
      
    }


    function delIngRowMethod(val){

      ChngeIngRw((oldVal)=>ingrows.filter((rw)=>rw!==val))
      setingRowVals((oldVal)=>ingRowVals.filter((rw)=>rw.id!==val))
    }

    const fallBackui=<Modal show={props.setdisplay} onHide={runOnClose}><Alert variant='danger' >Unexpected Error from Server on loading the component. Please refresh and try again!!</Alert></Modal>
    
    try{
      return <Modal show={props.setdisplay} onHide={runOnClose} className='mobcreatemodalmainclass' >
    <Modal.Header className='mobcreatemodalhdr' closeButton>
        <Modal.Title>Add New</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <Row className='webcreatefirstrow'>
        <Col>
        <Form className='webcreatefirstform'>
          {/* <Form.Group controlId="webcreatefirstform.ControlInput1"> */}
          <Form.Group>
            {crtNmeInvFlg && <Badge variant="dark">Keep name short and crisp!!</Badge>}
            <Form.Control style={invalidFlgs[0]?{borderColor:'red'}:{}} type="text" placeholder="Enter Name" id="recipename"  value={crtName} onChange={changeCrtName} />
          </Form.Group>

          {/* <Form.Group controlId="webcreatefirstform.ControlInput2"> */}
          <Form.Group>
            <Form.Label>Select Type:</Form.Label>
            <Form.Control as="select" id="seltype" value={crtType} onChange={changeCrtType} >
              <option>Link</option>
              <option>File/Image</option>
              <option>Text</option>
            </Form.Control>
          </Form.Group>
        </Form>
        </Col>
      </Row>

      {/* <!--####### row div for file #######--> */}
      {crtType==='File/Image' && <Row className='webcreatesecndtrow'>
        <Col>
        <Form className='webcreatesecndform'>
          {/* <Form.Group controlId="webcreatefirstform.ControlInput3"> */}
          <Form.Group>
            <Form.Label>Select File:</Form.Label>
            <Form.Control type="file" id="recipefile" onChange={handleFileLoad} />
          </Form.Group>
        </Form>
        </Col>
      </Row>}
      

      {/* <!--####### row div for url/link #######--> */}
      {crtType==='Link' && <Row className='webcreatesecndtrow'>
        <Col>
          <Form className='webcreatesecndform'>
            {/* <Form.Group controlId="webcreatefirstform.ControlInput2"> */}
            <Form.Group>
              <Form.Control style={invalidFlgs[1]?{borderColor:'red'}:{}} type="text" placeholder="Enter link" id="recipeurl" value={crtTUrl} onChange={changeCrtUrl} />
            </Form.Group>
          </Form>
        </Col>
      </Row> }
      

      {/* <!--####### row div for prep time #######--> */}
      {crtType==='Text' && <Row className='webcreatethrdtrow'>
        <Col>
          <Form style={{paddingBottom:10}}>
            <Form.Row>
              <Col>
                {crtprpInvFlg && <Badge variant="dark">Keep this short and crisp!!</Badge>}
                <Form.Control style={invalidFlgs[2]?{borderColor:'red'}:{}} type="text" placeholder="Enter Prep Time" id="preptime"  value={crtPrep} onChange={changeCrtPrep} />
              </Col>
              <Col>
              {crtsrvInvFlg && <Badge variant="dark">Keep this short and crisp!!</Badge>}
              <Form.Control style={invalidFlgs[3]?{borderColor:'red'}:{}} type="text" placeholder="Enter Servings" id="srvngs"  value={crtSrvng} onChange={changeCrtSrvng}  />
              </Col>
          </Form.Row>
          </Form>
        </Col>
      </Row>}
      


      {/* <!--####### row div for ingredients table #######--> */}
      {true && <Row className='webcreatefrthrow'>
        <Col>
          <Row>
            <Col xs={10} >
              <h1><Badge variant="secondary" className='mobcreateinghdr'>Ingredients</Badge></h1>
            </Col>
            <Col  className='mobcreateaddingbtncol' >
              <Fab color='primary' size="small" aria-label="add" style={{top: '8px'}} >
                <AddTwoToneIcon fontSize='medium'  onClick={addNewingrows} />
              </Fab>
              {/* <a  href="#"><i className="material-icons mobcreateaddingbtn" id='addalling' onClick={addNewingrows} >add_circle_outline</i></a> */}
            </Col>
          </Row>

          <Table striped >
            <thead>
              <tr>
                <th>#</th>
                <th>Item</th>
                <th></th>
                <th></th>
                <th>Quantity</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {ingrows.map((val,indx)=><MobCreateModalingRow key={val} idVal={val} loadClicked={loadDrftClked} draftRows={drftContext.ingredients} deleteIngMethod={delIngRowMethod} rowUpdates={changeIngVals} rowIndx={indx} />)}
              
              
              {/* <tr>
                <th>1</th>
                <td><Form.Control type="text" placeholder="Enter Item Name" id="additmnme" /></td>
                <td></td>
                <td></td>
                <td><Form.Control type="text" placeholder="Enter Quantity" id="addqty" /></td>
                <td><a href="#"><i className="material-icons webdelingrow" id='delingrow' >delete_sweep</i></a></td>
              </tr>
              <tr>
                <th>1</th>
                <td><Form.Control type="text" placeholder="Enter Item Name" id="additmnme" /></td>
                <td></td>
                <td></td>
                <td><Form.Control type="text" placeholder="Enter Quantity" id="addqty" /></td>
                <td><a href="#"><i className="material-icons webdelingrow" id='delingrow' >delete_sweep</i></a></td>
              </tr>
              <tr>
                <th>1</th>
                <td><Form.Control type="text" placeholder="Enter Item Name" id="additmnme" /></td>
                <td></td>
                <td></td>
                <td><Form.Control type="text" placeholder="Enter Quantity" id="addqty" /></td>
                <td><a href="#"><i className="material-icons webdelingrow" id='delingrow' >delete_sweep</i></a></td>
              </tr> */}
            </tbody>
          </Table>

        </Col>        
      </Row>}
      


      {/* <!--####### row div for steps for recipe #######--> */}
      <Row className='webcreateffthrow'>
        <Col>
          {crtType==='Text' ?<h1><Badge variant="secondary" className='mobcreaterecphdr'>How To?</Badge></h1>:<h1><Badge variant="secondary" className='mobcreaterecphdr' style={{left:'37%'}}>Notes</Badge></h1>}
          <Jumbotron fluid>
            <Container>
              <Form.Control as="textarea" rows="5" id="comment"  value={crtStps} onChange={changeCrtStps} />
            </Container>
          </Jumbotron>
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer>
      {crtType!=='File/Image' && <IconButton aria-label="save" className='mobCreateDraftSave' onClick={draftSave} >
        <NoteIcon />
      </IconButton>}
      {crtType!=='File/Image' && drftContext.recipename?<IconButton aria-label="load" className='mobCreateDraftload' onClick={loadDraft} >
        <CachedIcon />
      </IconButton> : null}
      {/* <Button variant="secondary" onClick={()=>props.handleClose()}> */}
      <Button variant="secondary" onClick={runOnClose}  >
        Close
      </Button>
      {loadingIco?<Spinner animation="border" variant="primary" />:<Button variant="primary"  style={{backgroundColor: '#363062'}} onClick={saveRecipe} >
        Save Changes
      </Button>}
      {/* {validateDraft() && <Button variant="secondary" onClick={loadDraft} >
        Load Draft
      </Button>} */}
      
    </Modal.Footer>
  </Modal>
    }catch(e){
      return fallBackui
    }
    
}

export default WebCreateModal