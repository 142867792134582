import React,{useState, useEffect,useContext} from 'react'
import { Modal,Button,Container,Row,Col,Form,Badge,Table,Jumbotron,Spinner,Alert } from 'react-bootstrap';
import WebCreateModalRow from './WebCreateModalIngRow'
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import IconButton from '@material-ui/core/IconButton';
import NoteIcon from '@material-ui/icons/Note';
import CachedIcon from '@material-ui/icons/Cached';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';
import lightBlue from '@material-ui/core/colors/lightBlue';
import webdraftContext from '../../contexts/webdraftCreate'
import { set, get } from 'idb-keyval'

const accent = lightBlue['900'];

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function WebCreateModal(props){
  const classes = useStyles();

  const [savedLoading,setSaveLoading]=useState(false)
  const [loadDrftClked,setLoadDrftClck]=useState(false)
  const [validated, setValidated] = useState(false);
  const [webdrftContext, setwebdraftContxt] = useContext(webdraftContext);
  const [ingrows,ChngeIngRw]=useState([uuidv4()])

  function addNewingrows(){
    ChngeIngRw((oldval)=>[...oldval,uuidv4()])
  }
  function addNewDrftingrows(val){
    ChngeIngRw((oldval)=>[...oldval,val])
  }
  

  //-----------------read the stored draft
  useEffect(()=>{
    // console.log('this is setting the valuf of the draft value')
    get("draftRecipe").then(value =>
      
      // setDraftRecipe(((oldval)=>{return {...value}}))
      setwebdraftContxt(((oldval)=>{return {...value}}))
    ).catch((e)=>{
      // setDraftRecipe({})
      setwebdraftContxt({})
    })
  },[])
  //--------------------read the stored draft
  // ###################all form field states####################
  const [crtName,setCrtName]=useState('')
  const [crtNmeInvFlg,setInvCrtFlg]=useState(false)
  function changeCrtName(event){
    try{
      event.preventDefault()
      const val=event.target.value
      if(val.length>60){
        // alert('keep the name short and crisp!!')
        setInvCrtFlg(true)
        return
      }
      setInvCrtFlg(false)
      setCrtName(val)
      }catch(e){
        alert('An unexpected error occured. Please refresh and try again!!')
      }    
  }

  const [crtType,setCrtType]=useState('Link')
  function changeCrtType(event){
    event.preventDefault()
    const val=event.target.value
    setCrtType(val)
  }

  const [crtTUrl,setCrtUrl]=useState('')
  function changeCrtUrl(event){
    event.preventDefault()
    const val=event.target.value
    setCrtUrl(val)
  }

  const [crtPrep,setCrtPrep]=useState('')
  const [crtprpInvFlg,setInvCrtprpFlg]=useState(false)
  function changeCrtPrep(event){
    event.preventDefault()
    const val=event.target.value
    if(val.length>20){
      // alert('keep the name short and crisp!!')
      setInvCrtprpFlg(true)
      return
    }
    setInvCrtprpFlg(false)
    setCrtPrep(val)
  }

  const [crtSrvng,setCrtSrvng]=useState('')
  const [crtsrvInvFlg,setInvCrtsrvFlg]=useState(false)
  function changeCrtSrvng(event){
    event.preventDefault()
    const val=event.target.value
    if(val.length>20){
      // alert('keep the name short and crisp!!')
      setInvCrtsrvFlg(true)
      return
    }
    setInvCrtsrvFlg(false)
    setCrtSrvng(val)
  }

  const [crtStps,setCrtStps]=useState('')
  function changeCrtStps(event){
    event.preventDefault()
    const val=event.target.value    
    setCrtStps(val)
  }

  //----------------file field-------
  const [selctdFile,changeSelFile]=useState(undefined)
  function handleFileLoad(event){
    const fleCntnt=event.target.files[0];
    changeSelFile(fleCntnt)
    // const fleData=new FormData()
    // console.log(fleCntnt)
  }
  //---------------file field----------



  // ###################end all form field states###################

  //#########################read ing row changes#########################
  const [ingRowVals,setingRowVals]=useState([])
  // id,itmname,itmqty
  function changeIngVals(valObj){
    if(ingRowVals.length===0){
      return setingRowVals([...ingRowVals,valObj])
    }

    let updFlg=false
    let updIngs=ingRowVals.map((v)=>{
      if (v.id===valObj.id){
        v.itemname=valObj.itemname
        v.quantity=valObj.quantity
        updFlg=true
        
      }
      return v
    })
    if(!updFlg){
      updIngs=[...updIngs,valObj]
    }
    setingRowVals(updIngs)
    console.log('this is running####################################################')
    console.log(ingRowVals)

  }

  const [ingRowCopy,setingRowCopy]=useState(ingRowVals)
  useEffect(()=>{
    let opRows=[]
    ingRowVals.forEach((val)=>{
      let tmparray={}
      Object.keys(val).forEach((ObjRow)=>{
        if(ObjRow==='id'){

        }else{
          tmparray[ObjRow]=val[ObjRow]
        }
      })
      opRows.push(tmparray)
    })
    setingRowCopy(opRows)
  },[ingRowVals])

  function removeIdvals(inptId){
    console.log(inptId)
    let op=[...inptId]
    op=op.map((v)=>{
      delete v['id']
      return v
    })
    console.log('##########################################in remove')
    console.log(op)
    return op
  }

  //#########################end read ing row changes#########################

  //#######################################load the draft data####################
  const [draftIngrRows,setDrftIngrrows]=useState([])
  async function loadDraft(){
    let loadedDraft=await get("draftRecipe")
    setwebdraftContxt(((oldval)=>{return {...loadedDraft}}))
    setCrtName(webdrftContext.recipename)
    setCrtType(webdrftContext.recipetype)
    setCrtUrl(webdrftContext.recipeurl)
    setCrtPrep(webdrftContext.preptime)
    setCrtSrvng(webdrftContext.servings)
    setCrtStps(webdrftContext.steps)
    setDrftIngrrows((oldval)=>[...oldval,...draftIngrRows])
    setLoadDrftClck(true)
    let tmpDraftRows=[...webdrftContext.ingredients]
    webdrftContext.ingredients.forEach((v)=>{
      addNewDrftingrows(v.id)
    })

    setingRowVals(tmpDraftRows)

  }

  //###########################save the draft data##############
  async function draftSave(){
    let draftdta={
      recipename:crtName,
      recipetype:crtType,
      preptime:crtPrep,
      servings:crtSrvng,
      steps:crtStps,
      mainimage:"mainimg.png",
      recipeurl:crtTUrl,
      recipefile:"na",
      recipefileid:"na",
      // ingredients:ingRowVals.length===0?[]:ingRowCopy
      ingredients:ingRowVals.length===0?[]:ingRowVals
    }
    setSaveLoading(true)
    await set("draftRecipe", {})
    await set("draftRecipe", draftdta)
    setSaveLoading(false)
  }

  //#########################save the draft data#######################


//###############################save action##############################

//validate fields
const [invalidFlgs,setinValidFlg]=useState([false,false,false,false])
function validateFields(inpt){
  console.log(inpt)
  let errflg=true
  let tmparry=[false,false,false,false]
  if(inpt.recipename===''){
    tmparry[0]=true
    errflg=false
  }
  if(inpt.recipetype==='text'){
    if(inpt.preptime===''){
      tmparry[2]=true
      errflg=false
    }
    if(inpt.servings===''){
      tmparry[3]=true
      errflg=false
    }

    }

  if(inpt.recipetype==='link'){
    if(inpt.recipeurl===''){
      tmparry[1]=true
      errflg=false
      }    
    }
    if(!errflg){
      setinValidFlg((oldval)=>[...tmparry])
    }
  return errflg
}

// let ingrowCpy=removeIdvals([...ingRowCopy])
console.log('##################this is copy')
console.log(ingRowCopy)
console.log(ingRowVals)
// console.log(ingrowCpy)
const saveApi=`${process.env.REACT_APP_API_HOST}/addrecipe`

let inptdta={
  recipename:crtName,
	recipetype:crtType.toLowerCase(),
	preptime:crtPrep,
	servings:crtSrvng,
	steps:crtStps,
	mainimage:"mainimg.png",
	recipeurl:crtTUrl,
  recipefile:"na",
  recipefileid:"na",
  ingredients:ingRowVals.length===0?[]:ingRowCopy
}
console.log('##############################################this is inpt form')
console.log(process.env.REACT_APP_AUTH_TKN)
function saveRecipe(){
  setinValidFlg([false,false,false,false])
  if(!validateFields(inptdta)){
    return
  }
  console.log(invalidFlgs)
  setSaveLoading(true)
  console.log(process.env.REACT_APP_AUTH_TKN)
  // *********************get file url****************
  if(inptdta.recipetype==='file/image'){
    if(!selctdFile){
      alert('select a file')
      setSaveLoading(false)
      return
    }
    const fleSvcUrl=`${process.env.REACT_APP_API_HOST}/postfile`
    const filedata = new FormData()
    filedata.append('image',selctdFile)
    fetch(fleSvcUrl,{
      credentials: 'include',
      method:'POST',
      headers:{
        'Authorization':`Bearer ${props.passAuthTkn}`,
        // 'Content-Type':'application/json'
      },
      body: filedata
    }).then((fleOut)=>{
      if(fleOut.status===500){
        throw Error('error in loading file')
      }
      return fleOut.json()
    }).then((flejson)=>{
      console.log(flejson)
      //save the file type recipe
      inptdta.recipefile=flejson.imagefile
      inptdta.recipefileid=flejson.imageid
      fetch(saveApi,{
        credentials: 'include',
        method:'POST',
        headers:{
              'Authorization':`Bearer ${props.passAuthTkn}`,
              'Content-Type':'application/json'
            },
        body:JSON.stringify(inptdta)
      }).then((saveDta)=>{
        return saveDta.json()
        
      }).then((jsonData)=>{
        if(jsonData.error){
          throw Error(jsonData.error)
        }
        setSaveLoading(false)
        //clear local draft
        set("draftRecipe", {})
        props.getCreatedRec(jsonData)
        //add to elastic search
        const esurl=`${process.env.REACT_APP_API_HOST}/adddocument`
        const esdata={
          indexname:"recipes",
          doc_id:jsonData.id,
          data:{
              recipename:jsonData.recipename
          }
        }
        fetch(esurl,{
          credentials: 'include',
          method:'POST',
          headers:{
              'Authorization':`Bearer ${props.passAuthTkn}`,
              'Content-Type':'application/json'
            },
          body:JSON.stringify(esdata)
        })
        //end add to elastic search
        runOnClose()
      }).catch((e)=>{
        console.log('in err form')
        setSaveLoading(false)
        console.log(e)
        alert(`Error in adding recipe. Try again`)
      })
      // return
    }).catch((e)=>{
      setSaveLoading(false)
      alert('Error in adding recipe because file was not loaded. Please try again')
    })
    return
  }

  //*********************end get file url**************** */
  fetch(saveApi,{
    credentials: 'include',
    method:'POST',
    headers:{
          'Authorization':`Bearer ${props.passAuthTkn}`,
          'Content-Type':'application/json'
        },
    body:JSON.stringify(inptdta)
  }).then((saveDta)=>{
    return saveDta.json()
    
  }).then((jsonData)=>{
    if(jsonData.error){
      throw Error(jsonData.error)
    }
    //clear local draft
    set("draftRecipe", {})
    //add to elastic search
    const esurl=`${process.env.REACT_APP_API_HOST}/adddocument`
    const esdata={
      indexname:"recipes",
      doc_id:jsonData.id,
      data:{
          recipename:jsonData.recipename
      }
    }
    fetch(esurl,{
      credentials: 'include',
      method:'POST',
      headers:{
          'Authorization':`Bearer ${props.passAuthTkn}`,
          'Content-Type':'application/json'
        },
      body:JSON.stringify(esdata)
    })
    //end add to elastic search
    setSaveLoading(false)
    props.getCreatedRec(jsonData)
    runOnClose()
  }).catch((e)=>{
    console.log('in err form')
    setSaveLoading(false)
    console.log(e)
    alert(`Error in adding recipe. Try again`)
  })
}
// let inptdta={
//   recipename:"New one",
// 	recipetype:"link",
// 	preptime:"10 hours",
// 	servings:"51 persons",
// 	steps:"1. asdasdasd 2. 44csdcs scsdc s 3. ds vsv sv sd vsd vsdsds  acas  ",
// 	mainimage:"testing",
// 	recipeurl:"http://test.com",
// 	recipefile:"na"
// }

//###############################end save action##############################

  

  function runOnClose(){

    const tmpflgs=[false,false,false,false]
    setinValidFlg((oldval)=>[...tmpflgs])
    
    setInvCrtFlg(false)
    setInvCrtprpFlg(false)
    setInvCrtsrvFlg(false)
    //reset all fields
    setCrtName('')
    setCrtType('Link')
    setCrtUrl('')
    setCrtPrep('')
    setCrtSrvng('')
    setCrtStps('')
    setingRowVals([])

    ChngeIngRw([1])
    props.handleClose()
  }

  function delIngRowMethod(val){
    try{
        console.log(ingRowVals)
        ChngeIngRw((oldVal)=>ingrows.filter((rw)=>rw!==val))
        setingRowVals((oldVal)=>oldVal.filter((rw)=>rw.id!==val))
        }catch(e){
          alert(e)
        }
    //ingRowVals,setingRowVals
    
    }

  const fallBackui=<div>error</div>
  try{
    return <Modal show={props.setdisplay} onHide={runOnClose}  >
    <Modal.Header className='webcreatemodalhdr' closeButton>
      <Modal.Title>Create New</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {/* <Container> */}

      {/* <!--####### row div for name #######--> */}
      <Row className='webcreatefirstrow'>
        <Col>
        <Form className='webcreatefirstform'>
          {/* <Form.Group controlId="webcreatefirstform.ControlInput1"> */}
          <Form.Group>
            {/* {invalidFlgs[0] && <Alert variant='danger' style={{height:'2px'}}>Please enter something!!</Alert>} */}
            {crtNmeInvFlg && <Badge variant="dark">Keep name short and crisp!!</Badge>}
            <Form.Control style={invalidFlgs[0]?{borderColor:'red'}:{}} type="text" placeholder="Enter Name" id="recipename" value={crtName} onChange={changeCrtName} />
          </Form.Group>

          {/* <Form.Group controlId="webcreatefirstform.ControlInput2"> */}
          <Form.Group>
            <Form.Label>Select Type:</Form.Label>
            <Form.Control as="select" id="seltype" value={crtType} onChange={changeCrtType} >
              <option>Link</option>
              <option>File/Image</option>
              <option>Text</option>
            </Form.Control>
          </Form.Group>
        </Form>
        </Col>
      </Row>

      {/* <!--####### row div for file #######--> */}
      {crtType==='File/Image' && <Row className='webcreatesecndtrow'>
        <Col>
        <Form className='webcreatesecndform'>
          {/* <Form.Group controlId="webcreatefirstform.ControlInput3"> */}
          <Form.Group>
            <Form.Label>Select File:</Form.Label>
            <Form.Control type="file" id="recipefile" onChange={handleFileLoad} />
          </Form.Group>
        </Form>
        </Col>
      </Row>}
      

      {/* <!--####### row div for url/link #######--> */}
      {crtType==='Link' && <Row className='webcreatesecndtrow'>
        <Col>
          <Form className='webcreatesecndform'>
            {/* <Form.Group controlId="webcreatefirstform.ControlInput2"> */}
            <Form.Group>
              <Form.Control style={invalidFlgs[1]?{borderColor:'red'}:{}} type="text" placeholder="Enter link" id="recipeurl" value={crtTUrl} onChange={changeCrtUrl} />
            </Form.Group>
          </Form>
        </Col>
      </Row> }
      

      {/* <!--####### row div for prep time #######--> */}
      {crtType==='Text' && <Row className='webcreatethrdtrow'>
        <Col>
          <Form style={{paddingBottom:10}}>
            <Form.Row>
              <Col>
                {crtprpInvFlg && <Badge variant="dark">Keep this short and crisp!!</Badge>}
                <Form.Control style={invalidFlgs[2]?{borderColor:'red'}:{}} type="text" placeholder="Enter Prep Time" id="preptime" value={crtPrep} onChange={changeCrtPrep}  />
              </Col>
              <Col>
                {crtsrvInvFlg && <Badge variant="dark">Keep this short and crisp!!</Badge>}
                <Form.Control style={invalidFlgs[3]?{borderColor:'red'}:{}} type="text" placeholder="Enter Servings" id="srvngs" value={crtSrvng} onChange={changeCrtSrvng}   />
              </Col>
          </Form.Row>
          </Form>
        </Col>
      </Row>}
      


      {/* <!--####### row div for ingredients table #######--> */}
      {true && <Row className='webcreatefrthrow'>
        <Col>
          <Row>
            <Col xs={10}>
              <h1><Badge variant="secondary" className='webcreateinghdr'>Ingredients</Badge></h1>
            </Col>
            <Col>
            <Fab color='primary' size="small" aria-label="add" style={{top: '8px'}} >
              <AddTwoToneIcon fontSize='large'  onClick={addNewingrows} />
              {/* <a  href="#"><i className="material-icons webcreateaddingbtn" id='addalling' onClick={addNewingrows} >add_circle_outline</i></a> */}
              </Fab>
            </Col>
          </Row>

          <Table striped >
            <thead>
              <tr>
                <th>#</th>
                <th>Item</th>
                <th></th>
                <th></th>
                <th>Quantity</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {ingrows.map((val,indx)=><WebCreateModalRow key={val} idVal={val} loadClicked={loadDrftClked} draftRows={webdrftContext.ingredients} deleteIngMethod={delIngRowMethod} rowUpdates={changeIngVals} rowIndx={indx} />)}
              {/* <tr>
                <th>1</th>
                <td><Form.Control type="text" placeholder="Enter Item Name" id="additmnme" /></td>
                <td></td>
                <td></td>
                <td><Form.Control type="text" placeholder="Enter Quantity" id="addqty" /></td>
                <td><a href="#"><i className="material-icons webdelingrow" id='delingrow' >delete_sweep</i></a></td>
              </tr>
              <tr>
                <th>1</th>
                <td><Form.Control type="text" placeholder="Enter Item Name" id="additmnme" /></td>
                <td></td>
                <td></td>
                <td><Form.Control type="text" placeholder="Enter Quantity" id="addqty" /></td>
                <td><a href="#"><i className="material-icons webdelingrow" id='delingrow' >delete_sweep</i></a></td>
              </tr>
              <tr>
                <th>1</th>
                <td><Form.Control type="text" placeholder="Enter Item Name" id="additmnme" /></td>
                <td></td>
                <td></td>
                <td><Form.Control type="text" placeholder="Enter Quantity" id="addqty" /></td>
                <td><a href="#"><i className="material-icons webdelingrow" id='delingrow' >delete_sweep</i></a></td>
              </tr> */}
            </tbody>
          </Table>

        </Col>        
      </Row>}
      


      {/* <!--####### row div for steps for recipe #######--> */}
      
      <Row className='webcreateffthrow'>
        <Col>
          {crtType==='Text' ?<h1><Badge variant="secondary" className='webcreaterecphdr'>How To?</Badge></h1>:<h1><Badge variant="secondary" className='webcreaterecphdr' style={{left:'37%'}}>Notes</Badge></h1>}
          <Jumbotron fluid>
            <Container>
              <Form.Control as="textarea" rows="5" id="comment" value={crtStps} onChange={changeCrtStps} />
            </Container>
          </Jumbotron>
        </Col>
      </Row>



    {/* </Container>     */}
    </Modal.Body>
    <Modal.Footer>
    {crtType!=='File/Image' && <IconButton aria-label="save" className='webCreateDraftSave' onClick={draftSave} >
        <NoteIcon />
      </IconButton>}
      {crtType!=='File/Image' && webdrftContext.recipename?<IconButton aria-label="load" className='webCreateDraftload' onClick={loadDraft} >
        <CachedIcon />
      </IconButton> : null}
      <Button variant="secondary" onClick={runOnClose}>
        Close
      </Button>
        {savedLoading?<Spinner animation="border" variant="primary" />:<Button variant="primary" style={{backgroundColor: '#363062'}} onClick={saveRecipe}>
        Save Changes
      </Button>}
    </Modal.Footer>
  </Modal>
    }catch(e){
      return fallBackui
    }
}

export default WebCreateModal