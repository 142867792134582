import React,{useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css';
// import logo from './images/search-field.png';
import mainlogo from '../../images/webmainheader.svg'

import { Button,Form,Row,Col,Navbar,Nav } from 'react-bootstrap';
import LibraryAddTwoToneIcon from '@material-ui/icons/LibraryAddTwoTone';

function NavbarHdrLanding(props){

  const [createStyle,setCreateStyle]=useState({color:'rgba(255,255,255,.75)'})
  const changeCreateStyle=()=>{
    // alert('akjhdjh')
    setCreateStyle({})
    setCreateStyle({color:'rgba(255,255,255,.75)'})
  }
  const changeFocusStyle=()=>{
    // alert('Focus')\
    setCreateStyle({})
    setCreateStyle({color:'#FFF'})
  }

  function logOutMethod(){
    props.setlogoutFlag(false)
    props.logoutRemoveTkn('unset')
    localStorage.removeItem('appky')
    const apiurl=`${process.env.REACT_APP_API_HOST}/logout`
    fetch(apiurl,{
      credentials: 'include',
      headers:{
        'Authorization':`Bearer ${props.tokenValue}`
      }
    }).then((out)=>{
      if(out.status!==200){
        throw Error('Error in Logging out!!')
      }
      return out.json()
    }).then((outjson)=>{
      console.log(outjson)
      // props.setlogoutFlag(false)
      // props.logoutRemoveTkn('unset')
      // localStorage.removeItem('appky')
      // console.log(outjson)
    }).catch((e)=>{
      alert('Error in logging Out!!')
    })
  }


  return <div>
  <Navbar bg="dark" variant="dark" expand="sm" className="webnavbar">
    {/* <a className="navbar-brand" href="#">Logo</a> */}
    <Navbar.Brand className='webnavbarhdrbrand' ><img
        src={mainlogo}
        width="80"
        height="40"
        className="d-inline-block align-top"
        alt=""
        style={{margin:'0'}}
      />
      Specipe</Navbar.Brand>

    {/* <Form className='navbarsearchform'>
        <Row>
            <Col>
                <Form.Control className='searchfield' placeholder="Search for a Recipe" aria-label="Search for a Recipe" aria-describedby="basic-addon2" />
            </Col>
        </Row>
    </Form> */}
    <Nav className="ml-auto">
      <Nav.Link className='nav-item' href="#home">
        <LibraryAddTwoToneIcon style={createStyle}  onMouseLeave={changeCreateStyle} onMouseEnter={changeFocusStyle} onClick={()=>props.handleShow()} />
      {/* <i style={createStyle} onMouseLeave={changeCreateStyle} onMouseEnter={changeFocusStyle} className="material-icons" id='additem' onClick={()=>props.handleShow()}>
                library_add
                    </i> */}
                    </Nav.Link>
      <Nav.Link  className='moblogoutlink' onClick={logOutMethod} ><strong>Logout</strong></Nav.Link>      
    </Nav>
    </Navbar>

    </div>

}

export default NavbarHdrLanding