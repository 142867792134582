import React,{useState} from 'react';
import './App.css';
import NavbarHdr from './components/common/NavbarHdr'
import WebCreateModal from './components/web/WebCreateModal'
import MobCreateModal from './components/mobile/MobCreateModal'
import MainBody from './components/common/MainBody'
import WebEditModal from './components/web/WebEditModal'
import LoginPage from './components/mobile/LoginPage'
import MobHdrLanding from './components/mobile/MobHdrLanding'
import MobHdrDetail from './components/mobile/MobHdrDetail'
import ShopListModal from './components/mobile/ShopListModal'
import MobHdrEdit from './components/mobile/MobHdrEditPage'
import NavBarhdrLanding from './components/web/NavBarHdrLanding'
import draftContext from './contexts/draftCreate'
import webDraftContext from './contexts/webdraftCreate'
import webDraftEdtCntxt from './contexts/webdraftEdit'

function App(){

    // state changes to show or unshow the create modal on web ui
    const [showCreate,setShowCreate]=useState(false)
    const setShow=()=>{
        setShowCreate(true)
    }
    const unsetShow=()=>{
        setShowCreate(false)
        // setCreateClass('aa')        
    }

    // state changes to show or unshow the edit modal on web ui
    const [showEdtpop,setShowedtPop]=useState(false)
    const [popData,setPopDta]=useState({})
    const setEdtPop=(popdata)=>{
        setPopDta(popdata)
        setShowedtPop(true)
    }
    const unsetEdtPop=()=>{
        setShowedtPop(false)
        // setCreateClass('aa')        
    }
    
    // state changes to show or unshow the create modal on mobile ui
    const [mobShowCreate,setmobShowCreate]=useState(false)
    const setmobShow=()=>{
        setmobShowCreate(true)
    }
    const unsetmobShow=()=>{
        setmobShowCreate(false)
        // setCreateClass('aa')        
    }
    
    //set the auth token header on login
    const [authTokenVal,setAuthTokenVal]=useState(localStorage.getItem('appky')?localStorage.getItem('appky'):'unset')
    //set login from login page
    // const [loggedin,SetLoggedin]=useState(false)
    const [loggedin,SetLoggedin]=useState(authTokenVal==='unset'?false:true)
    function loginMethod(){

        SetLoggedin(true)
    }

    //change header navbars
    // const [pageHdr,setPageType]=useState('default')

    const [pageHdr,setPageType]=useState(localStorage.getItem('apptype')?'landing':'weblanding')
    function setPageName(pageName){

        setPageType(pageName)
    }

    //set state to open edit page from detail mobile page
    const [openEditPge,setOpenEdit]=useState(false)
    function controlOpenEdit(event){
        setOpenEdit(!openEditPge)
    }


    //set state to display or not display shoppping list pop up on mobile ui
    const [showShoLst,setLstShow]=useState(false)
    function togglShopLstdisp(){
        setLstShow(!showShoLst)
    }

    //get the created recipe details
    const [createdRecp,setCreatedRecp]=useState({})

    //signal if item is updated or not
    const [recUpdFlg,setRecUpd]=useState(true)


    //get mobile detail page data for the header buttons
    const [mobDetData,setMobDetData]=useState({})


    //control data flow when save is clicked on edit page
    const [editClicked,setEdtClck]=useState(false)
    const [saveDrfteditClicked,setsaveDrftEdtClck]=useState(false)
    const [loadDrfteditClicked,setloadDrftEdtClck]=useState(false)

    //control the shopping list data changes from mobile detail page
    const [shopLstData,addToShopLst]=useState({})
    function modiFyShoplst(inpt){
        // if(inpt==='reset'){
        //     addToShopLst({})
        // }else{
            // addToShopLst((oldval)=>[...oldval,inpt])
            addToShopLst(inpt)
        // }
        
    }

    //trigger re query on mobile create
    const [mobRqyFlg,setMobileQry]=useState(false)

    ////read search term from web header search bar
    const [hdrQryTrm,setHdrQrytrm]=useState('unset')

    //read search term mobile header search bar
    const [mobhdrQryTrm,setmobHdrQrytrm]=useState('unset')

    
    

    const [drftCntxt, setdrftCntxt] = useState({});
    const [webdrftCntxt, setwebdrftCntxt] = useState({});
    const [webdrftEdtCntxt, setwebdrftEdtCntxt] = useState([]);
    if(!loggedin){
        return <LoginPage logintoggle={loginMethod} setPageTypeMethd={setPageName} getAuthTkn={setAuthTokenVal} tokenValue={authTokenVal} setSearchFlag={setRecUpd} />
    }else{
        return <webDraftEdtCntxt.Provider value={[webdrftEdtCntxt, setwebdrftEdtCntxt]} ><webDraftContext.Provider value={[webdrftCntxt, setwebdrftCntxt]} ><draftContext.Provider value={[drftCntxt, setdrftCntxt]}><div className='mainContainer'>
        {pageHdr==='weblanding' && <NavBarhdrLanding handleShow={setShow} tokenValue={authTokenVal} setlogoutFlag={SetLoggedin} logoutRemoveTkn={setAuthTokenVal} />}
        {pageHdr==='default' && <NavbarHdr handleShow={setShow} activeState={showCreate} handleMobShow={setmobShow} handleEdtpop={setEdtPop} setQryFrmHdr={setRecUpd} setSrchtrm={setHdrQrytrm} mobileSrchHdrQry={setMobileQry} tokenValue={authTokenVal} setlogoutFlag={SetLoggedin} logoutRemoveTkn={setAuthTokenVal} />}
        {pageHdr==='landing' && <MobHdrLanding handleShow={setShow} handleMobShow={setmobShow} tokenValue={authTokenVal} setlogoutFlag={SetLoggedin} logoutRemoveTkn={setAuthTokenVal} />}
        {pageHdr==='homepage' && <NavbarHdr handleShow={setShow} activeState={showCreate} handleMobShow={setmobShow} mobileSrchHdrQry={setMobileQry}  mobHdrSearchSet={setmobHdrQrytrm} tokenValue={authTokenVal} setlogoutFlag={SetLoggedin} logoutRemoveTkn={setAuthTokenVal} />}
        {pageHdr==='detailpage' && <MobHdrDetail handleShow={setShow} activeState={showCreate} handleMobShow={setmobShow} setEditPageNav={controlOpenEdit} handleShopshw={togglShopLstdisp} setPageTypeMethd={setPageName} detlPageDta={mobDetData} tokenValue={authTokenVal} setlogoutFlag={SetLoggedin} logoutRemoveTkn={setAuthTokenVal} />}
        {pageHdr==='editpage' && <MobHdrEdit activeState={showCreate} editData={mobDetData} editClkFlg={setEdtClck} saveeditDraftClkFlg={setsaveDrftEdtClck} loadeditDraftClkFlg={setloadDrftEdtClck} tokenValue={authTokenVal} setlogoutFlag={SetLoggedin} logoutRemoveTkn={setAuthTokenVal} />}
        <WebCreateModal setdisplay={showCreate} handleClose={unsetShow} getCreatedRec={setCreatedRecp} passAuthTkn={authTokenVal} />
        <WebEditModal setdisplay={showEdtpop} handleClose={unsetEdtPop} editData={popData} chngeUpdted={setRecUpd} passAuthTkn={authTokenVal} />
        <MobCreateModal setdisplay={mobShowCreate} handleClose={unsetmobShow} setCreateQry={setMobileQry} passAuthTkn={authTokenVal} />
        <ShopListModal handleDisp={showShoLst} handleHide={togglShopLstdisp} inputShpData={shopLstData} recpData={mobDetData} passAuthTkn={authTokenVal} />
        <MainBody showEdit={setEdtPop} saveEditDta={editClicked} saveDrftEditDta={saveDrfteditClicked} saveeditDraftClkFlg={setsaveDrftEdtClck} loadDrftEditDta={loadDrfteditClicked} loadeditDraftClkFlg={setloadDrftEdtClck} resetEditFlg={setEdtClck} setPageTypeMethd={setPageName} editPageState={openEditPge} ctrlEditForFooter={controlOpenEdit} newRecpDets={createdRecp} rowUpdatedFlg={recUpdFlg} resetUpdFlg={setRecUpd} getMobDetailDta={setMobDetData} changeShopData={modiFyShoplst} reQueryOnMobCreate={mobRqyFlg} qryMobMethod={setMobileQry} hdrSearchTerm={hdrQryTrm} resetHdrSrchtrm={setHdrQrytrm} mobhdrSearchTrm={mobhdrQryTrm} mobHdrSearchSet={setmobHdrQrytrm} passAuthTkn={authTokenVal} />
    </div></draftContext.Provider></webDraftContext.Provider></webDraftEdtCntxt.Provider>
    }


    // webDraftEdtCntxt

    // const appOut={loggedin ? null:null}
    // const AppPage=<div className='mainContainer'>
    // <NavbarHdr handleShow={setShow} activeState={showCreate} handleMobShow={setmobShow} />
    // <WebCreateModal setdisplay={showCreate} handleClose={unsetShow} />
    // <WebEditModal setdisplay={showCreate} handleClose={unsetShow} />
    // <MobCreateModal setdisplay={mobShowCreate} handleClose={unsetmobShow} />
    // <WebMainBody showEdit={setShow} />
    // </div>

    // return <div className='mainContainer'>
    //     {loggedin?<LoginPage />:}
    // </div>

   

    // return {loggedin?<LoginPage />:AppPage}
    // return <LoginPage />
    // return ({loggedin ? (<LoginPage />):(<div className='mainContainer'>
    //     <NavbarHdr handleShow={setShow} activeState={showCreate} handleMobShow={setmobShow} />
    //     <WebCreateModal setdisplay={showCreate} handleClose={unsetShow} />
    //     <WebEditModal setdisplay={showCreate} handleClose={unsetShow} />
    //     <MobCreateModal setdisplay={mobShowCreate} handleClose={unsetmobShow} />
    //     <WebMainBody showEdit={setShow} />
    // </div>)})
    // function showCreateModal(){
    //     setShowCreate(true)
    // }


    // const [createItemVal,setCreateClass]=useState('nav-item')
    
        // setCreateClass('createnewitem')
        
        
        // console.log(event.target)
        // event.target.parentElement.classList.remove('active')
        // console.log(this.clicknewitem)
    //   }

}

export default App