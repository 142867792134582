import React,{useState} from 'react'

import { Container,Row,Col,Card,Navbar,Modal,Badge,Image } from 'react-bootstrap';
import backbutton from '../../images/backbutton.svg'

function MobFooter(props){

    function navtoPage(event){
        
        props.setHdrToggl()
        props.setNavLink({})
        if(props.setQryFlag){
            props.setQryFlag(true)
        }
        if(props.setQry){
            props.resetMobSrch('unset')
            props.resetHdrSrch('unset')
            props.setQry(true)
        }
        // if(props.resetShopLst){
        //     props.resetShopLst('reset')
        // }
    }

    const [showCrdts,setShwCrdts]=useState(false)

    return <Navbar fixed="bottom" variant="dark" bg="dark" className='mobfooter'>
    <Row>
        <Col>
        { props.ctrlNavDisply ? <Image src={backbutton} fluid onClick={navtoPage} /> : null}
        {/* {props.ctrlNavDisply ? <a  href="#" className='webfrstcolnext'><i onClick={navtoPage} className="material-icons " style={{fontSize:35}} id='mobfootprev' >
        skip_previous
                      </i></a>:<a><i className="material-icons " style={{fontSize:35}} id='mobfootprev' >
        
                      </i></a>} */}
                      
        </Col>
        <Col>
        {props.showCrdts && <div className='mobcredits'>
                            <a href='#'  style={{color:'white'}} onClick={()=>setShwCrdts(true)} >Credits</a>
                        </div>}
        
        </Col>
    </Row>

    <Modal show={showCrdts} onHide={()=>setShwCrdts(false)} >
            {/* <Modal.Header className='webcreatemodalhdr' closeButton>
                <Modal.Title>Edit Header Image</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
            <Badge pill variant="secondary" style={{color:'black'}}>
                Icons made by <a href="https://www.flaticon.com/authors/freepik" target="_blank" title="Freepik" style={{color:'blue'}}>Freepik</a> from <a href="https://www.flaticon.com/" target="_blank" title="Flaticon" style={{color:'blue'}}>www.flaticon.com</a>
                
                </Badge>{' '}
                <Badge pill variant="secondary">
                    <a target="_blank" href="https://icons8.com/icons/set/cooking-book" style={{color:'blue'}}>Cooking Book icon</a> icon by <a target="_blank" href="https://icons8.com" style={{color:'blue'}}>Icons8</a>
                </Badge>
            </Modal.Body>            
        </Modal>
    
    </Navbar>
}

export default MobFooter