import React,{useState} from 'react'
import { Button,Form,Row,Col,Navbar,Nav,Alert } from 'react-bootstrap';
import mainlogo from '../../images/webmainheader.svg'
import CreateTwoToneIcon from '@material-ui/icons/CreateTwoTone';
import LocalMallTwoToneIcon from '@material-ui/icons/LocalMallTwoTone';

function MobHdrDetails(props){
    console.log(props.detlPageDta)

    const [createStyle,setCreateStyle]=useState({color:'rgba(255,255,255,.75)'})
    const changeCreateStyle=()=>{
        // alert('akjhdjh')
        setCreateStyle({})
        setCreateStyle({color:'rgba(255,255,255,.75)'})
    }
    const changeFocusStyle=()=>{
        // alert('Focus')\
        setCreateStyle({})
        setCreateStyle({color:'#FFF'})
    }


    function navEditPage(event){

        props.setPageTypeMethd('editpage')
        props.setEditPageNav()
    }

    function logOutMethod(){
        props.setlogoutFlag(false)
        props.logoutRemoveTkn('unset')
        localStorage.removeItem('appky')
        const apiurl=`${process.env.REACT_APP_API_HOST}/logout`
        fetch(apiurl,{
          credentials: 'include',
          headers:{
            'Authorization':`Bearer ${props.tokenValue}`
          }
        }).then((out)=>{
          if(out.status!==200){
            throw Error('Error in Logging out!!')
          }
          return out.json()
        }).then((outjson)=>{
          console.log(outjson)
          // props.setlogoutFlag(false)
          // props.logoutRemoveTkn('unset')
          // localStorage.removeItem('appky')
          // console.log(outjson)
        }).catch((e)=>{
          alert('Error in logging Out!!')
        })
      }

    const fallBackui=<Navbar bg="dark" variant="dark" expand="sm" className="mobilenavbar"><Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert></Navbar>

    try{
        return <div>
        <Navbar bg="dark" variant="dark" expand="sm" className="mobilenavbar">
            <Navbar.Brand onClick={()=>window.location.reload(false)} className='mobilenavbarhdrbrand' ><img
        src={mainlogo}
        width="50"
        height="40"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
        style={{margin:'0'}}></img></Navbar.Brand>
            <Form className='mobdetlhdrfrm'>
                <Row>
                    {/* <Col>
                        <a  href="#"><i className="material-icons mobdetlhdrico1" id='addalitms'>playlist_addr</i></a>
                    </Col> */}
                    <Col>
                      <CreateTwoToneIcon color='primary' fontSize='large' className='mobdetlhdrico1' onClick={navEditPage} />
                        {/* <a  href="#"><i className="material-icons mobdetlhdrico1" id='mobopnedit' onClick={navEditPage} >edit</i></a> */}
                    </Col>
                </Row>
            </Form>
        <Navbar.Toggle className='ml-auto' aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">

        {/* future feature placeholder */}
        {/* <Nav.Link className='nav-item' href="#home"><i style={createStyle} className="material-icons" id='hdrprint' onClick={()=>props.handleMobShow()}>
        local_printshop
                    </i></Nav.Link>    */}
        {/* end future feature placeholder */}  

        {/* was active */}
        {/* {true && <Nav.Link className='nav-item' href="#home"><i style={createStyle} className="material-icons" id='hdropenshopng' onClick={()=>props.handleShopshw()}>
        shopping_basket
                    </i></Nav.Link>} */}
        {true && <Nav.Link className='nav-item' href="#home">
          <LocalMallTwoToneIcon fontSize='medium' style={createStyle} onClick={()=>props.handleShopshw()} />
        </Nav.Link>}
               
        
        <Nav.Link className='ml-auto nav-item moblogoutlink' onClick={logOutMethod} style={createStyle}><strong>Logout</strong></Nav.Link>
        </Navbar.Collapse>    
    </Navbar>
    </div>
    }catch(e){
        return fallBackui
    }
    
}

export default MobHdrDetails