import React,{useState} from 'react'
import { Container, Col,Form,Table,Row,Badge,Jumbotron,Card,InputGroup,Alert } from 'react-bootstrap';
import srchbck from '../../images/search-field.png'
import backimg from '../../images/recipe_book_1.svg'

function MobLandingPage(props){

    function navSrchReslts(event){

        props.setHdrToggl('homepage')
        props.homeNavPage()
    }

    const [srchValue,setSrchval]=useState('')
    function changeSrchWord(event){
        const val=event.target.value
        setSrchval(val)
        props.setSearchVals(val)
    }
    //onChange={navSrchReslts}

    const fallbackui=<Col className='moblndngmaincol'><Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert></Col>
    
    try{
        return <Col  className='moblndngmaincol'>
        <div className='moblndnsearchbar'>
            <InputGroup className="mb-3">
                {/* <Form.Control type="text"  style={{backgroundImage:`url(${srchbck})`,backgroundPosition:'right',backgroundRepeat:'no-repeat'}} className='mobmainsrchfrm' placeholder="Search for a Recipe" id="mobmainsrchfld"  aria-label="Search for a Recipe" aria-describedby="basic-addon2" /> */}
                <Form.Control type="text"  className='mobmainsrchfrm' placeholder="Search for a Recipe" id="mobmainsrchfld"  aria-label="Search for a Recipe" aria-describedby="basic-addon2" value={srchValue} onChange={changeSrchWord} />
                <div className='mobhomesrchbtn' onClick={navSrchReslts}  ></div> 
            </InputGroup>
        </div>        
    </Col>
    }catch(e){
        return fallbackui
    }
    
}

export default MobLandingPage