import React,{useState,useEffect,useContext} from 'react'
import { Button,Form,Row,Col,Navbar,Nav,Spinner,Alert } from 'react-bootstrap';
import mainlogo from '../../images/webmainheader.svg'
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import SplitButton from './mobHdrEditBtn';
import webdraftEditContext from '../../contexts/webdraftEdit'
import { set, get } from 'idb-keyval'

const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge']


function MobHdrEdit(props){
    console.log(props.editData)
    const [showLoading,setLoading]=useState(false)
    const [createStyle,setCreateStyle]=useState({color:'rgba(255,255,255,.75)'})
    const [webdrftEdtContext, setwebdraftEditContext] = useContext(webdraftEditContext?webdraftEditContext:[])
    const changeCreateStyle=()=>{
        // alert('akjhdjh')
        setCreateStyle({})
        setCreateStyle({color:'rgba(255,255,255,.75)'})
    }
    const changeFocusStyle=()=>{
        // alert('Focus')\
        setCreateStyle({})
        setCreateStyle({color:'#FFF'})
    }    
    
    function saveEditFlg(){
        setLoading(true)
        props.editClkFlg(true)
    }

    function logOutMethod(){
        props.setlogoutFlag(false)
        props.logoutRemoveTkn('unset')
        localStorage.removeItem('appky')
        const apiurl=`${process.env.REACT_APP_API_HOST}/logout`
        fetch(apiurl,{
          credentials: 'include',
          headers:{
            'Authorization':`Bearer ${props.tokenValue}`
          }
        }).then((out)=>{
          if(out.status!==200){
            throw Error('Error in Logging out!!')
          }
          return out.json()
        }).then((outjson)=>{
          console.log(outjson)
          // props.setlogoutFlag(false)
          // props.logoutRemoveTkn('unset')
          // localStorage.removeItem('appky')
          // console.log(outjson)
        }).catch((e)=>{
          alert('Error in logging Out!!')
        })
      }

      function saveEditDraft(){
        props.saveeditDraftClkFlg(true)
      }

      function loadEditDraft(){
        props.loadeditDraftClkFlg(true)
      }
    
    const fallbackui=<Navbar bg="dark" variant="dark" expand="sm" className="mobilenavbar"><Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert></Navbar>
    
    try{
        return <div>
        <Navbar bg="dark" variant="dark" expand="sm" className="mobilenavbar">
            <Navbar.Brand onClick={()=>window.location.reload(false)} className='mobilenavbarhdrbrand' ><img
        src={mainlogo}
        width="50"
        height="40"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
        style={{margin:'0'}}></img></Navbar.Brand>
            <Form className='mobdetlhdrfrm'>
                <Row>
                    <Col>
                        {showLoading?<Spinner animation="border" variant="primary" /> : <SaveTwoToneIcon color='primary' fontSize='large' className='mobdetlhdrico1' onClick={saveEditFlg} />}
                        {/* {<SplitButton />} */}
                        {/* {showLoading?<Spinner animation="border" variant="primary" />:<a  href="#"><i className="material-icons mobdetlhdrico1" id='mobsaveedt' onClick={saveEditFlg} >save</i></a>} */}
                    </Col>
                </Row>
            </Form>
            <Navbar.Toggle className='ml-auto' aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">        
                <Nav.Link className='ml-auto nav-item moblogoutlink' onClick={logOutMethod} style={createStyle}><strong>Logout</strong></Nav.Link>
                <Nav.Link className='ml-auto nav-item moblogoutlink' onClick={saveEditDraft} style={createStyle}><strong>Save Draft</strong></Nav.Link>
                {/* {webdrftEdtContext.length>0 && <Nav.Link className='ml-auto nav-item moblogoutlink' onClick={loadEditDraft} style={createStyle}><strong>Load Draft</strong></Nav.Link>} */}
                {(()=>webdrftEdtContext.filter((v)=>v.rowid===props.editData.id).length>0)() && <Nav.Link className='ml-auto nav-item moblogoutlink' onClick={loadEditDraft} style={createStyle}><strong>Load Draft</strong></Nav.Link>}
            </Navbar.Collapse>    
    </Navbar>
    </div>
    }catch(e){
        return fallbackui
    }
    
}

export default MobHdrEdit