import React,{useState,useEffect} from 'react'
import { Container, Col,Form,Table,Row,Spinner,Alert } from 'react-bootstrap';
import WebFrstColRow from './WebFrstColRow'
import { v4 as uuidv4 } from 'uuid';

function WebFirstCol(props){
    
  // const [itemRow,chngeItems]=useState([{
  //   recpName:'Basic card Afqd asda sd'
  //   },{
  //   recpName:'Basic card Afqd asda sd1'
  //   }])
    const [itemRow,chngeItems]=useState([])
    useEffect(()=>{
      if(Object.keys(props.newRecrdDets).length>0){
        console.log(props.newRecrdDets)
        chngeItems((oldItm)=>[...oldItm,props.newRecrdDets])
      }
      
    },[props.newRecrdDets])
    
    //loading icon control
    const [loadingIcon,setLoading]=useState(false)
    console.log(props.srchTermVal)
  

    // useEffect(()=>{
    //   const apiurl='http://localhost:5000/getrecipe/me'
    //   fetch(apiurl).then((out)=>{
        
    //   })
    // })
    

    useEffect(()=>{
      if(props.itmUpdted){
        chngeItems([])
        const apiurl=`${process.env.REACT_APP_API_HOST}/searchdocs`
        setLoading(true)
        let qryData={}
        if(props.hdrTrm!=='unset'){
          qryData={indexname:"recipes",searchterm:`recipename:*${props.hdrTrm}*`}
        }else{
          qryData={indexname:"recipes",searchterm:`recipename:*${props.srchTermVal}*`}
        }
        
        fetch(apiurl,{
          credentials: 'include',
          method:'POST',
          headers:{
            // 'Authorization':`Bearer ${process.env.REACT_APP_AUTH_TKN}`,
            'Authorization':`Bearer ${props.authTokenValue}`,
            'Content-Type':'application/json'
          },
          body:JSON.stringify(qryData)
        }).then((out)=>{
          if(out.status===500){
            throw Error('Error Getting the recipes. Please refresh and try again!!!')
          }else if(out.status!==200){
            throw Error('Server error on Getting the recipes. Please refresh the page and try again')
          }
          return out.json()
        }).then((outjson)=>{
          chngeItems(outjson)
          // chngeItems('')
          setLoading(false)
          props.resetFlgEdit(false)
          props.resetHdrTerm('unset')
        }).catch((e)=>{
          // chngeItems([])
          chngeItems('')
          setLoading(false)
          props.resetFlgEdit(false)
          props.resetHdrTerm('unset')
          // alert(e)
        })
      }
    },[props.itmUpdted,props.hdrUpdatedQry])

    //############################this was the actual query if any issue reinstate###########################

    // useEffect(() => {
    //   if(props.itmUpdted){
    //     const apiurl=`${process.env.REACT_APP_API_HOST}/getrecipe/me`
    //   setLoading(true)
    //   fetch(apiurl,{
    //     credentials: 'include',
    //     headers:{
    //       'Authorization':'Bearer gAAAAABe7wrG1I7UbLq7IajF8xbZp5wExwyNozzW99FONO1z_jPsfe86OGkJGxOEvw9p-8KCqslfVpvCFFePTrBezmQUzlMr2JlfQIll_FAAroaK3plD7uPY8p5QCRn6VJhrDHnQxvZ6'
    //     }
    //   }).then((out)=>{
    //     return out.json()
    //   }).then((datajsonout)=>{
    //       console.log(datajsonout)
    //       chngeItems(datajsonout)
    //       setLoading(false)
    //       props.resetFlgEdit(false)
    //       // props.resetHdrFlag(false)
    //       // consol
    //       // datarw=[...datajsonout]
    //   })
    //   }
      
    //   }, [props.itmUpdted,props.hdrUpdatedQry])
    //############################this was the actual query if any issue reinstate###########################


      const [selrow,setrowsel]=useState(-1)
      function stfunc(val){
        // console.log(`this is called with value ${val}`)
        setrowsel(val)
      }

    function delItems(val){

      chngeItems((oldVal)=>oldVal.filter((rw)=>rw.id!==val))
      // stfunc(1)
    }

    //state to store the temp queried data for a selected row
    const [tmpQryData,setTmpData]=useState({})
    
    // ################################################fallback UI on error##########################################
    const fallBackui=<Col className='col1' xs={3}>
    {/* {itemRow.length===0 && <div style={{width:'250px',position:"relative",top:'50%',left:'20%'}}> */}
    {loadingIcon && <div style={{width:'250px',position:"relative",top:'50%',left:'20%'}}>
      <Spinner animation="grow" variant="info" />
      <Spinner animation="grow" variant="success" />
      <Spinner animation="grow" variant="primary" />
      <Spinner animation="grow" variant="warning" />
      <Spinner animation="grow" variant="info" />
      <Spinner animation="grow" variant="success" />
      <Spinner animation="grow" variant="primary" />
    </div>}
    <Table hover borderless className='col1-table'>
        <tbody>     
          <Alert variant='danger' >
            Error from Server on loading the recipes.
          </Alert>      
          {/* {itemRow.map((rw,idx)=><WebFrstColRow tmpData={tmpQryData} getTmpdata={setTmpData} selfunc={stfunc} selected={selrow} indx={idx} key={uuidv4()} rowData={rw} showEditpop={props.showEditView} delRowMethod={delItems} readcolData={props.getColData} rowRfrsh={props.itmUpdted} />)} */}
            {/* <tr className='webfrstcolrw'>
                <td className='align-middle webfrscol1'>Header</td>
                <td colSpan="2">
                    <div><b>Header</b></div>
                    <div>Content</div>
                </td>
                <td className='align-middle webfrscol2'>
                <a  href="#"><i className="material-icons webeditfrstrw" id='additem' onClick={()=>props.showEditView()}>
                edit
                  </i></a>
                </td>
                <td className='align-middle webfrscol3'>
                <a  href="#"><i className="material-icons webdelfrstrw" id='delitem'>
              delete_forever
                  </i></a>
                </td>
            </tr>
            <tr className='webfrstcolrw'>
                <td className='align-middle webfrscol1'>Header</td>
                <td colSpan="2">
                    <div><b>Header</b></div>
                    <div>Content</div>
                </td>
                <td className='align-middle webfrscol2'>
                <a  href="#"><i className="material-icons webeditfrstrw" id='additem' onClick={()=>props.showEditView()}>
                edit
                  </i></a>
                </td>
                <td className='align-middle webfrscol3'>
                <a  href="#"><i className="material-icons webdelfrstrw" id='delitem'>
              delete_forever
                  </i></a>
                </td>
            </tr>
            <tr className='webfrstcolrw'>
                <td className='align-middle webfrscol1'>Header</td>
                <td colSpan="2">
                    <div><b>Header</b></div>
                    <div>Content</div>
                </td>
                <td className='align-middle webfrscol2'>
                <a  href="#"><i className="material-icons webeditfrstrw" id='additem' onClick={()=>props.showEditView()}>
                edit
                  </i></a>
                </td>
                <td className='align-middle webfrscol3'>
                <a  href="#"><i className="material-icons webdelfrstrw" id='delitem'>
              delete_forever
                  </i></a>
                </td>
            </tr>
            <tr className='webfrstcolrw'>
                <td className='align-middle webfrscol1'>Header</td>
                <td colSpan="2">
                    <div><b>Header</b></div>
                    <div>Content</div>
                </td>
                <td className='align-middle webfrscol2'>
                <a  href="#"><i className="material-icons webeditfrstrw" id='additem' onClick={()=>props.showEditView()}>
                edit
                  </i></a>
                </td>
                <td className='align-middle webfrscol3'>
                <a  href="#"><i className="material-icons webdelfrstrw" id='delitem'>
              delete_forever
                  </i></a>
                </td>
            </tr>
            <tr className='webfrstcolrw'>
                <td className='align-middle webfrscol1'>Header</td>
                <td colSpan="2">
                    <div><b>Header</b></div>
                    <div>Content</div>
                </td>
                <td className='align-middle webfrscol2'>
                <a  href="#"><i className="material-icons webeditfrstrw" id='additem' onClick={()=>props.showEditView()}>
                edit
                  </i></a>
                </td>
                <td className='align-middle webfrscol3'>
                <a  href="#"><i className="material-icons webdelfrstrw" id='delitem'>
              delete_forever
                  </i></a>
                </td>
            </tr> */}
            
        </tbody>

    </Table>
    {/* <Row className='webfrstcolnavs'>
        <Col>
        <a  href="#" className='webfrstcolprev'><i className="material-icons " style={{fontSize:35}} id='prevpge' >
        arrow_back_ios
                  </i></a>
        </Col>
        <Col>
        <a  href="#" className='webfrstcolnext'><i className="material-icons " style={{fontSize:35}} id='nextpge' >
        arrow_forward_ios
                  </i></a>
        </Col>
    </Row> */}
    
    </Col>

    // ################################################ end fallback UI on error##########################################


    // ################################################ actual UI##########################################
    try{
    const colUi=<Col className='col1' xs={3}>
    {/* {itemRow.length===0 && <div style={{width:'250px',position:"relative",top:'50%',left:'20%'}}> */}
    {loadingIcon && <div style={{width:'300px',position:"relative",top:'50%',left:'15%'}}>
      <Spinner className='webhomepageloader1' animation="grow" variant="info" />
      <Spinner className='webhomepageloader2' animation="grow" variant="success" />
      <Spinner className='webhomepageloader3' animation="grow" variant="primary" />
      <Spinner className='webhomepageloader4' animation="grow" variant="warning" />
      <Spinner className='webhomepageloader5' animation="grow" variant="info" />
      <Spinner className='webhomepageloader6' animation="grow" variant="success" />
      {/* <Spinner className='webhomepageloader7' animation="grow" variant="primary" /> */}
    </div>}
    <Table hover borderless className='col1-table'>
        <tbody>            
          {itemRow.map((rw,idx)=><WebFrstColRow apiauth={props.authTokenValue} tmpData={tmpQryData} getTmpdata={setTmpData} selfunc={stfunc} selected={selrow} indx={idx} key={uuidv4()} rowData={rw} showEditpop={props.showEditView} delRowMethod={delItems} readcolData={props.getColData} rowRfrsh={props.itmUpdted} />)}
            {/* <tr className='webfrstcolrw'>
                <td className='align-middle webfrscol1'>Header</td>
                <td colSpan="2">
                    <div><b>Header</b></div>
                    <div>Content</div>
                </td>
                <td className='align-middle webfrscol2'>
                <a  href="#"><i className="material-icons webeditfrstrw" id='additem' onClick={()=>props.showEditView()}>
                edit
                  </i></a>
                </td>
                <td className='align-middle webfrscol3'>
                <a  href="#"><i className="material-icons webdelfrstrw" id='delitem'>
              delete_forever
                  </i></a>
                </td>
            </tr>
            <tr className='webfrstcolrw'>
                <td className='align-middle webfrscol1'>Header</td>
                <td colSpan="2">
                    <div><b>Header</b></div>
                    <div>Content</div>
                </td>
                <td className='align-middle webfrscol2'>
                <a  href="#"><i className="material-icons webeditfrstrw" id='additem' onClick={()=>props.showEditView()}>
                edit
                  </i></a>
                </td>
                <td className='align-middle webfrscol3'>
                <a  href="#"><i className="material-icons webdelfrstrw" id='delitem'>
              delete_forever
                  </i></a>
                </td>
            </tr>
            <tr className='webfrstcolrw'>
                <td className='align-middle webfrscol1'>Header</td>
                <td colSpan="2">
                    <div><b>Header</b></div>
                    <div>Content</div>
                </td>
                <td className='align-middle webfrscol2'>
                <a  href="#"><i className="material-icons webeditfrstrw" id='additem' onClick={()=>props.showEditView()}>
                edit
                  </i></a>
                </td>
                <td className='align-middle webfrscol3'>
                <a  href="#"><i className="material-icons webdelfrstrw" id='delitem'>
              delete_forever
                  </i></a>
                </td>
            </tr>
            <tr className='webfrstcolrw'>
                <td className='align-middle webfrscol1'>Header</td>
                <td colSpan="2">
                    <div><b>Header</b></div>
                    <div>Content</div>
                </td>
                <td className='align-middle webfrscol2'>
                <a  href="#"><i className="material-icons webeditfrstrw" id='additem' onClick={()=>props.showEditView()}>
                edit
                  </i></a>
                </td>
                <td className='align-middle webfrscol3'>
                <a  href="#"><i className="material-icons webdelfrstrw" id='delitem'>
              delete_forever
                  </i></a>
                </td>
            </tr>
            <tr className='webfrstcolrw'>
                <td className='align-middle webfrscol1'>Header</td>
                <td colSpan="2">
                    <div><b>Header</b></div>
                    <div>Content</div>
                </td>
                <td className='align-middle webfrscol2'>
                <a  href="#"><i className="material-icons webeditfrstrw" id='additem' onClick={()=>props.showEditView()}>
                edit
                  </i></a>
                </td>
                <td className='align-middle webfrscol3'>
                <a  href="#"><i className="material-icons webdelfrstrw" id='delitem'>
              delete_forever
                  </i></a>
                </td>
            </tr> */}
            
        </tbody>

    </Table>
    {/* <Row className='webfrstcolnavs'>
        <Col>
        <a  href="#" className='webfrstcolprev'><i className="material-icons " style={{fontSize:35}} id='prevpge' >
        arrow_back_ios
                  </i></a>
        </Col>
        <Col>
        <a  href="#" className='webfrstcolnext'><i className="material-icons " style={{fontSize:35}} id='nextpge' >
        arrow_forward_ios
                  </i></a>
        </Col>
    </Row> */}
    
  </Col>
  // ################################################ actual UI##########################################

    
      return colUi
    }catch(e){
      return fallBackui
    }
    
}

export default WebFirstCol