import React,{useState} from 'react'
import { Container,Row } from 'react-bootstrap';
import WebFirstCol from '../web/WebFrstCol'
import WebSecndCol from '../web/WebSecndCol'
import WebThrdCol from '../web/WebThrdCol'
import MobHomePage from '../mobile/MobHomePage'
import MobFooter from '../mobile/MobFooter'
import MobEditPage from '../mobile/MobEditPage'
import MobDetailPage from '../mobile/MobDetailPage'
import MobLandingPage from '../mobile/MobLandingPage'
import Weblandingpage from '../web/WebLandingPage'

function MainBody(props){

    // ######################################for mobile views#####################################
    // let edtflag=props.editPageState
    const [showEdit,setEdit]=useState(props.editPageState)
    // console.log(`This is the prop: ${props.editPageState}`)
    
    // console.log(`This is edit flag: ${showEdit}`)
    function toggleEdit(){
        // console.log(`This is edit flag value in loop: ${showEdit}`)
        // console.log(`This is prop flag value in loop: ${props.editPageState}`)
        setDetail(!showDetail)
        // setEdit(!showEdit)
        setEdit(false)
        // props.editPageState=!props.editPageState
        // setEdit(!props.editPageState)
    }
    function setEdtFlag(){
        setEdit(props.editPageState)
        return props.editPageState
    }
    const [showFtrEdit,setFtrEdit]=useState(false)
    function toggleFtrEdit(){
        setDetail(!showDetail)
        setFtrEdit(!showFtrEdit)
    }

    

    const [showDetail,setDetail]=useState(false)
    const [detailPgeData,setDetailData]=useState({})  //get data for mobile detail page
    function toggleDetail(inptdata){

        setDetail(!showDetail)
        setHome(!showHome)
        setDetailData(inptdata)
        props.getMobDetailDta(inptdata)
    }
    function toggleDetailFlg(){
        setDetail(!showDetail)
        setHome(!showHome)
    }

    const [showHome,setHome]=useState(false)
    function toggleHome(){
        setHome(!showHome)
        setLndng(!showLandng)
    }

    const [showLandng,setLndng]=useState(true)
    function togglelndng(){
        setLndng(!showLandng)
        
    }

    const [showFooter,setShowFooter]=useState(false)
    function togglFooter(){
        setShowFooter(!showFooter)
    }

    const [triggerQryonedit,setTrgrQry]=useState(true)

    //data for shoppping list which is selected from detail page in mobile
    // const [shopLstItms,changeShopList]=useState([])
    const [mobsrchTerm,setmobSrchtrm]=useState('unset')
    

    // ######################################end for mobile views#####################################


    // ######################################for web views#####################################
    const [srchTerm,setSrchtrm]=useState('')
    const [showWeblndng,setWebLanding]=useState(true)
    function togglWebLandingview(){
        setWebLanding(!showWeblndng)
    }

    const [secncolData,setSecndcol]=useState([])
    function setDataSecndcol(data){
        console.log(data)
        setSecndcol(data)
    }


    const [shoplistFrmsecnd,setShopListItm]=useState([])
    function changeShopList(val){
        setShopListItm(val)
    }

    const [hdrUpdated,setHdrUpdated]=useState(true)
    


    // ######################################end for web views#####################################

    return <Container fluid> 
        <Row className='webview'>
            {showWeblndng && <Weblandingpage setHdrToggl={props.setPageTypeMethd} navLndnpage={togglWebLandingview} getSrchWord={setSrchtrm} authTokenValue={props.passAuthTkn} />}
            {!showWeblndng && <WebFirstCol showEditView={props.showEdit} getColData={setDataSecndcol} newRecrdDets={props.newRecpDets} itmUpdted={props.rowUpdatedFlg} resetFlgEdit={props.resetUpdFlg} hdrUpdatedQry={hdrUpdated} resetHdrFlag={setHdrUpdated} srchTermVal={srchTerm} hdrTrm={props.hdrSearchTerm} resetHdrTerm={props.resetHdrSrchtrm} authTokenValue={props.passAuthTkn} />}
            {!showWeblndng && <WebSecndCol inputData={secncolData} chngeshopList={changeShopList} triggerQry={props.resetUpdFlg} authTokenValue={props.passAuthTkn} />}
            {!showWeblndng && <WebThrdCol inputData={secncolData} shopDatafrmSec={shoplistFrmsecnd} authTokenValue={props.passAuthTkn} />}
        </Row>

        <Row className='mobileview'>
            {showLandng && <MobLandingPage homeNavPage={toggleHome} setHdrToggl={props.setPageTypeMethd} setSearchVals={setmobSrchtrm} />}
            {showHome && <MobHomePage detailNavPage={toggleDetail} setHdrToggl={props.setPageTypeMethd} triggerReQry={triggerQryonedit} resetQryTrgr={setTrgrQry} triggerQryOnCreate={props.reQueryOnMobCreate} changeQryFlagOnCreate={props.qryMobMethod} srchStrVal={mobsrchTerm} mobhdrTrm={props.mobhdrSearchTrm} authTokenValue={props.passAuthTkn} />}
            {showDetail && !props.editPageState && <MobDetailPage inputData={detailPgeData} shopDataChnge={props.changeShopData} authTokenValue={props.passAuthTkn} />}
            {props.editPageState && <MobEditPage editData={detailPgeData} editFlg={props.saveEditDta} saveDrfteditFlg={props.saveDrftEditDta} resetsaveDrfteditFlg={props.saveeditDraftClkFlg} loadDrfteditFlg={props.loadDrftEditDta} resetloadeditDraftClkFlg={props.loadeditDraftClkFlg} resetEdtFlg={props.resetEditFlg} setNavLink={()=>props.ctrlEditForFooter()} setHdrToggl={()=>props.setPageTypeMethd('detailpage')} updateDataAftEdit={setDetailData} setQryFlag={setTrgrQry} tmpflgVal={triggerQryonedit} authTokenValue={props.passAuthTkn} />}
            {/* {!(showLandng || showHome || showDetail) && <MobEditPage />}  */}
        </Row>
        {showLandng && <MobFooter ctrlNavDisply={false} setNavLink={toggleEdit} setHdrToggl={props.setPageTypeMethd} showCrdts={true} />}
        {showHome && <MobFooter ctrlNavDisply={true} setNavLink={toggleHome} setHdrToggl={()=>props.setPageTypeMethd('landing')} setQry={setTrgrQry} resetHdrSrch={props.mobHdrSearchSet} resetMobSrch={setmobSrchtrm} />}
        {showDetail && <MobFooter ctrlNavDisply={true} setNavLink={toggleDetail} setHdrToggl={()=>props.setPageTypeMethd('homepage')} setQryFlag={setTrgrQry} resetShopLst={props.changeShopData} />}
        {(showEdit || props.editPageState) && <MobFooter ctrlNavDisply={true} setNavLink={()=>props.ctrlEditForFooter()} setHdrToggl={()=>props.setPageTypeMethd('detailpage')}/>}
    </Container>
}


export default MainBody