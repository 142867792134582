import React,{useState, useEffect,useContext} from 'react'
import { Modal,Button,Container,Row,Col,Form,Badge,Table,Jumbotron,Spinner,Alert } from 'react-bootstrap';
import WebEditModalIngRow from './WebEditModalIngRow'
import { v4 as uuidv4 } from 'uuid';
import Fab from '@material-ui/core/Fab';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';
import webdraftEditContext from '../../contexts/webdraftEdit'
import { set, get } from 'idb-keyval'
import IconButton from '@material-ui/core/IconButton';
import NoteIcon from '@material-ui/icons/Note';
import CachedIcon from '@material-ui/icons/Cached';

function WebEditModal(props){
  console.log('This is data in edit modal')
  console.log(props.editData)
  const [loadDrftClked,setLoadDrftClck]=useState(false)
  const [saveBtnFlag,setBtnFlg]=useState(false)
  const [saveJson,setSaveJson]=useState({})
  const [webdrftEdtContext, setwebdraftEditContext] = useContext(webdraftEditContext?webdraftEditContext:[])
    //-----------------read the stored draft
    useEffect(()=>{
      // console.log('this is setting the valuf of the draft value')
      get("editdraftRecipe").then(value =>{
        // setDraftRecipe(((oldval)=>{return {...value}}))
        if (!value){
          throw Error('undefined edit draft recipe')
        }
        setwebdraftEditContext(((oldval)=>{return [...value]}))
      }
        
        
      ).catch((e)=>{
        // setDraftRecipe({})
        setwebdraftEditContext(((oldval)=>{return [...oldval]}))
      })
    },[])
    //--------------------read the stored draft
  function mapTypecust(val){
    let tmp=['Link','File/Image','Text'].filter((v)=>v.toLowerCase()===val)[0]
    console.log(tmp)
    return tmp
    // let tmparry=['link','file','text']
    // let indx=tmparry.map((v,idx)=>{if (v===val) return idx})
    // return indx
  }

  function getChangedField(fldName,val){
    setSaveJson((oldVal)=>{
      oldVal[fldName]=val
      return oldVal
    })
  }

  const [saveLoading,setSaveLoadFlg]=useState(false)

  // const [webdrftEditContext, setwebdraftEditContxt] = useContext(webdraftEdtContext)
  

  //########################onchange for all text fields######################
  const [recPNme,setRcpnme]=useState(props.editData.recipename)
  const [crtNmeInvFlg,setInvCrtFlg]=useState(false)
  function chngeRecpName(event){
    event.preventDefault()
    const val=event.target.value
    if(val.length>60){
      // alert('keep the name short and crisp!!')
      setInvCrtFlg(true)
      return
    }
    setInvCrtFlg(false)
    setRcpnme(val)
    getChangedField('recipename',val)
    
  }
  useEffect(()=>{
    setRcpnme(props.editData.recipename)
    
  },[props.editData.recipename])


  const [recpType,setRcpType]=useState(mapTypecust(props.editData.recipetype))
  function chngeRecpType(event){
    event.preventDefault()
    const val=event.target.value
    setRcpType(val)
    getChangedField('recipetype',val)
  }
  useEffect(()=>{
    setRcpType(mapTypecust(props.editData.recipetype))
  },[props.editData.recipetype])


  const [recpurl,setRcpurl]=useState(props.editData.recipeurl)
  function chngeRecpUrl(event){
    event.preventDefault()
    const val=event.target.value
    setRcpurl(val)
    getChangedField('recipeurl',val)
  }
  useEffect(()=>{
    setRcpurl(props.editData.recipeurl)
  },[props.editData.recipeurl])


  const [recprptime,setRcpTime]=useState(props.editData.preptime)
  const [crtprpInvFlg,setInvCrtprpFlg]=useState(false)
  function chngeRecpTime(event){
    event.preventDefault()
    const val=event.target.value
    if(val.length>20){
      // alert('keep the name short and crisp!!')
      setInvCrtprpFlg(true)
      return
    }
    setInvCrtprpFlg(false)
    setRcpTime(val)
    getChangedField('preptime',val)
  }
  useEffect(()=>{
    setRcpTime(props.editData.preptime)
  },[props.editData.preptime])


  const [recpSrvngs,setRcpSrvng]=useState(props.editData.servings)
  const [crtsrvInvFlg,setInvCrtsrvFlg]=useState(false)
  function chngeRecpSrvngs(event){
    event.preventDefault()
    const val=event.target.value
    if(val.length>20){
      // alert('keep the name short and crisp!!')
      setInvCrtsrvFlg(true)
      return
    }
    setInvCrtsrvFlg(false)
    setRcpSrvng(val)
    getChangedField('servings',val)
  }
  useEffect(()=>{
    setRcpSrvng(props.editData.servings)
  },[props.editData.servings])

  const [recpSteps,setRcpSteps]=useState(props.editData.steps)
  function chngeRecpSteps(event){
    event.preventDefault()
    const val=event.target.value
    setRcpSteps(val)
    getChangedField('steps',val)
  }
  useEffect(()=>{
    setRcpSteps(props.editData.steps)
  },[props.editData.steps])

   //----------------file field-------
   const [selctdFile,changeSelFile]=useState(undefined)
   function handleFileLoad(event){
     const fleCntnt=event.target.files[0];
     changeSelFile(fleCntnt)
     // const fleData=new FormData()
     // console.log(fleCntnt)
   }
   //---------------file field----------



  //########################end of onchange for all text fields######################
  

  //####################################ingredient rows##################################

  //##############new way
  // const [ingRow,chngIngRow]=useState([{
  //   id:uuidv4(),
  //   itemname:'',
  //   quantity:'',
  //   source:'new'
  // }])
  let ingFlg=false
  if(props.editData.ingredients){
    if(props.editData.ingredients.length>0){
      ingFlg=true
    }
  }
  const [ingRow,chngIngRow]=useState(ingFlg?props.editData.ingredients:[{
    id:uuidv4(),
    itemname:'',
    quantity:'',
    source:'new'
  }])
  // let tmpArray=[]
    useEffect(()=>{
    let ingval=props.editData.ingredients?props.editData.ingredients:[]
    chngIngRow(ingval)
  },[props.editData.ingredients])
  
  function addNewIng(){
    setBtnFlg(true)
    chngIngRow([...ingRow,{id:uuidv4(),itemname:'',quantity:'',source:'new'}])
  }
  function addNewDrftingrows(val){
    setBtnFlg(true)
    val.forEach((rw)=>{
      chngIngRow((oldval)=>[...oldval,{id:uuidv4(),itemname:rw.itemname,quantity:rw.quantity,source:'new'}])
    })
    
  }

  function updateState(val){    
    console.log(val)
    // ingRow.push(val)
    // let tingRow=
    ingRow.map((v)=>{
      if(v.id===val.id){
        v.itemname=val.itemname
        v.quantity=val.quantity
      }
      return v
    })
    // chngIngRow(tingRow)
    // console.log(tmpArray)
  }
  //##############end new way

  // const [ingRow,chngIngRow]=useState([{
  //     id:uuidv4(),
  //     ingItem:'Add New',
  //     itmQty:'Add new'
  //   }])
  // const [tmpingRow,chngTmpIngRow]=useState([])
  // // const [ingRow,chngIngRow]=useState([uuidv4()])
  // function setIngRows(val){    
  //   chngIngRow(val)
  // }
  // useEffect(()=>{
  //   let ingval=props.editData.ingredients?props.editData.ingredients:[]
  //   setIngRows(ingval)
  // },[props.editData.ingredients])


  
  // const [ingRow,chngIngRow]=useState([{
  //   rowid:uuidv4(),
  //   ingItem:'Item 1',
  //   itmQty:5
  // },{
  //   rowid:uuidv4(),
  //   ingItem:'Item 2',
  //   itmQty:10
  // }])

  

  // function addNewIng(){

  //   if(tmpingRow.length===0){
  //     const rnd=uuidv4()
  //     chngIngRow((oldVal)=>[...oldVal,{id:rnd,ingItem:'',itmQty:''}])
  //     chngTmpIngRow([{id:rnd,ingItem:'',itmQty:''}])
  //   }else{
  //     const rnd1=uuidv4()
  //     chngIngRow([...tmpingRow,{id:rnd1,ingItem:'',itmQty:''}])
  //     chngTmpIngRow([...tmpingRow,{id:rnd1,ingItem:'',itmQty:''}])
  //   }

    
  //   // chngIngRow((oldval)=>[...oldval,uuidv4()])
  // }

  // function changeTmpArry(vals){
  //   let a=''
  //   console.log(vals)
  //   let tmpRws=tmpingRow.map((rwvl)=>{
  //     if(rwvl.id===vals.id){
  //       rwvl.itemname=vals.itemname
  //       rwvl.quantity=vals.quantity
  //     }
  //     return rwvl
  //   })
  //   // chngIngRow((oldVal)=>[...oldVal,vals])
  //   chngTmpIngRow(tmpRws)
  //   // chngIngRow(tmpRws)
  // }

  function runOnClose(){

    // chngIngRow([{
    //   rowid:uuidv4(),
    //   ingItem:'Item 1',
    //   itmQty:5
    // },{
    //   rowid:uuidv4(),
    //   ingItem:'Item 2',
    //   itmQty:10
    // }])
    setInvCrtFlg(false)
    setInvCrtprpFlg(false)
    setInvCrtsrvFlg(false)
    setSaveJson({})
    //---------------------optional
    setRcpnme(props.editData.recipename)
    // setRcpType(props.editData.recipetype)
    setRcpurl(props.editData.recipeurl)
    setRcpTime(props.editData.preptime)
    setRcpSrvng(props.editData.servings)
    setRcpSteps(props.editData.steps)
    setBtnFlg(false)
  props.handleClose()
}

function delIngRow(val){

  chngIngRow((oldVal)=>oldVal.filter((rw)=>rw.id!==val))
}

  //******************************************not used******************************************
  // function changeIngVals(valObj){
  //   // if(!ingRow.id){
  //   //   return chngIngRow([valObj])
  //   // }
  //   if(tmpingRow.length===0){
  //     return chngTmpIngRow([...tmpingRow,valObj])
  //   }

  //   let updFlg=false
  //   let updIngs=tmpingRow.map((v)=>{
  //     if (v.id===valObj.id){
  //       v.itemname=valObj.itemname
  //       v.quantity=valObj.quantity
  //       updFlg=true        
  //     }
  //     return v
  //   })
  //   if(!updFlg){
  //     updIngs=[...updIngs,valObj]
  //   }
  //   chngTmpIngRow(updIngs)
  //   console.log('this is running####################################################')
  //   console.log(tmpingRow)

  // }
  //******************************************not used******************************************

  



  //####################################end ingredient rows##################################

  //validate fields
  const [invalidFlgs,setinValidFlg]=useState([false,false,false,false])
  function validateFields(inpt){
    console.log(inpt)
    let errflg=true
    let tmparry=[false,false,false,false]
    if(Object.keys(inpt).includes('recipename')){
      if(inpt.recipename===''){
        tmparry[0]=true
        errflg=false
      }
    }

    if(Object.keys(inpt).includes('preptime')){
      if(inpt.preptime===''){
        tmparry[2]=true
        errflg=false
      }
    }

    if(Object.keys(inpt).includes('servings')){
      if(inpt.servings===''){
        tmparry[3]=true
        errflg=false
      }
    }

    if(Object.keys(inpt).includes('recipeurl')){
      if(inpt.recipeurl===''){
        tmparry[1]=true
        errflg=false
        }
    }
      if(!errflg){
        setinValidFlg((oldval)=>[...tmparry])
      }
    return errflg
  }

  //###########################save the draft data##############
  async function draftSave(){
    // let draftdta={
    //   recipename:crtName,
    //   recipetype:crtType,
    //   preptime:crtPrep,
    //   servings:crtSrvng,
    //   steps:crtStps,
    //   mainimage:"mainimg.png",
    //   recipeurl:crtTUrl,
    //   recipefile:"na",
    //   recipefileid:"na",
    //   // ingredients:ingRowVals.length===0?[]:ingRowCopy
    //   ingredients:ingRowVals.length===0?[]:ingRowVals
    // }
    try{
      let draftdta={...saveJson}
      let tmpdta=[...ingRow]
      let filteredTmp=tmpdta.filter((v)=>v.source==='new')
      draftdta.ingredients=[...filteredTmp]
      draftdta.rowid=props.editData.id
      setSaveLoadFlg(true)
      let tmpdata=await get("editdraftRecipe")
      if(tmpdata){
       tmpdata=[...tmpdata.filter((v)=>v.rowid!==props.editData.id)]
        tmpdata.push(draftdta)
      }else{
        tmpdata=[]
        tmpdata.push(draftdta)
      }
    
      // await set("editdraftRecipe", {})
      await set("editdraftRecipe", tmpdata)
    }catch(e){
      alert('error in saving draft')
    }
    
    setSaveLoadFlg(false)
  }

  //#######################################load the draft data####################
  const [draftIngrRows,setDrftIngrrows]=useState([])
  async function loadDraft(){
    let loadedDraft=await get("editdraftRecipe")
    let draftfiltered={...loadedDraft.filter((v)=>v.rowid===props.editData.id)[0]}
    let storedKeys=Object.keys(draftfiltered)
    if(draftfiltered.recipename){
      setRcpnme(draftfiltered.recipename)
      getChangedField('recipename',draftfiltered.recipename)
    }
    if(draftfiltered.recipeurl){
      setRcpurl(draftfiltered.recipeurl)
      getChangedField('recipeurl',draftfiltered.recipeurl)
    }
    if(draftfiltered.preptime){
      setRcpTime(draftfiltered.preptime)
      getChangedField('preptime',draftfiltered.preptime)
    }
    if(draftfiltered.servings){
      setRcpSrvng(draftfiltered.servings)
      getChangedField('servings',draftfiltered.servings)
    }
    if(draftfiltered.steps){
      setRcpSteps(draftfiltered.steps)
      getChangedField('steps',draftfiltered.steps)
    }
    console.log('##############################this is inside the loading before addnewrow')
    console.log(draftfiltered)
    
    addNewDrftingrows(draftfiltered.ingredients)
    // setRcpType(props.editData.recipetype)
    
    
    
    

    console.log(draftfiltered)
  }

  function validateLoadDraftbtn(){
    webdrftEdtContext.forEach((v)=>{
      console.log(v)
    })
  }

  //####################################save the form###########################################

  function saveEdits(){    
    console.log(ingRow)
    if(!validateFields(saveJson)){
      return
    }
    setSaveLoadFlg(true)
    //---------------------------------get edited file-----------------
    if(props.editData.recipetype==='file/image' && selctdFile){
      //**********delete the old file */
      const delSrvc=`${process.env.REACT_APP_API_HOST}/deletefilebackend/${props.editData.recipefile}`
      fetch(delSrvc,{
        credentials: 'include',
        method:'DELETE',
        headers:{
          'Authorization':`Bearer ${props.passAuthTkn}`,
          // 'usrid':props.editData.user_id,
          // 'mode':'priv'
          // 'Content-Type':'application/json'
        }
      }).then((out)=>{
        if(out.status===500){
          throw new Error('error in updating recipe')
        }
        return out.json()
      }).then((outjson)=>{
        console.log('done')
      }).catch((e)=>{
        console.log(e)
        // setSaveLoadFlg(false)
        // alert(e)
        // return
      })

      //**********end delete the old file */

      //**************************update the file******************************* */
      const fleSvcUrl=`${process.env.REACT_APP_API_HOST}/postfile`
      const filedata = new FormData()
      filedata.append('image',selctdFile)
      fetch(fleSvcUrl,{
        credentials: 'include',
        method:'POST',
        headers:{
          'Authorization':`Bearer ${props.passAuthTkn}`,
          // 'Content-Type':'application/json'
        },
        body: filedata
      }).then((fleOut)=>{
        if(fleOut.status===500){
          throw Error('error in loading file')
        }
        return fleOut.json()
      }).then((flejson)=>{
        saveJson.recipefile=flejson.imagefile
        saveJson.recipefileid=flejson.imageid
        let ingDataRw=[]
        ingRow.forEach((ings)=>{
          if(ings.source==='new'){
            let tmpIng={}
            Object.keys(ings).forEach((vals)=>{
            if(vals==='id' || vals==='source'){
  
            }else{
              tmpIng[vals]=ings[vals]
            }
        })
          ingDataRw.push(tmpIng)
        }
      
      })
      saveJson.ingredients=ingDataRw    
      const apiurl=`${process.env.REACT_APP_API_HOST}/updaterec/me/${props.editData.id}`
    
      fetch(apiurl,{
        credentials: 'include',
        method:'PATCH',
        headers:{
                'Authorization':`Bearer ${props.passAuthTkn}`,
                'Content-Type':'application/json'
        },
        body:JSON.stringify(saveJson)
      }).then((out)=>{
        if(out.status===404){
          throw Error('recipe not found')
        }else if(out.status!==200){
          throw Error('server error in updating')
        }
        return out.json()
      }).then((outjson)=>{
        console.log('updated recipe')
        //update index on elastic search
        if(Object.keys(saveJson).includes('recipename')){
          const esurl=`${process.env.REACT_APP_API_HOST}/updatedoc`
          const esdata={
            indexname:"recipes",
            doc_id:outjson.id,
            data:{doc: {recipename: saveJson.recipename}}
          }
          fetch(esurl,{
              credentials: 'include',
              method:'POST',
              headers:{
                'Authorization':`Bearer ${props.passAuthTkn}`,
                'Content-Type':'application/json'
              },
          body:JSON.stringify(esdata)
          })
        }
        //clear draft data
        let tmpdraftdeldta=[...webdrftEdtContext]
        tmpdraftdeldta=tmpdraftdeldta.filter((rw)=>rw.rowid!==props.editData.id)
        set("editdraftRecipe", [...tmpdraftdeldta])
        // set("editdraftRecipe", [])
        //end update elastic search
        props.chngeUpdted(true)
        setSaveLoadFlg(false)
        runOnClose()
      }).catch((e)=>{
        setSaveLoadFlg(false)
        alert(e)
      })
      }).catch((e)=>{
        setSaveLoadFlg(false)
        alert(e)
      })

      //**************************end update the file******************************* */
      return
    }

    //--------------------------------end get edited file--------------------
    let ingDataRw=[]
    ingRow.forEach((ings)=>{
      if(ings.source==='new'){
        let tmpIng={}
        Object.keys(ings).forEach((vals)=>{
          if(vals==='id' || vals==='source'){
  
          }else{
            tmpIng[vals]=ings[vals]
          }
      })
        ingDataRw.push(tmpIng)
      }
      
    })
    saveJson.ingredients=ingDataRw    
    const apiurl=`${process.env.REACT_APP_API_HOST}/updaterec/me/${props.editData.id}`
    
    fetch(apiurl,{
      credentials: 'include',
      method:'PATCH',
      headers:{
                'Authorization':`Bearer ${props.passAuthTkn}`,
                'Content-Type':'application/json'
      },
      body:JSON.stringify(saveJson)
    }).then((out)=>{
      if(out.status===404){
        throw Error('recipe not found')
      }else if(out.status!==200){
        throw Error('server error in updating')
      }
      return out.json()
    }).then((outjson)=>{
      console.log('updated recipe')
      //update index on elastic search
      if(Object.keys(saveJson).includes('recipename')){
        const esurl=`${process.env.REACT_APP_API_HOST}/updatedoc`
        const esdata={
          indexname:"recipes",
          doc_id:outjson.id,
          data:{doc: {recipename: saveJson.recipename}}
        }
        fetch(esurl,{
            credentials: 'include',
            method:'POST',
            headers:{
              'Authorization':`Bearer ${props.passAuthTkn}`,
              'Content-Type':'application/json'
            },
        body:JSON.stringify(esdata)
        })
      }
      //clear draft data
      let tmpdraftdeldta=[...webdrftEdtContext]
      tmpdraftdeldta=tmpdraftdeldta.filter((rw)=>rw.rowid!==props.editData.id)
      set("editdraftRecipe", [...tmpdraftdeldta])
      // set("editdraftRecipe", [])
      //end update elastic search
      props.chngeUpdted(true)
      setSaveLoadFlg(false)
      runOnClose()
    }).catch((e)=>{
      setSaveLoadFlg(false)
      alert(e)
    })

    // console.log(saveJson)
  }
  // console.log('#########################This is the context####################')
  // console.log(webdrftEdtContext)

  //####################################end save the form###########################################

    const fallBackUi=<Modal show={props.setdisplay} onHide={runOnClose}  ><Alert variant='danger' >Unexpected Error from Server on loading the edit component. Please refresh and try again!!</Alert></Modal> 

    try{
      return <Modal show={props.setdisplay} onHide={runOnClose}  className='fontclassfordiv' >
    <Modal.Header className='webcreatemodalhdr' closeButton>
      <Modal.Title>Edit Item</Modal.Title>
    </Modal.Header>
    <Modal.Body >
    {/* <Container> */}

      {/* <!--####### row div for name #######--> */}
      <Row className='webcreatefirstrow'>
        <Col>
        <Form className='webcreatefirstform'>
          {/* <Form.Group controlId="webcreatefirstform.ControlInput1"> */}
          <Form.Group>
            {crtNmeInvFlg && <Badge variant="dark">Keep name short and crisp!!</Badge>}
            <Form.Control style={invalidFlgs[0]?{borderColor:'red'}:{}} type="text" placeholder="Enter Name" id="recipename" value={recPNme?recPNme:props.editData.recipename} onChange={chngeRecpName} />
            {/* <Form.Label>Change Header Image:</Form.Label>
            <Form.Control type="file" id="recipemainig"  /> */}
          </Form.Group>

          {/* <Form.Group controlId="webcreatefirstform.ControlInput2"> */}
          <Form.Group>
            <Form.Label style={{fontWeight:'bolder'}} >Type:</Form.Label>
            <Form.Control type='text' value={recpType} readOnly></Form.Control>
            {/* <Form.Control as="select" id="seltype" value={recpType} onChange={chngeRecpType} readOnly>
              <option >Link</option>
              <option >File/Image</option>
              <option >Text</option>
            </Form.Control> */}
          </Form.Group>
        </Form>
        </Col>
      </Row>

      {/* <!--####### row div for file #######--> */}
      {recpType==='File/Image' && <Row className='webcreatesecndtrow'>
        <Col>
        <Form className='webcreatesecndform'>
          {/* <Form.Group controlId="webcreatefirstform.ControlInput3"> */}
          <Form.Group>
            <Form.Label style={{fontWeight:'bolder'}} >Select File:</Form.Label>
            <Form.Control type="file" id="recipefile" onChange={handleFileLoad} />
          </Form.Group>
        </Form>
        </Col>
      </Row> }

      {/* <!--####### row div for url/link #######--> */}
      {recpType==='Link' && <Row className='webcreatesecndtrow'>
        <Col>
          <Form className='webcreatesecndform'>
            <Form.Group controlId="webcreatefirstform.ControlInput2">
              <Form.Control style={invalidFlgs[1]?{borderColor:'red'}:{}} type="text" placeholder="Enter link" id="recipeurl" value={recpurl?recpurl:props.editData.recipeurl} onChange={chngeRecpUrl} />
            </Form.Group>
          </Form>
        </Col>
      </Row> }
      

      {/* <!--####### row div for prep time #######--> */}
      {recpType==='Text' && <Row className='webcreatethrdtrow'>
        <Col>
          <Form style={{paddingBottom:10}}>
            <Form.Row>
              <Col>
                {crtprpInvFlg && <Badge variant="dark">Keep this short and crisp!!</Badge>}
                <Form.Label style={{fontWeight:'bolder'}} >Prep Time</Form.Label>                
                <Form.Control style={invalidFlgs[2]?{borderColor:'red'}:{}} type="text" placeholder="Enter Prep Time" id="preptime" value={recprptime?recprptime:props.editData.preptime} onChange={chngeRecpTime} />
              </Col>
              <Col>
                {crtsrvInvFlg && <Badge variant="dark">Keep this short and crisp!!</Badge>}
                <Form.Label style={{fontWeight:'bolder'}} >Servings</Form.Label>                
                <Form.Control style={invalidFlgs[3]?{borderColor:'red'}:{}} type="text" placeholder="Enter Servings" id="srvngs" value={recpSrvngs?recpSrvngs:props.editData.servings} onChange={chngeRecpSrvngs} />
              </Col>
          </Form.Row>
          </Form>
        </Col>
      </Row>}
      


      {/* <!--####### row div for ingredients table #######--> */}
      {true && <Row className='webcreatefrthrow'>
        <Col>
          <Row>
            <Col xs={10}>
              <h1><Badge variant="secondary" className='webcreateinghdr'>Ingredients</Badge></h1>
            </Col>
            <Col>
              <Fab color='primary' size="small" aria-label="add" style={{top: '8px'}} >
                <AddTwoToneIcon fontSize='large'  onClick={addNewIng} />
              </Fab>
              {/* <a  href="#"><i className="material-icons webcreateaddingbtn" id='addalling' onClick={addNewIng} >add_circle_outline</i></a> */}
            </Col>
          </Row>

          <Table striped >
            <thead>
              <tr>
                <th>#</th>
                <th>Item</th>
                {/* <th></th> */}
                <th></th>
                <th>Quantity</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {ingRow.map((rw,indx)=><WebEditModalIngRow key={uuidv4()} rowData={rw} idVal={rw.id} deleteIng={delIngRow}  rowUpdates={updateState} triggerQryOndel={props.chngeUpdted} rowIndx={indx} authTokenValu={props.passAuthTkn} />)}
              {/* <tr>
                <th>1</th>
                <td><Form.Control type="text" placeholder="Enter Item Name" id="additmnme" /></td>
                <td></td>
                <td></td>
                <td><Form.Control type="text" placeholder="Enter Quantity" id="addqty" /></td>
                <td><a href="#"><i className="material-icons webdelingrow" id='delingrow' >delete_sweep</i></a></td>
              </tr>
              <tr>
                <th>1</th>
                <td><Form.Control type="text" placeholder="Enter Item Name" id="additmnme" /></td>
                <td></td>
                <td></td>
                <td><Form.Control type="text" placeholder="Enter Quantity" id="addqty" /></td>
                <td><a href="#"><i className="material-icons webdelingrow" id='delingrow' >delete_sweep</i></a></td>
              </tr>
              <tr>
                <th>1</th>
                <td><Form.Control type="text" placeholder="Enter Item Name" id="additmnme" /></td>
                <td></td>
                <td></td>
                <td><Form.Control type="text" placeholder="Enter Quantity" id="addqty" /></td>
                <td><a href="#"><i className="material-icons webdelingrow" id='delingrow' >delete_sweep</i></a></td>
              </tr> */}
            </tbody>
          </Table>

        </Col>        
      </Row>}
      


      {/* <!--####### row div for steps for recipe #######--> */}
      <Row className='webcreateffthrow'>
        <Col>
          {recpType==='Text' ? <h1><Badge variant="secondary" className='webcreaterecphdr'>How To?</Badge></h1>: <h1><Badge variant="secondary" className='webcreaterecphdr' style={{left:'35%'}}>Notes</Badge></h1> }
          <Jumbotron fluid>
            <Container>
              <Form.Control as="textarea" rows="5" id="comment" value={recpSteps} onChange={chngeRecpSteps} />
            </Container>
          </Jumbotron>
        </Col>
      </Row>



    {/* </Container>     */}
    </Modal.Body>
    <Modal.Footer>
    {recpType!=='File/Image' && <IconButton aria-label="save" className='webCreateDraftSave' onClick={draftSave} >
        <NoteIcon />
      </IconButton>}
      {recpType!=='File/Image' && (()=>webdrftEdtContext.filter((v)=>v.rowid===props.editData.id).length>0)()?<IconButton aria-label="load" className='webCreateDraftload' onClick={loadDraft} >
        <CachedIcon />
      </IconButton> : null}
      <Button variant="secondary" onClick={runOnClose}>
        Close
      </Button>
      {saveLoading?<Spinner animation="border" variant="primary" />:(Object.keys(saveJson).length!==0 || saveBtnFlag || selctdFile) && <Button variant="primary" style={{backgroundColor: '#363062'}} onClick={saveEdits} >
        Save Changes
      </Button>}
      
    </Modal.Footer>
  </Modal>
    }catch(e){
      console.log(e)
      return fallBackUi
    }

    
}

export default WebEditModal