import React,{useState,useEffect} from 'react'
import { Container, Col,Form,Table,Row,Badge,Jumbotron,Card,Spinner,Alert,Image } from 'react-bootstrap';
import WebThrdColShopRow from './WebThrdColShopRow'
import { v4 as uuidv4 } from 'uuid';
import sampleback3 from '../../images/shoplist.png'
import AddShoppingCartTwoToneIcon from '@material-ui/icons/AddShoppingCartTwoTone';

function WebThrdCol(props){
    console.log('This is in 3rd col')
    console.log(props.inputData)
    const [shopListRw,addShopRow]=useState([])
    console.log(shopListRw)
    const [loadingShw,setLoadng]=useState(false)
    const [listLoadingShow,setLstLoading]=useState(false)
    const [delRowLoadingIco,setDelLoadingIco]=useState(false)
    const [loadingRowDisbled,setDisabled]=useState(false)

    // ############################get all categ values ##########################
    const [categValues,setCategvalues]=useState([])
    useEffect(()=>{
        const apiurl=`${process.env.REACT_APP_API_HOST}/getcategories`
          
          fetch(apiurl,{
            credentials: 'include',
            method:'GET',
            headers:{
              // 'Authorization':`Bearer ${process.env.REACT_APP_AUTH_TKN}`,
              'Authorization':`Bearer ${props.authTokenValue}`
            }
          }).then((out)=>{
            if(out.status===500){
              throw Error('Error Getting the categories')
            }else if(out.status!==200){
              throw Error('Error Getting the categories')
            }
            return out.json()
          }).then((outjson)=>{
            console.log(outjson)
            setCategvalues((oldval)=>[...outjson])
          }).catch((e)=>{
            // chngeItems([])
            
            // alert(e)
          })
    },[])
    // ############################end get all categ values ##########################
    

    useEffect(()=>{
        if(props.shopDatafrmSec.length>0){
            addShopRow((oldVal)=>[...oldVal,...props.shopDatafrmSec])
        }
    },[props.shopDatafrmSec])

    //############modify shop row category############
    function upDateCategonChange(inpt){
        
        shopListRw.map((rws)=>{
            if(!rws.shopapprowid){
                if(rws.tmpid===inpt.changeId){
                    rws.category=inpt.selectedval
                }
            }           
            return rws
        })
        console.log(shopListRw)
    }

    //############end modify shop row category############

    // useEffect(()=>{
    //     addShopRow(shopListRw)
    // },[shopListRw])

    //########################get shoplist data################
    
    useEffect(() => {
        // addShopRow([])
        if(props.inputData.id){
            const apiurl=`${process.env.REACT_APP_API_HOST}/getlistitem/me/${props.inputData.id}`
            setLstLoading(true)
            fetch(apiurl,{
                credentials: 'include',
                headers:{
                    'Authorization':`Bearer ${props.authTokenValue}`
                }
            }).then((out)=>{
                console.log(out.status)
                let dataOut=out.json()
                if(out.status===404){
                    // console.log('*********************************************************inside inside error')
                    dataOut=[]
                }else if(out.status!==200){
                    dataOut=[]
                }
                return dataOut
            }).then((datajsonout)=>{
                console.log('in the loop')
                console.log(datajsonout)
                setLstLoading(false)
                let rwval=datajsonout?datajsonout:[]
                // setIngRows(ingval)
                addShopRow(rwval)
                // consol
                // datarw=[...datajsonout]
            }).catch((err)=>{
                console.log('in error loop')
                setLstLoading(false)
                addShopRow([])
            })
        }else{
            addShopRow([])
        }
        }, [props.inputData.id])

        

    //######################end shoplist data######################

    //#####################add all to shopping list using api####################
    // useEffect(()=>{

    // },[props.inputData.id])

    function addAllShopRws(){
        const allRws=shopListRw.filter((vals)=>!vals.shopapprowid)
        if(allRws.length!==0){
            setDisabled(true)
        }
        let shopApprows=shopListRw.filter((vals)=>vals.shopapprowid)
        console.log(allRws)
        const apiurl=`${process.env.REACT_APP_API_HOST}/addtolist/me/${props.inputData.id}`
        // console.log(apiurl)
        
        allRws.forEach((shprw)=>{
            delete shprw.tmpid
            setLoadng(true)
            fetch(apiurl,{
                method:'POST',
                credentials: 'include',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Bearer ${props.authTokenValue}`
                    },
                body:JSON.stringify(shprw)
            }).then((out)=>{
                console.log(out.status)
                if(out.status===400){
                    throw Error('Error in the request to add to shopping list. Please refresh and try again!!!')
                }else if(out.status===500){
                    throw Error('Server error in adding to shopping list. Please refresh and try again!!!')
                }
                return out.json()
            }).then((outjson)=>{
                setDisabled(false)
                shopApprows.push(outjson)
                console.log(shopApprows)
                addShopRow([])
                addShopRow(shopApprows)
                setLoadng(false)
            }).catch((e)=>{
                setDisabled(false)
                console.log(e)
                setLoadng(false)
                alert(e)
            })
        })

        }


    //#####################end add all to shopping list using api####################


    //###########################delete shopping list item##############################
    function delShopItem(itm){
        if(itm.tmpid){
            addShopRow((oldVal)=>{
                return oldVal.filter((rwVal)=>rwVal.tmpid!==itm.tmpid)
            })
            return
        }
        const apiurl=`${process.env.REACT_APP_API_HOST}/completelistitem/me/${itm.id}`
        setDelLoadingIco(true)
        fetch(apiurl,{
            credentials: 'include',
            headers:{
                'Authorization':`Bearer ${props.authTokenValue}`
            }
        }).then((out)=>{
            if(out.status===404){
                throw Error('shopping item not found on server. Please refresh and try again!!!')
            }else if(out.status===500){
                throw Error('server error in deleting shopping list item. Please refresh and try again!!!')
            }
            return out.json()
        }).then((outjson)=>{
            console.log('deleted item')
            setDelLoadingIco(false)
            addShopRow((oldVal)=>{
                return oldVal.filter((rwVal)=>rwVal.id!==itm.id)
            })
        }).catch((e)=>{
            setDelLoadingIco(false)
            alert(e)
        })
        
    }



    //###########################delete shopping list item##############################


    // const [shopListRw,addShopRow]=useState([{
    //     itemname:'A new item'
    // },{
    //     itemname:'aascacz czxc'
    // },{
    //     itemname:'xy6673 37'
    // }])

    const fallbackUI=<Col className='col3'  xs={2}><Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert></Col>

    try{
        return <Col className='col3'  xs={2}>
        <Row>
            <Col>
            {/* <Card className='webhomerocol3imgcard'> */}
                {/* <Card className='webhomerocol3img'> */}
                {/* <Card className='webthrdcolhdricon'> */}
                    {/* <Card.Body className='webthrdcolhdrbdy'>Shopping List</Card.Body> */}
                    <Image className='webhomecol3imgtag' src={`${sampleback3}`} fluid rounded/>
                    {/* <Alert>Shopping List</Alert> */}
                {/* </Card> */}
            </Col>
        </Row>

        <Row style={{paddingTop:40}} className='webthrdcoladdscroll'>
            <Col >
                <Table hover >
                    <tbody>
                        {listLoadingShow?<Spinner animation="border" variant="primary" />:shopListRw.map((rw)=><WebThrdColShopRow key={uuidv4()} rowData={rw} delRowFunc={delShopItem} upDateCategValue={upDateCategonChange} delLoadingFlag={delRowLoadingIco} authTokenVal={props.authTokenValue} categDisabled={loadingRowDisbled} categRows={categValues} />)}
                        {/* <tr>
                            <td>Mark akdahsj akh alskdasldj alksd </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>

                        <tr>
                            <td>Item 1</td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>

                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr>
                        <tr>
                            <td>Item 2 </td>
                            <td><a href='#'><i className="material-icons webthrdcoladdshp1" id='additem1' >
                      delete_forever
                          </i></a></td>
                        </tr> */}
                        
                        
                    </tbody>
                </Table>

                

                {/* <Row className='webfrstcolnavs'>
                    <Col>
                        <a  href="#" className='webfrstcolprev'><i className="material-icons " style={{fontSize:35}} id='prevpge' >
                            arrow_back_ios
                            </i></a>
                    </Col>
                    <Col>
                        <a  href="#" className='webfrstcolnext'><i className="material-icons " style={{fontSize:35}} id='nextpge' >
                            arrow_forward_ios
                            </i></a>
                    </Col>
                </Row> */}

                
            </Col>
        </Row>

        <Row>
            <Col>
                {loadingShw?<Spinner animation="grow" variant="info" /> : <AddShoppingCartTwoToneIcon fontSize='large' color='primary' className='webthrdcoladdshpico1' onClick={addAllShopRws} />}
                {/* <a  href="#" className='webthrdcoladdallshp1'>{loadingShw?<Spinner animation="grow" variant="info" />:<i className="material-icons webthrdcoladdshpico1" id='additem' onClick={addAllShopRws} >
                playlist_add
                    </i>}</a> */}
            </Col>
        </Row>
    </Col>
    }catch(e){
        return fallbackUI
    }
    
}

export default WebThrdCol