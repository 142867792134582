import React,{useState,useEffect,useContext} from 'react'
import { Container, Col,Form,Table,Row,Badge,Jumbotron,Card,Alert } from 'react-bootstrap';
import MobEditPageIngrow from './MobEditPageIngRow'
import { v4 as uuidv4 } from 'uuid';
import Fab from '@material-ui/core/Fab';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';
import webdraftEditContext from '../../contexts/webdraftEdit'
import { set, get } from 'idb-keyval'
import IconButton from '@material-ui/core/IconButton';
import NoteIcon from '@material-ui/icons/Note';
import CachedIcon from '@material-ui/icons/Cached';

function MobEditPage(props){

    // console.log(props.editData)
    // console.log(props.editFlg)
    const [loadDrftClked,setLoadDrftClck]=useState(false)
    //props.resetEdtFlg

    // const [ingRow,chngIngRow]=useState([{
    //     rowid:uuidv4(),
    //     ingItem:'Item 1',
    //     itmQty:5
    //   },{
    //     rowid:uuidv4(),
    //     ingItem:'Item 2',
    //     itmQty:10
    //   }])


      const [saveJson,setSaveJson]=useState({})
      const [webdrftEdtContext, setwebdraftEditContext] = useContext(webdraftEditContext?webdraftEditContext:[])

      //-----------------read the stored draft
      useEffect(()=>{
        // console.log('this is setting the valuf of the draft value')
        get("editdraftRecipe").then(value =>{
          // setDraftRecipe(((oldval)=>{return {...value}}))
          if (!value){
            throw Error('undefined edit draft recipe')
          }
            setwebdraftEditContext(((oldval)=>{return [...value]}))
          }
        
        
          ).catch((e)=>{
            // setDraftRecipe({})
            setwebdraftEditContext(((oldval)=>{return [...oldval]}))
          })
      },[])
      //--------------------read the stored draft


      //##################################state values for all fields########################

    function mapTypecust(val){
        let tmp=['Link','File/Image','Text'].filter((v)=>v.toLowerCase()===val)[0]
        console.log(tmp)
        return tmp
        // let tmparry=['link','file','text']
        // let indx=tmparry.map((v,idx)=>{if (v===val) return idx})
        // return indx
      }
    
      function getChangedField(fldName,val){
        setSaveJson((oldVal)=>{
          oldVal[fldName]=val
          return oldVal
        })
      }
    const [recPNme,setRcpnme]=useState(props.editData.recipename)
    const [crtNmeInvFlg,setInvCrtFlg]=useState(false)
  function chngeRecpName(event){
    event.preventDefault()
    const val=event.target.value
    if(val.length>60){
      // alert('keep the name short and crisp!!')
      setInvCrtFlg(true)
      return
    }
    setInvCrtFlg(false)
    setRcpnme(val)
    getChangedField('recipename',val)
    
  }
  useEffect(()=>{
    setRcpnme(props.editData.recipename)
    
  },[props.editData.recipename])


  const [recpType,setRcpType]=useState(mapTypecust(props.editData.recipetype))
  function chngeRecpType(event){
    event.preventDefault()
    const val=event.target.value
    setRcpType(val)
    getChangedField('recipetype',val.toLowerCase())
  }
  useEffect(()=>{
    setRcpType(mapTypecust(props.editData.recipetype))
  },[props.editData.recipetype])


  const [recpurl,setRcpurl]=useState(props.editData.recipeurl)
  function chngeRecpUrl(event){
    event.preventDefault()
    const val=event.target.value
    setRcpurl(val)
    getChangedField('recipeurl',val)
  }
  useEffect(()=>{
    setRcpurl(props.editData.recipeurl)
  },[props.editData.recipeurl])


  const [recprptime,setRcpTime]=useState(props.editData.preptime)
  const [crtprpInvFlg,setInvCrtprpFlg]=useState(false)
  function chngeRecpTime(event){
    event.preventDefault()
    const val=event.target.value
    if(val.length>20){
      // alert('keep the name short and crisp!!')
      setInvCrtprpFlg(true)
      return
    }
    setInvCrtprpFlg(false)
    setRcpTime(val)
    getChangedField('preptime',val)
  }
  useEffect(()=>{
    setRcpTime(props.editData.preptime)
  },[props.editData.preptime])


  const [recpSrvngs,setRcpSrvng]=useState(props.editData.servings)
  const [crtsrvInvFlg,setInvCrtsrvFlg]=useState(false)
  function chngeRecpSrvngs(event){
    event.preventDefault()
    const val=event.target.value
    if(val.length>20){
      // alert('keep the name short and crisp!!')
      setInvCrtsrvFlg(true)
      return
    }
    setInvCrtsrvFlg(false)
    setRcpSrvng(val)
    getChangedField('servings',val)
  }
  useEffect(()=>{
    setRcpSrvng(props.editData.servings)
  },[props.editData.servings])

  const [recpSteps,setRcpSteps]=useState(props.editData.steps)
  function chngeRecpSteps(event){
    event.preventDefault()
    const val=event.target.value
    setRcpSteps(val)
    getChangedField('steps',val)
  }
  useEffect(()=>{
    setRcpSteps(props.editData.steps)
  },[props.editData.steps])

     //----------------file field-------
     const [selctdFile,changeSelFile]=useState(undefined)
     function handleFileLoad(event){
       const fleCntnt=event.target.files[0];
       changeSelFile(fleCntnt)
       // const fleData=new FormData()
       // console.log(fleCntnt)
     }
     //---------------file field----------

    //##################################end state values for all fields########################

    //#####################################process ingredient rows#########################
    let ingFlg=false
    if(props.editData.ingredients){
      if(props.editData.ingredients.length>0){
        ingFlg=true
      }
    }
    const [ingRow,chngIngRow]=useState(ingFlg?props.editData.ingredients:[{
      id:uuidv4(),
      itemname:'',
      quantity:'',
      source:'new'
    }])

    useEffect(()=>{
      let ingval=props.editData.ingredients?props.editData.ingredients:[]
      chngIngRow(ingval)
    },[props.editData.ingredients])
    function updateState(val){    
      console.log(val)
      // ingRow.push(val)
      // let tingRow=
      ingRow.map((v)=>{
        if(v.id===val.id){
          v.itemname=val.itemname
          v.quantity=val.quantity
        }
        return v
      })
      // chngIngRow(tingRow)
      // console.log(tmpArray)
    }

    function addNewIng(){
      // setBtnFlg(true)
      chngIngRow([...ingRow,{id:uuidv4(),itemname:'',quantity:'',source:'new'}])
    }
    function addNewDrftingrows(val){
      // setBtnFlg(true)
      val.forEach((rw)=>{
        chngIngRow((oldval)=>[...oldval,{id:uuidv4(),itemname:rw.itemname,quantity:rw.quantity,source:'new'}])
      })
      
    }

    //#####################################end process ingredient rows########################

    //###########################save the draft data##############
  async function draftSave(){
    props.resetsaveDrfteditFlg(false)
    // let draftdta={
    //   recipename:crtName,
    //   recipetype:crtType,
    //   preptime:crtPrep,
    //   servings:crtSrvng,
    //   steps:crtStps,
    //   mainimage:"mainimg.png",
    //   recipeurl:crtTUrl,
    //   recipefile:"na",
    //   recipefileid:"na",
    //   // ingredients:ingRowVals.length===0?[]:ingRowCopy
    //   ingredients:ingRowVals.length===0?[]:ingRowVals
    // }
    let draftdta={...saveJson}
    let tmpdta=[...ingRow]
    let filteredTmp=tmpdta.filter((v)=>v.source==='new')
    draftdta.ingredients=[...filteredTmp]
    draftdta.rowid=props.editData.id
    // setSaveLoadFlg(true)
    let tmpdata=await get("editdraftRecipe")
    if(tmpdata){
      tmpdata=[...tmpdata.filter((v)=>v.rowid!==props.editData.id)]
      tmpdata.push(draftdta)
    }else{
      tmpdata=[]
      tmpdata.push(draftdta)
    }
    
    // await set("editdraftRecipe", {})
    await set("editdraftRecipe", tmpdata)
    alert('Draft Saved Successfully..')
    
    // setSaveLoadFlg(false)
  }
  useEffect(()=>{
    try{
      if(props.saveDrfteditFlg){
        draftSave()
      }
    }catch(e){
      console.log(e)
      alert('Error in saving draft...')
    }
    
  },[props.saveDrfteditFlg])

  //#######################################load the draft data####################
  const [draftIngrRows,setDrftIngrrows]=useState([])
  async function loadDraft(){
    props.resetloadeditDraftClkFlg(false)
    let loadedDraft=await get("editdraftRecipe")
    let draftfiltered={...loadedDraft.filter((v)=>v.rowid===props.editData.id)[0]}
    let storedKeys=Object.keys(draftfiltered)
    if(draftfiltered.recipename){
      setRcpnme(draftfiltered.recipename)
      getChangedField('recipename',draftfiltered.recipename)
    }
    if(draftfiltered.recipeurl){
      setRcpurl(draftfiltered.recipeurl)
      getChangedField('recipeurl',draftfiltered.recipeurl)
    }
    if(draftfiltered.preptime){
      setRcpTime(draftfiltered.preptime)
      getChangedField('preptime',draftfiltered.preptime)
    }
    if(draftfiltered.servings){
      setRcpSrvng(draftfiltered.servings)
      getChangedField('servings',draftfiltered.servings)
    }
    if(draftfiltered.steps){
      setRcpSteps(draftfiltered.steps)
      getChangedField('steps',draftfiltered.steps)
    }
    // console.log('##############################this is inside the loading before addnewrow')
    // console.log(draftfiltered)
    
    addNewDrftingrows(draftfiltered.ingredients)
    // setRcpType(props.editData.recipetype)
    
    
    
    

    console.log(draftfiltered)
  }
  useEffect(()=>{
    if(props.loadDrfteditFlg){
      loadDraft()
    }
  },[props.loadDrfteditFlg])
  

    //####################################save the form###########################################
    function navtoPage(event){
      // props.setQryFlag(true)
      props.setHdrToggl()
      props.setNavLink()
    }

    function saveUpdatedData(inpt){
      let actInpt={...props.editData}
      Object.keys(actInpt).forEach((ky)=>{
        if(ky==='ingredients'){

        }else{
          if(Object.keys(inpt).includes(ky)){
            actInpt[ky]=inpt[ky]
          }
        }
      })
      actInpt.ingredients.push(...inpt.ingredients)
      console.log(actInpt)
      props.updateDataAftEdit(actInpt)
    }

    function saveEdits(){ 

      //********************************for file types***************** */
      if(props.editData.recipetype==='file/image' && selctdFile){
        //**********delete the old file */
        const delSrvc=`${process.env.REACT_APP_API_HOST}/deletefilebackend/${props.editData.recipefile}`
        fetch(delSrvc,{
          credentials: 'include',
          method:'DELETE',
          headers:{
          'Authorization':`Bearer ${props.authTokenValue}`,
            // 'usrid':props.editData.user_id,
            // 'mode':'priv'
            // 'Content-Type':'application/json'
          }
        }).then((out)=>{
          if(out.status===500){
            throw new Error('error in updating recipe')
          }else if(out.status!==200){
            throw Error('Server error on updating recipe. Please refresh the page and try again')
          }
          return out.json()
        }).then((outjson)=>{
          console.log('done')
        }).catch((e)=>{
          console.log(e)
          // setSaveLoadFlg(false)
          // alert(e)
          // return
        })

      //**********end delete the old file */

      //**************************update the file******************************* */
      const fleSvcUrl=`${process.env.REACT_APP_API_HOST}/postfile`
      const filedata = new FormData()
      filedata.append('image',selctdFile)
      fetch(fleSvcUrl,{
        credentials: 'include',
        method:'POST',
        headers:{
          'Authorization':`Bearer ${props.authTokenValue}`,
          // 'Content-Type':'application/json'
        },
        body: filedata
      }).then((fleOut)=>{
        if(fleOut.status===500){
          throw Error('error in loading file')
        }
        return fleOut.json()
      }).then((flejson)=>{
        if(flejson.error){
          throw Error('error in loading file')
        }
        saveJson.recipefile=flejson.imagefile
        saveJson.recipefileid=flejson.imageid
        //---------------------------------------recipe record update-------------------------------
        let ingDataRw=[]
        ingRow.forEach((ings)=>{
          if(ings.source==='new'){
            let tmpIng={}
            Object.keys(ings).forEach((vals)=>{
            if(vals==='id' || vals==='source'){
    
            }else{
              tmpIng[vals]=ings[vals]
            }
          })
            ingDataRw.push(tmpIng)
          }        
        })
        saveJson.ingredients=ingDataRw  
        const apiurl=`${process.env.REACT_APP_API_HOST}/updaterec/me/${props.editData.id}`
        fetch(apiurl,{
          credentials: 'include',
          method:'PATCH',
          headers:{
                  'Authorization':`Bearer ${props.authTokenValue}`,
                  'Content-Type':'application/json'
          },
          body:JSON.stringify(saveJson)
        }).then((out)=>{
          if(out.status===404){
            throw Error('recipe not found')
          }else if(out.status!==200){
            throw Error('server error in updating')
          }
          return out.json()
        }).then((outjson)=>{
          console.log('updated recipe')
          props.resetEdtFlg(false)
          saveUpdatedData(outjson)
          //index update in elastic search
          if(Object.keys(saveJson).includes('recipename')){
            const esurl=`${process.env.REACT_APP_API_HOST}/updatedoc`
            const esdata={
              indexname:"recipes",
              doc_id:outjson.id,
              data:{doc: {recipename: saveJson.recipename}}
            }
            fetch(esurl,{
                credentials: 'include',
                method:'POST',
                headers:{
                  'Authorization':`Bearer ${props.authTokenValue}`,
                  'Content-Type':'application/json'
                },
            body:JSON.stringify(esdata)
            })
          }
          // end index update in elastic search
      //   console.log(props.tmpflgVal)
      //   if(props.setQryFlag){
      //     props.setQryFlag(true)
      // }
          navtoPage()
        // runOnClose()
        }).catch((e)=>{
          alert(e)
          return navtoPage()
          // return alert(e)
        })
        //---------------------------------------recipe record update-------------------------------
      }).catch((e)=>{
        alert(e)
        return navtoPage()
        // return alert(e)
      })

      //**************************end update the file******************************* */

        return
      } 
      //********************************end for file types***************** */

      console.log(ingRow)
      let ingDataRw=[]
      ingRow.forEach((ings)=>{
        if(ings.source==='new'){
          let tmpIng={}
          Object.keys(ings).forEach((vals)=>{
            if(vals==='id' || vals==='source'){
    
            }else{
              tmpIng[vals]=ings[vals]
            }
        })
          ingDataRw.push(tmpIng)
        }
        
      })
      saveJson.ingredients=ingDataRw  
      const apiurl=`${process.env.REACT_APP_API_HOST}/updaterec/me/${props.editData.id}`
      fetch(apiurl,{
        credentials: 'include',
        method:'PATCH',
        headers:{
                  'Authorization':`Bearer ${props.authTokenValue}`,
                  'Content-Type':'application/json'
        },
        body:JSON.stringify(saveJson)
      }).then((out)=>{
        if(out.status===404){
          throw Error('recipe not found')
        }else if(out.status!==200){
          throw Error('server error in updating')
        }
        return out.json()
      }).then((outjson)=>{
        console.log('updated recipe')
        props.resetEdtFlg(false)
        saveUpdatedData(outjson)
        //index update in elastic search
        if(Object.keys(saveJson).includes('recipename')){
          const esurl=`${process.env.REACT_APP_API_HOST}/updatedoc`
          const esdata={
            indexname:"recipes",
            doc_id:outjson.id,
            data:{doc: {recipename: saveJson.recipename}}
          }
          fetch(esurl,{
              credentials: 'include',
              method:'POST',
              headers:{
                'Authorization':`Bearer ${props.authTokenValue}`,
                'Content-Type':'application/json'
              },
          body:JSON.stringify(esdata)
          })
        }
        // end index update in elastic search

        //clear draft data
        let tmpdraftdeldta=[...webdrftEdtContext]
        tmpdraftdeldta=tmpdraftdeldta.filter((rw)=>rw.rowid!==props.editData.id)
        set("editdraftRecipe", [...tmpdraftdeldta])
      //   console.log(props.tmpflgVal)
      //   if(props.setQryFlag){
      //     props.setQryFlag(true)
      // }
        navtoPage()
        // runOnClose()
      }).catch((e)=>{
        alert(e)
        navtoPage()
      })
    }

    useEffect(()=>{
      if(props.editFlg){
        saveEdits()
      }
    },[props.editFlg])

      //####################################end save the form###########################################

    // function addNewIng(){

    //     chngIngRow((oldVal)=>[...oldVal,{rowid:uuidv4(),ingItem:'',itmQty:''}])
    // }

    function delIngRow(val){

        chngIngRow((oldVal)=>oldVal.filter((rw)=>rw.id!==val))
        let actInpt={...props.editData}
        if(actInpt.ingredients.length>0){
          actInpt.ingredients=actInpt.ingredients.filter((ing)=>ing.id!==val)
        }
        props.updateDataAftEdit(actInpt)
      }


    const fallBackui=<Col className='mobeditmaincol'><Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert></Col>

    try{
      return <Col className='mobeditmaincol'>

        {/* <!--####### row div for name #######--> */}
        <Row className='mobeditrw1'>
            <Col>
                <Form className='mobeditrw1frm1'>
                    {/* <Form.Group controlId="webeditfirstform.ControlInput1"> */}
                    <Form.Group>
                      {crtNmeInvFlg && <Badge variant="dark">Keep name short and crisp!!</Badge>}
                      <Form.Control type="text" placeholder="Enter Name" id="edtmobrecipename" value={recPNme} onChange={chngeRecpName} />
                    </Form.Group>

                    {/* <Form.Group controlId="webeditfirstform.ControlInput2"> */}
                    <Form.Group>
                        <Form.Label style={{fontWeight:'bolder'}} > Type:</Form.Label>
                        <Form.Control type='text' value={recpType} readOnly></Form.Control>
                        {/* <Form.Control as="select" id="seltype" value={recpType} onChange={chngeRecpType} >
                            <option>Link</option>
                            <option>File/Image</option>
                            <option>Text</option>
                        </Form.Control> */}
                    </Form.Group>
                </Form>
            </Col>
        </Row>

        {/* <!--####### row div for file #######--> */}
        {recpType==='File/Image' && <Row className='mobeditrw2'>
            <Col>
                <Form className='mobeditrw1frm2'>
                    {/* <Form.Group controlId="webeditsecndform.ControlInput3"> */}
                    <Form.Group>
                        <Form.Label style={{fontWeight:'bolder'}} >Select File:</Form.Label>
                        <Form.Control type="file" id="mobedrecipefile" onChange={handleFileLoad} />
                    </Form.Group>
                </Form>
            </Col>
        </Row>}


        {/* <!--####### row div for url/link #######--> */}
        {recpType==='Link' && <Row className='mobeditrw2n'>
          <Col>
            <Form >
                <Form.Control type="text" placeholder="Enter URL" id="edtmoburlfield" style={{marginBottom:10}}  value={recpurl?recpurl:props.editData.recipeurl} onChange={chngeRecpUrl} />
            </Form>
          </Col>
        </Row>}
        

        {/* <!--####### row div for prep time #######--> */}
        {recpType==='Text' && <Row className='mobeditrw2n'>
            <Col>
                <Form >
                    {crtprpInvFlg && <Badge variant="dark">Keep this short and crisp!!</Badge>}
                    <Form.Label style={{fontWeight:'bolder'}} >Prep Time</Form.Label>
                    <Form.Control type="text" placeholder="Enter Prep Time" id="edtmobpreptime" style={{marginBottom:10}} value={recprptime?recprptime:props.editData.preptime} onChange={chngeRecpTime} />
                    {crtsrvInvFlg && <Badge variant="dark">Keep this short and crisp!!</Badge>}
                    <Form.Label style={{fontWeight:'bolder'}} >Servings</Form.Label>
                    <Form.Control type="text" placeholder="Enter Servings" id="edtmobsrvngs" style={{marginBottom:10}} value={recpSrvngs?recpSrvngs:props.editData.servings} onChange={chngeRecpSrvngs} />
                </Form>
            </Col>
        </Row>}
        

        {/* <!--####### row div for ingredients table #######--> */}
        {true && <Row className='mobeditrw3'>
            <Col>
                <Row>
                    <Col className='mobeditrw3col1' sm={6}>
                        <h1><Badge variant="secondary" className='mobeditinghdr'>Ingredients</Badge></h1>
                    </Col>
                    <Col className='mobeditrw3col2'>
                      <Fab color='primary' size="small" className='mobeditadingrwico11' aria-label="add" style={{top: '8px'}} >
                        <AddTwoToneIcon fontSize='large'  onClick={addNewIng} />
                      </Fab>
                        {/* <a><i className="material-icons mobeditadingrw1" id='mobadingrw1' onClick={addNewIng}>add_circle_outline</i></a> */}
                    </Col>
                </Row>

                <Table striped >
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Item</th>
                            {/* <th></th> */}
                            
                            <th>Quantity</th>
                            {/* <th></th> */}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {ingRow.map((rw,indx)=><MobEditPageIngrow key={uuidv4()} rowData={rw} idVal={rw.id} deleteIng={delIngRow}  rowUpdates={updateState} rowIndx={indx} authTknValu={props.authTokenValue} />)}
                        {/* <tr>
                            <th>1</th>
                            <td><Form.Control type="text" placeholder="Enter Item Name" id="mobeditpreptime" /></td>
                            <td></td>
                            <td></td>
                            <td><Form.Control type="text" placeholder="Enter Quantity" id="mobeditqty" /></td>
                            <td><a href="#"><i className="material-icons mobedtwebdelingrow" id='mobedtdelingrow' >delete_sweep</i></a></td>
                        </tr>
                        <tr>
                            <th>2</th>
                            <td><Form.Control type="text" placeholder="Enter Item Name" id="mobeditpreptime" /></td>
                            <td></td>
                            <td></td>
                            <td><Form.Control type="text" placeholder="Enter Quantity" id="mobeditqty" /></td>
                            <td><a href="#"><i className="material-icons mobedtwebdelingrow" id='mobedtdelingrow' >delete_sweep</i></a></td>
                        </tr> */}
                    </tbody>
                </Table>
            </Col>
        </Row>}
        

        {/* <!--####### row div for steps for recipe #######--> */}
        <Row className='mobeditrw4'>
            <Col sm>
                {recpType==='Text' ?<h1><Badge variant="secondary" className='mobedtrecphdr'>How To?</Badge></h1>:<h1><Badge variant="secondary" className='mobedtrecphdr' style={{left:'35%'}}>Notes</Badge></h1>}
                <Jumbotron fluid>
                    <Container>
                        <Form.Control as="textarea" rows="5" id="edtcomment" value={recpSteps} onChange={chngeRecpSteps} />
                    </Container>
                </Jumbotron>
                
            </Col>
        </Row>
       

        

        

    </Col>
    }catch(e){
      return fallBackui
    }
    
}

export default MobEditPage