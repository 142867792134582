import React, { useState } from 'react'
import { Container, Col,Form,Table,Row,Badge,Jumbotron,Card,InputGroup,Button,Alert } from 'react-bootstrap';
import { useCookies } from 'react-cookie'
import './Login.css';
import { Buffer } from 'buffer'

function LoginPage(props){

    const [cookies, setCookie] = useCookies(['chromevar', 'varname'])
    const [disableLgnBtn,setDsblflg]=useState(false)
    console.log(props.tokenValue)
    const [userNameVal,setUsername]=useState('')
    function changeUsrName(event){
        const val=event.target.value
        setUsername(val)
        setLoginFlag(false)
    }
    const [pswrdVal,setpswrd]=useState('')
    function changePswrd(event){
        const val=event.target.value
        setpswrd(val)
        setLoginFlag(false)
    }

    const [chkFlds,setChkFlds]=useState([false,false])
    function validateFields(usrname,pswrd){
        let tmpval=[false,false]
        let outflag=false
        if(usrname===''){
            tmpval[0]=true
            outflag=true
        }

        if(pswrd===''){
            tmpval[1]=true
            outflag=true
        }
        setChkFlds((oldval)=>[...tmpval])
        return outflag
    }

    const [loginFlag,setLoginFlag]=useState(false)


    function doLoginMethod(){
        setDsblflg(true)

        // setCookie('chromevar', '709bfb45-72de-428d-a4d1-22df6d51bda0',{path:'/'})
        // setCookie('varname', 'test1',{path:'/'})

        if(validateFields(userNameVal,pswrdVal)){
            setDsblflg(false)
            return
        }

        const creds=`${userNameVal}:${pswrdVal}`
        const encoded_string=Buffer.from(creds).toString('base64');
        const loginUrl=`${process.env.REACT_APP_API_HOST}/login`
        fetch(loginUrl,{
            credentials: 'include',
            method:'POST',
            headers:{
                'Authorization':`Basic ${encoded_string}`,
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({})
        }).then((out)=>{
            if(out.status===401){
                throw Error('Login Failed. Please try again!!')
            }else if(out.status!==200){
                throw Error('Login Failed. Please try again!!')
            }
            return out.json()
        }).then((outjson)=>{
            //{authtoken: 'gAAAAABfHHeLKXSJEITIo-P6uqYNpct-Kq_3Cokf0Lj…3n2KYfXt0hgxB2ffwoEft1ozw2MNt4ITmUzE3iILMSF'}
            setDsblflg(false)
            // console.log(outjson)
            if(outjson.error){
                throw Error('auth failed')
            }
            props.getAuthTkn(outjson.authtoken)
            localStorage.setItem('appky',outjson.authtoken)
            localStorage.setItem('apptype','mobilepwa')
            props.setPageTypeMethd('landing')
            props.logintoggle()
        }).catch((e)=>{
            console.log(e)
            setDsblflg(false)
            // alert(e)
            setLoginFlag(true)
        })

        // props.setPageTypeMethd('landing')
        // props.logintoggle()
    }

    function doLoginMethodWeb(){
        setDsblflg(true)

        //set cookie---------------------------------change this
        // let expires = new Date()
        // expires.setTime(expires.getTime() + (response.data.expires_in * 1000))
        // setCookie('varname', response.data.refresh_token, {path: '/', expires})

        //---------------------------uncomment if issue
        // setCookie('chromevar', '709bfb45-72de-428d-a4d1-22df6d51bda0',{path:'/'})
        // setCookie('varname', 'test1',{path:'/'})

        if(validateFields(userNameVal,pswrdVal)){
            setDsblflg(false)
            return
        }

        //format: Basic tes1:Abc1289
        const creds=`${userNameVal}:${pswrdVal}`
        // const encoded_string=Buffer.from('user2:Abc1289').toString('base64');
        const encoded_string=Buffer.from(creds).toString('base64');

        const loginUrl=`${process.env.REACT_APP_API_HOST}/login`
        fetch(loginUrl,{
            credentials: 'include',
            method:'POST',
            headers:{
                'Authorization':`Basic ${encoded_string}`,
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({})
        }).then((out)=>{
            if(out.status===401){
                throw Error('Login Failed. Please try again!!')
            }else if(out.status!==200){
                throw Error('Login Failed. Please try again!!')
            }
            return out.json()
        }).then((outjson)=>{
            //{authtoken: 'gAAAAABfHHeLKXSJEITIo-P6uqYNpct-Kq_3Cokf0Lj…3n2KYfXt0hgxB2ffwoEft1ozw2MNt4ITmUzE3iILMSF'}
            setDsblflg(false)
            // console.log(outjson)
            if(outjson.error){
                throw Error('auth failed')
            }
            props.getAuthTkn(outjson.authtoken)
            localStorage.setItem('appky',outjson.authtoken)
            props.setSearchFlag(true)
            props.setPageTypeMethd('weblanding')
            props.logintoggle()
        }).catch((e)=>{
            setDsblflg(false)
            console.log(e)
            // alert(e)
            setLoginFlag(true)
        })

        //---------------------------uncomment if issue
        // props.setPageTypeMethd('weblanding')
        // props.logintoggle()
    }

    return <Container fluid className='logincontainer fontclassfordiv'> 
        {loginFlag && <Alert className='mobloginboxalert' variant='danger' style={{position:'relative',top:'30%'}}>Login Failed!!</Alert>}
        <div className='loginbox'>
            {/* <Form onSubmit={doLoginMethod}> */}
            
            <Form>
                <Form.Group>
                    
                    <Form.Control type="text" placeholder="Enter username" id="mobusrname" value={userNameVal} onChange={changeUsrName} style={chkFlds[0]?{borderColor:'red'}:{}} />
                </Form.Group>
                <Form.Group>
                    <Form.Control type="password" placeholder="Enter password" id="mobpswrd" value={pswrdVal} onChange={changePswrd} style={chkFlds[1]?{borderColor:'red'}:{}} />
                </Form.Group>
                {/* <Button variant="dark" type="submit" id='mobloginbtn'>Login</Button> */}
                <Button variant="dark" id='mobloginbtn' onClick={doLoginMethod} disabled={disableLgnBtn} >Login</Button>
            </Form>
            {/* <Button variant="info" type="submit" id='mobforgotbtn' style={{marginTop:10}}>Forgot Password</Button> */}
            {/* <Button variant="info" id='mobforgotbtn' style={{marginTop:10}}>Forgot Password</Button> */}
        </div>
        {loginFlag && <Alert className='webloginboxalert' variant='danger' style={{position:'relative',top:'30%',width:'50%',margin:'auto'}}>Login Failed!!</Alert>}
        <div className='webloginbox'>
            {/* <Form onSubmit={doLoginMethodWeb}> */}
            
            <Form>
                <Form.Group>
                    
                    <Form.Control type="text" placeholder="Enter username" id="webusrname" value={userNameVal} onChange={changeUsrName} style={chkFlds[0]?{borderColor:'red'}:{}} />
                </Form.Group>
                <Form.Group>
                    <Form.Control type="password" placeholder="Enter password" id="webpswrd" value={pswrdVal} onChange={changePswrd} style={chkFlds[1]?{borderColor:'red'}:{}} />
                </Form.Group>
                {/* <Button variant="dark" type="submit" id='webloginbtn' onClick={doLoginMethodWeb} >Login</Button> */}
                <Button variant="dark" id='webloginbtn' onClick={doLoginMethodWeb} disabled={disableLgnBtn} >Login</Button>
            </Form>
            {/* <Button variant="info" type="submit" id='webforgotbtn' style={{marginTop:10}}>Forgot Password</Button> */}
            {/* <Button variant="info"  id='webforgotbtn' style={{marginTop:10}}>Forgot Password</Button> */}
        </div>
    </Container>

}

export default LoginPage