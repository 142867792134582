import React,{useState,useEffect} from 'react'
import { Modal,Button,Container,Row,Col,Form,Badge,Table,Jumbotron,Alert } from 'react-bootstrap';
import DeleteSweepTwoToneIcon from '@material-ui/icons/DeleteSweepTwoTone';


function MobCreateModalingRow(props){

    // console.log(props.loadClicked)
    const [itmName,setItmName]=useState(props.loadClicked && props.draftRows[props.rowIndx-1]?props.draftRows[props.rowIndx-1].itemname:'')
    function updItmName(event){
        event.preventDefault()
        setItmName(event.target.value)
    }

    // const [itmQty,setItmQty]=useState('')
    const [itmQty,setItmQty]=useState(props.loadClicked && props.draftRows[props.rowIndx-1]?props.draftRows[props.rowIndx-1].quantity:'')
    function updItmQty(event){
        event.preventDefault()
        setItmQty(event.target.value)
    }

    useEffect(()=>{
        // id,itmname,itmqty
        const opObj={
            id: props.idVal,
            itemname: itmName,
            quantity: itmQty
        }
        props.rowUpdates(opObj)
    },[itmName,itmQty])

    function removeIngRow(event){

        props.deleteIngMethod(props.idVal)
    }

    const fallBackui=<Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert> 

    try{
        return <tr>
                <th>{props.rowIndx+1}</th>
                <td><Form.Control type="text" placeholder="Enter Item Name" id="additmnme" value={itmName} onChange={updItmName}  /></td>
                <td></td>
                <td></td>
                <td><Form.Control type="text" placeholder="Enter Quantity" id="addqty" value={itmQty} onChange={updItmQty}  /></td>
                <td><DeleteSweepTwoToneIcon fontSize='large' className='webdelingrow' onClick={removeIngRow} ></DeleteSweepTwoToneIcon></td>
                {/* <td><a href="#"><i className="material-icons webdelingrow" id='delingrow' onClick={removeIngRow} >delete_sweep</i></a></td> */}
            </tr>
    }catch(e){
        return fallBackui
    }
    
}

export default MobCreateModalingRow