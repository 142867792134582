import React,{useState} from 'react'
import Zoom from '@material-ui/core/Zoom'
import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css';
// import logo from './images/search-field.png';
import srchbck from '../../images/search-field.png'
import mainlogo from '../../images/webmainheader.svg'
import LibraryAddTwoToneIcon from '@material-ui/icons/LibraryAddTwoTone';

import { Button,Form,Row,Col,Navbar,Nav } from 'react-bootstrap';

function NavbarHdr(props){

  const [createStyle,setCreateStyle]=useState({color:'rgba(255,255,255,.75)'})
  const changeCreateStyle=()=>{
    // alert('akjhdjh')
    setCreateStyle({})
    setCreateStyle({color:'rgba(255,255,255,.75)'})
  }
  const changeFocusStyle=()=>{
    // alert('Focus')\
    setCreateStyle({})
    setCreateStyle({color:'#FFF'})
  }

  const [srchVal,setSrchVal]=useState('')
  function getSrchTermHdr(event){
    const val=event.target.value
    setSrchVal(val)
    props.setSrchtrm(val)
  }
  function doSearch(){
    // const val=event.target.value
    
    props.setQryFrmHdr(true)
  }

  const [mobsrchVal,setmobSrchVal]=useState('')
  function getmobSrchTermHdr(event){
    const val=event.target.value
    setmobSrchVal(val)
    props.mobHdrSearchSet(val)
  }

  function doMobSearch(){
    if(mobsrchVal===''){
      props.mobHdrSearchSet('blankfromhdr')
    }
    props.mobileSrchHdrQry(true)
  }

  function logOutMethod(){
    props.setlogoutFlag(false)
    props.logoutRemoveTkn('unset')
    localStorage.removeItem('appky')
    const apiurl=`${process.env.REACT_APP_API_HOST}/logout`
    fetch(apiurl,{
      credentials: 'include',
      headers:{
        'Authorization':`Bearer ${props.tokenValue}`
      }
    }).then((out)=>{
      if(out.status!==200){
        throw Error('Error in Logging out!!')
      }
      return out.json()
    }).then((outjson)=>{
      console.log(outjson)
      // props.setlogoutFlag(false)
      // props.logoutRemoveTkn('unset')
      // localStorage.removeItem('appky')
      console.log(outjson)
    }).catch((e)=>{
      alert('Error in logging Out!!')
    })
  }


  return <div>
  <Navbar bg="dark" variant="dark" expand="sm" className="webnavbar">
    {/* <a className="navbar-brand" href="#">Logo</a> */}
    <Navbar.Brand onClick={()=>window.location.reload(false)} className='webnavbarhdrbrand'><img
        src={mainlogo}
        width="80"
        height="40"
        className="d-inline-block align-top"
        alt=""
        style={{margin:'0'}}></img>
      Specipe</Navbar.Brand>

    <Form className='navbarsearchform'>
        <Row>
            <Col>
                {/* <Form.Control className='searchfield' style={{backgroundImage:`url(${srchbck})`,backgroundPosition:'right',backgroundRepeat:'no-repeat'}} placeholder="Search for a Recipe" aria-label="Search for a Recipe" aria-describedby="basic-addon2" /> */}
                <Zoom in={true}><Form.Control className='searchfield'  placeholder="Search for a Recipe" aria-label="Search for a Recipe" aria-describedby="basic-addon2" value={srchVal} onChange={getSrchTermHdr}  />
                </Zoom><div className='webpagessrchbtn' onClick={doSearch} ></div>
            </Col>
        </Row>
    </Form>
    <Nav className="ml-auto">
      <Nav.Link className='nav-item' href="#home">
        <LibraryAddTwoToneIcon style={createStyle}  onMouseLeave={changeCreateStyle} onMouseEnter={changeFocusStyle} onClick={()=>props.handleShow()} />
      {/* <i style={createStyle} onMouseLeave={changeCreateStyle} onMouseEnter={changeFocusStyle} className="material-icons" id='additem' onClick={()=>props.handleShow()}>
                library_add
                    </i> */}
      </Nav.Link>
      <Nav.Link className='moblogoutlink' onClick={logOutMethod} ><strong>Logout</strong></Nav.Link>      
    </Nav>
    </Navbar>


    {/* <!--################################ Mobile Navbar#######################################--> */}
    <Navbar bg="dark" variant="dark" expand="sm" className="mobilenavbar">
    <Navbar.Brand onClick={()=>window.location.reload(false)} className='mobilenavbarhdrbrand' ><img
        src={mainlogo}
        width="40"
        height="40"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
        style={{margin:'0'}}></img></Navbar.Brand>

    <Form className='mobnavbarsearchform'>
        <Row>
            <Col>
                {/* <Form.Control className='mobsearchfield' style={{backgroundImage:`url(${srchbck})`,backgroundPosition:'right',backgroundRepeat:'no-repeat'}} placeholder="Search for a Recipe" aria-label="Search for a Recipe" aria-describedby="basic-addon2" /> */}
                <Zoom in={true}><Form.Control className='mobsearchfield'  placeholder="Search for a Recipe" aria-label="Search for a Recipe" aria-describedby="basic-addon2" value={mobsrchVal} onChange={getmobSrchTermHdr} /></Zoom>
                <div className='mobhdrsrchbtn' onClick={doMobSearch} ></div> 
            </Col>
        </Row>
    </Form>
    <Navbar.Toggle className='ml-auto' aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
    <Nav.Link className='nav-item' href="#home">
      <LibraryAddTwoToneIcon style={createStyle} fontSize='medium' onMouseLeave={changeCreateStyle} onMouseEnter={changeFocusStyle} onClick={()=>props.handleMobShow()} />
    {/* <i style={createStyle} onMouseLeave={changeCreateStyle} onMouseEnter={changeFocusStyle} className="material-icons" id='additem' onClick={()=>props.handleMobShow()}>
                library_add
                    </i> */}
                    </Nav.Link>
      <Nav.Link className='ml-auto nav-item moblogoutlink' style={createStyle} onClick={logOutMethod} ><strong>Logout</strong></Nav.Link>
    </Navbar.Collapse>    
    </Navbar>
    {/* <!--################################ end Mobile Navbar#######################################--> */}

    </div>

}

export default NavbarHdr
    





    
    // nav-link
    // function setCreateButtonClass(){
    //   setCreateClass('aa')
    // }
    
    
    

    {/* return <Navbar bg="dark" variant="dark" expand="sm" className="webnavbar"> */}
    {/* <a className="navbar-brand" href="#">Logo</a> */}
    {/* <Navbar.Brand href="#home">Logo</Navbar.Brand> */}

    {/* <Form className='navbarsearchform'> */}
        {/* <Row> */}
            {/* <Col> */}
                {/* <Form.Control className='searchfield' placeholder="Search for a Recipe" aria-label="Search for a Recipe" aria-describedby="basic-addon2" /> */}
            {/* </Col> */}
            {/* <Col> */}
                {/* <Form.Control placeholder="Last name" /> */}
            {/* </Col> */}
        {/* </Row> */}
    {/* </Form> */}

    {/* <form className="form-inline navbarsearchform"> */}
      {/* <!-- <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" style='width: 70%;'> --> */}
      {/* <!-- <button className="btn btn-outline-primary my-2 my-sm-0" type="submit" style='background-color: #5c8d89;color:black'>Search</button> --> */}
      {/* <input type="text" className="form-control searchfield" placeholder="Search for a Recipe" aria-label="Search for a Recipe" aria-describedby="basic-addon2" /> */}
    {/* </form> */}

    {/* <Nav className="ml-auto"> */}
      {/* <Nav.Link className='nav-item' href="#home"><i style={createStyle} onMouseLeave={changeCreateStyle} onMouseEnter={changeFocusStyle} className="material-icons" id='additem' onClick={()=>props.handleShow()}> */}
                {/* library_add */}
                    {/* </i></Nav.Link> */}
      {/* <Nav.Link href="#features"><strong>Logout</strong></Nav.Link> */}
      {/* <Nav.Link href="#pricing">Pricing</Nav.Link> */}
    {/* </Nav> */}

    {/* <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse ml-auto" id="collapsibleNavbar">
          <ul className="navbar-nav ml-auto">
           
            <li className="nav-item">
              <a className="nav-link" href="#"><i className="material-icons" id='additem' data-toggle="modal" data-target="#exampleModal">
                library_add
                    </i></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/logout"><strong>Logout</strong></a>
            </li>
            
            
          </ul>
        </div> */}
      
    
    {/* </Navbar> */}
// }

// export default NavbarHdr