import React,{useState} from 'react'
import { Container, Col,Form,Table,Row,Badge,Jumbotron,Alert } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import { green } from '@material-ui/core/colors';
import AddShoppingCartTwoToneIcon from '@material-ui/icons/AddShoppingCartTwoTone';


function WebSecndColIngRow(props){

    const [showAddConf,setShowConf]=useState(props.loadingBtnFlg[props.rownum]?true:false)
    if(showAddConf){
        setTimeout(()=>{
            props.updateLoadingBtnflg((oldval)=>{
                oldval[props.rownum]=false
                return oldval
            })
            setShowConf(false)
        },2000)
    }

    function addtoShopList(){
        

        const shopDta=[{
            "tmpid":uuidv4(),
            "itemdesc":`${props.rowData.itemname}:${props.rowData.quantity}`,
	        "category":"Walmart"
        }]
        props.addShopFunc(shopDta)
        props.updateLoadingBtnflg((oldval)=>{
            oldval[props.rownum]=true
            return oldval
        })
    }

    console.log(showAddConf)

    const fallbackUI=<Alert variant='danger' >Error from Server on loading the component. Please refresh and try again!!</Alert> 

    try{
        return <tr>
                <th scope="row">{props.rownum+1}</th>                    
                <td className='websecndcol1' >{props.rowData.itemname}</td>
                <td></td>
                <td></td>
                <td className='websecndcol2' >{props.rowData.quantity}</td>
                {!showAddConf && <td><AddShoppingCartTwoToneIcon color='primary' fontSize='medium' className='websecndcoladshpico2' onClick={addtoShopList} /></td>}
                {/* {!showAddConf && <td><a  href="#"><i className="material-icons websecndcoladshp2" id='addtoshp1' onClick={addtoShopList} >add_circle_outline</i></a></td>} */}
                {showAddConf && <td><CheckCircleTwoToneIcon className="websecndcoladshp2" fontSize="medium" style={{ color: green[500] }}></CheckCircleTwoToneIcon></td>}
            </tr>
    }catch(e){
        return fallbackUI
    }
    
}

export default WebSecndColIngRow