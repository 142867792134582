import React, { useState,useEffect } from 'react'
import { Container,Row,Col,Card,Spinner,Alert } from 'react-bootstrap';
// import sampleback from '../../images/recipe1.png'
import MobHomePageItems from './MobHomePageitems'
import { v4 as uuidv4 } from 'uuid';


function MobHomePage(props){

    
    function onClickRow(detailData){

        props.setHdrToggl('detailpage')
        props.detailNavPage(detailData)
    }
    const [loadingIcon,setLoading]=useState(false)

    const [itemRow,chngeItems]=useState([])


    function delItems(val){
        chngeItems((oldVal)=>oldVal.filter((rw)=>rw.id!==val))
      }

    // use
    // const [reQryFlg,setReQryFlag]=useState(true)
    console.log(props.srchStrVal)

    useEffect(()=>{
      if(props.triggerReQry || props.triggerQryOnCreate){

        
        chngeItems([])
        setLoading(true)
        const apiurl=`${process.env.REACT_APP_API_HOST}/searchdocs`
        let qryData={}
        if(props.srchStrVal!=='unset' && props.mobhdrTrm==='blankfromhdr'){
          qryData={indexname:"recipes",searchterm:''}
        }else if(props.mobhdrTrm!=='unset' && props.mobhdrTrm!=='blankfromhdr'){
          qryData={indexname:"recipes",searchterm:`recipename:*${props.mobhdrTrm}*`}
        }else if(props.srchStrVal!=='unset'){
          qryData={indexname:"recipes",searchterm:`recipename:*${props.srchStrVal}*`}
        }else {
          qryData={indexname:"recipes",searchterm:''}
        }
        // qryData={indexname:"recipes",searchterm:`recipename:*${props.srchStrVal}*`}
        fetch(apiurl,{
          credentials: 'include',
          method:'POST',
          headers:{
            'Authorization':`Bearer ${props.authTokenValue}`,
            'Content-Type':'application/json'
          },
          body:JSON.stringify(qryData)
        }).then((out)=>{
          if(out.status===500){
            throw Error('error querying')
          }else if(out.status!==200){
            throw Error('Server error on Getting the recipes. Please refresh the page and try again')
          }
          return out.json()
        }).then((datajsonout)=>{
          setLoading(false)
          chngeItems(datajsonout)
          props.resetQryTrgr(false)
          props.changeQryFlagOnCreate(false)
        }).catch((e)=>{
          chngeItems([])
          setLoading(false)
          alert(e)
        })

      }
    },[props.triggerReQry,props.triggerQryOnCreate])

      //#########################old query method. reinstate if issue###########################
      // useEffect(() => {
      //     if(props.triggerReQry || props.triggerQryOnCreate){
      //         console.log(process.env.REACT_APP_API_HOST)
      //       const apiurl=`${process.env.REACT_APP_API_HOST}/getrecipe/me`
      //   fetch(apiurl,{
      //     credentials: 'include',
      //     headers:{
      //       'Authorization':`Bearer ${process.env.REACT_APP_AUTH_TKN}`
      //     }
      //   }).then((out)=>{
      //     return out.json()
      //   }).then((datajsonout)=>{
      //       console.log(datajsonout)
      //       chngeItems(datajsonout)
      //       props.resetQryTrgr(false)
      //       props.changeQryFlagOnCreate(false)
      //       // props.resetFlgEdit(false)
      //       // consol
      //       // datarw=[...datajsonout]
      //   })
      //     }
        
      //   }, [props.triggerReQry,props.triggerQryOnCreate])
        //#########################end of old query method. reinstate if issue###########################

    const fallbackui=<Col className='mobhomecol1'>
      {loadingIcon && <div style={{width:'250px',position:"relative",top:'50%',left:'20%'}}>
      {/* {loadingIcon && <div style={{width:'250px',position:"relative",top:'50%',left:'20%'}}> */}
          <Spinner  animation="grow" variant="info" />
          <Spinner animation="grow" variant="success" />
          <Spinner animation="grow" variant="primary" />
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="info" />
          <Spinner animation="grow" variant="success" />
          <Spinner animation="grow" variant="primary" />
        </div>}
        <Alert variant='danger' >
            Error from Server on loading the recipes.
          </Alert> 
    </Col>

    try{
      return <Col className='mobhomecol1'>
         {/* {itemRow.length===0 && <div style={{width:'250px',position:"relative",top:'50%',left:'20%'}}> */}
         {/* {true && <div style={{width:'250px',position:"relative",top:'50%',left:'20%'}}> */}
         {loadingIcon && <div style={{width:'300px',position:"relative",top:'50%',left:'15%'}}>
          <Spinner  className='mobhomepageloader1' animation="grow" variant="info" />
          <Spinner className='mobhomepageloader2' animation="grow" variant="success" />
          <Spinner className='mobhomepageloader3' animation="grow" variant="primary" />
          <Spinner className='mobhomepageloader4' animation="grow" variant="warning" />
          <Spinner className='mobhomepageloader5' animation="grow" variant="info" />
          <Spinner className='mobhomepageloader6' animation="grow" variant="success" />
          {/* <Spinner className='mobhomepageloader7' animation="grow" variant="primary" /> */}
        </div>}
        
        {itemRow.map((rw)=><MobHomePageItems clickNav={onClickRow} key={uuidv4()} rowData={rw} delRowMethod={delItems} authValue={props.authTokenValue} />)}

        {/* <Row className='mobhomerw1'>
            <Col xs={2} className='mobhomerocol1' style={{backgroundImage:`url(${sampleback})`}} >

            </Col>
            <Col xs={9}>
                <Card className='mobhomerocol2' onClick={onClickRow} >
                    <Card.Body>Basic card Afqd asda sd </Card.Body>
                </Card>
            </Col>
            <Col xs={1}>
            <a  href="#" className='mobhomerocol3'><i className="material-icons mobhomerocol3ico" id='mobdelitem'>
                  delete_forever
                      </i></a>
            </Col>
        </Row>
        <Row className='mobhomerw1'>
            <Col xs={2} className='mobhomerocol1' style={{backgroundImage:`url(${sampleback})`}} >

            </Col>
            <Col xs={9}>
                <Card className='mobhomerocol2' onClick={onClickRow} >
                    <Card.Body>Basic card Afqd asda sd </Card.Body>
                </Card>
            </Col>
            <Col xs={1}>
            <a  href="#" className='mobhomerocol3'><i className="material-icons mobhomerocol3ico" id='mobdelitem'>
                  delete_forever
                      </i></a>
            </Col>
        </Row> */}
        
        
    </Col>
    }catch(e){
      return fallbackui
    }
    

}

export default MobHomePage